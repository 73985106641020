export interface IExpenseItemModel {
	expenseItemId: number;
	qbwcClassId?: number;
	itemCode: string;
	description: string;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	quickbooksCategoryId: string;
	quickbooksAccountType: string;
	active: boolean;
}

export class ExpenseItemModel implements IExpenseItemModel {
	expenseItemId: number;
	qbwcClassId?: number;
	itemCode: string;
	description: string;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	quickbooksCategoryId: string;
	quickbooksAccountType: string;
	active: boolean;
}