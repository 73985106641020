export interface ISurveyQuestionModel {
	surveyQuestionId: number;
	question: string;
	active: boolean;
}

export class SurveyQuestionModel implements ISurveyQuestionModel {
	constructor() {
		this.surveyQuestionId = 0;
		this.active = true;
	}

	surveyQuestionId: number;
	question: string;
	active: boolean;
}