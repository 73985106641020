export interface IBusinessDevelopmentContactRoleModel {
	businessDevelopmentContactRoleId: number;
	roleName: string;
	active: boolean;
}

export class BusinessDevelopmentContactRoleModel implements IBusinessDevelopmentContactRoleModel {
	constructor() {
		this.businessDevelopmentContactRoleId = 0;
		this.active = true;
	}

	businessDevelopmentContactRoleId: number;
	roleName: string;
	active: boolean;
}