<form #addressForm="ngForm" novalidate>
    <div *ngIf="showAddressSearch" class="row form-group">
        <div *ngIf="disabled === false" class="col-12">
            <label *ngIf="showPlaceholder === false">Address Search</label>
            <div class="input-group">
                <span class="input-group-text"><span class="far fa-map-marker-alt"></span></span>
                <input type="text" class="form-control" name="addressSearch" [(ngModel)]="googleAddressSearch" slick-google-address-search (onGoogleAddressSelect)="onGoogleMapAddressSelect($event)" />
            </div>
        </div>
    </div>

    <div class="row form-group">
        <div class="col-12">
            <label *ngIf="showPlaceholder === false">Address 1</label>
            <input [disabled]="disabled" [slick-validation-indicator]="addressModel.address1" slickValidationType="warning" type="text" class="form-control" name="address1" [(ngModel)]="addressModel.address1" placeholder="{{ (showPlaceholder === true) ? 'Address 1' : '' }}" />
        </div>
    </div>

    <div *ngIf="!addressModel.address2 && showAddress2 === false && !disabled" style="position: relative; top: -6px; left: -12px;">
        <div class="col-12" style="line-height: 10px; height: 10px;">
            <button type="button" class="btn btn-link" (click)="showAddress2 = !showAddress2" style="font-size: 10px;">Show Address 2</button>
        </div>
    </div>
    <div *ngIf="addressModel.address2 || showAddress2 === true">
        <div class="row form-group">
            <div class="col-12">
                <label *ngIf="showPlaceholder === false">Address 2</label>
                <input [disabled]="disabled" type="text" class="form-control" name="address2" [(ngModel)]="addressModel.address2" placeholder="{{ (showPlaceholder === true) ? 'Address 2' : '' }}" />
            </div>
        </div>
    </div>

    <div class="row form-group">
        <div class="col-12 d-flex">
            <div class="flex-fill me-1">
                <label *ngIf="showPlaceholder === false">City</label>
                <input [disabled]="disabled" [slick-validation-indicator]="addressModel.city" slickValidationType="warning" type="text" class="form-control" name="city" [(ngModel)]="addressModel.city" placeholder="{{ (showPlaceholder === true) ? 'City' : '' }}" />
            </div>
            <div class="me-1" style="width: 50px;">
                <label *ngIf="showPlaceholder === false">St</label>
                <input [disabled]="disabled" [slick-validation-indicator]="addressModel.state" slickValidationType="warning" type="text" class="form-control" name="state" [(ngModel)]="addressModel.state" placeholder="{{ (showPlaceholder === true) ? 'ST' : '' }}" />
            </div>

            <div style="width: 100px;">
                <label *ngIf="showPlaceholder === false">Postal</label>
                <input [disabled]="disabled" [slick-validation-indicator]="addressModel.postalCode" slickValidationType="warning" type="text" class="form-control" name="postalCode" [(ngModel)]="addressModel.postalCode" placeholder="{{ (showPlaceholder === true) ? 'Postal' : '' }}" />
            </div>
        </div>
    </div>
</form>
