import { Injectable } from '@angular/core';


@Injectable()
export class AuthService  {

	constructor() { }

	get isAuthenticated(): boolean {

		const token = localStorage.getItem('jwt_token');

		return !!token;
	}

	setToken(token: string) {
		localStorage.setItem("jwt_token", token);
	}

	getToken(): string {
		return localStorage.getItem("jwt_token");
	}

	clearToken() {
		console.info("Clearing Token");
		localStorage.removeItem("jwt_token");
		localStorage.removeItem("USER_INFO_CACHE_CHECKSUM")
	}

	canActivate() {
		return this.isAuthenticated;
	}
}