import { Injectable } from "@angular/core";
import { IROIExpenseModel } from "@models";
import { HttpService } from "./utils/http.service";

@Injectable()
export class ROIService {
	constructor(private httpService: HttpService) { }

	getROIExpense(roiExpenseId: number): Promise<IROIExpenseModel> {
		const params = {
			roiExpenseId: roiExpenseId
		}
		return this.httpService.get("/roi/getRoiExpense", params);
	}

	getROIExpensesForPayable(payableId: number): Promise<IROIExpenseModel[]> {
		const params = {
			payableId: payableId
		}
		return this.httpService.get("/roi/getROIExpensesForPayable", params);
	}

	getROIExpensesForBusinessDevelopmentAccount(businessDevelopmentAccountId: number): Promise<IROIExpenseModel[]> {
		const params = {
			businessDevelopmentAccountId: businessDevelopmentAccountId
		}
		return this.httpService.get("/roi/getROIExpensesForBusinessDevelopmentAccount", params);
	}

	addExpense(roiExpenseModel: IROIExpenseModel): Promise<IROIExpenseModel> {
		return this.httpService.post("/roi/addExpense", roiExpenseModel);
	}

	updateExpense(roiExpenseModel: IROIExpenseModel): Promise<IROIExpenseModel> {
		return this.httpService.put("/roi/updateExpense", roiExpenseModel);
	}

	deleteROIExpense(roiExpenseId: number): Promise<IROIExpenseModel> {
		const params = {
			roiExpenseId: roiExpenseId
		}
		return this.httpService.delete("/roi/getRoiExpense", params);
	}

}