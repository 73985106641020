export interface IDropdownModel {
	id: any;
	text: string;
}

export class DropdownModel implements IDropdownModel {
	constructor(id: any, text: string) {
		this.id = id;
		this.text = text;
	}

	id: any;
	text: string;
}