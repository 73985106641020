export interface IAppointmentLaborCodeModel {
	laborCodeId: number;
	laborCodeName: string;
}

export class AppointmentLaborCodeModel implements IAppointmentLaborCodeModel {
	laborCodeId: number;
	laborCodeName: string;
}

