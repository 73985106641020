import { Injectable } from '@angular/core';
import { FunctionLockService, UtilsService, GlobalsService, HttpService } from '@services';
import { DevTraceService } from '@services/utils/dev-trace.service';
import { IPhotoUploadModel, PhotoUploadModel } from "./photo-upload.model";
import { IDocumentModel } from '@models';
import { Subject } from 'rxjs';
import { SlickToastService } from 'slick-components';

import { Filesystem, Directory } from "@capacitor/filesystem";

const LOCK_KEY = "PHOTOS_STORE_LOCK";
const LOCALSTORAGE_KEY = "PHOTOS_STORE_MODELS";

@Injectable()
export class PhotoUploadStore {
	private static _isLocked: boolean;
	private static _errors: string[];

	photoUploadedSubject: Subject<IDocumentModel> = new Subject<IDocumentModel>();

	constructor(private functionLockService: FunctionLockService,
		private slickToastService: SlickToastService,
		private httpService: HttpService,
		private devTraceService: DevTraceService) {
		PhotoUploadStore._errors = [];
		PhotoUploadStore._isLocked = true;

		const allPhotos = this.getPhotoUploadModels();

		if (allPhotos && allPhotos.length > 0)
			allPhotos[0].error = null;

		this.setPhotoUploadModels(allPhotos);
		PhotoUploadStore._isLocked = false;
	}

	async uploadPhotos() {
		if (PhotoUploadStore._isLocked === true)
			return;

		if (PhotoUploadStore._errors && PhotoUploadStore._errors.length > 0) {
			for (let i = 0; i < PhotoUploadStore._errors.length; i++) {
				await this.devTraceService.addTrace(PhotoUploadStore._errors[i]);
			}
		}

		PhotoUploadStore._errors = [];

		try {
			await Filesystem.readdir({
				path: `jobsight-photos`,
				directory: Directory.Data
			})
		}
		catch {
			await Filesystem.mkdir({
				path: `jobsight-photos`,
				directory: Directory.Data
			});
		}

		try {
			PhotoUploadStore._isLocked = true;

			const allPhotos = this.getPhotoUploadModels() || [];
			if (allPhotos.length === 0) {
				PhotoUploadStore._isLocked = false;
				return;
			}
			this.devTraceService.addTrace(`${allPhotos.length} photos to upload`);

			allPhotos.forEach(x => x.error = null);
			this.setPhotoUploadModels(allPhotos);
			if (!GlobalsService.isOnline) {
				this.devTraceService.addTrace(`Device is offline`);
				PhotoUploadStore._isLocked = false;
				return;
			}

			if (allPhotos.length > 0) {

				const uploadPromises: Promise<IPhotoUploadModel>[] = [];
				for (let i = 0; i < allPhotos.length; i++) {
					const currentPhoto = allPhotos[i];
					uploadPromises.push(this.upload_photo_internal(currentPhoto));
				}

				await Promise.all(uploadPromises);
				if ((this.getPhotoUploadModels() || []).length === 0)
					this.slickToastService.showSuccess(`All photos uploaded`, 2000);


			}
		}
		catch (err: any) {
			console.error(err);
			const errMsg = `Msg: ${err.message} | Stack: ${err.stack}`
			if (PhotoUploadStore._errors.indexOf(errMsg) < 0)
				PhotoUploadStore._errors.push(errMsg);
		}

		PhotoUploadStore._isLocked = false;
	}

	private async upload_photo_internal(photo: IPhotoUploadModel): Promise<IPhotoUploadModel> {
		try {
			// Upload photo
			const imageFile = await Filesystem.readFile({
				path: `jobsight-photos/${photo.fileName}`,
				directory: Directory.Data
			});
			const uploadUrl = `documents/saveJobPhoto?folderUuid=${photo.folderUuid}&uuid=${photo.uuid}&seq=${photo.seq ?? 999}`;

			const formData = new FormData();
			formData.append('enc64', imageFile.data);

			const result = await this.httpService.postMultipart(uploadUrl, formData);
			if (result.status === 'ok') {
				const doc = await this.httpService.get(`documents/getDocumentByUuid?uuid=${photo.uuid}`);
				this.photoUploadedSubject.next(doc);
				// Delete this photo
				await this.clearPhoto(photo)
			}
		}
		catch (err: any) {
			photo.error = `Msg: ${err.message} | Stack: ${err.stack}`
			photo.currentStatus = "Error";
			photo.retryCount++;
			await this.updatePhoto(photo);
			console.error(err)
			const errMsg = `Msg: ${err.message} | Stack: ${err.stack}`
			if (PhotoUploadStore._errors.indexOf(errMsg) < 0)
				PhotoUploadStore._errors.push(errMsg);
			//this.slickToastService.showDanger(`Photo ${photo.fileName} failed to upload`, 2000);
		}

		return photo;
	}

	async addPhoto(fileName: string, folderUuid: string, seq: number): Promise<IPhotoUploadModel> {
		const newPhotoUploadModel = new PhotoUploadModel();

		let photosUploadModels = this.getPhotoUploadModels();

		newPhotoUploadModel.uuid = UtilsService.newGuid();
		newPhotoUploadModel.date = new Date();
		newPhotoUploadModel.folderUuid = folderUuid;
		newPhotoUploadModel.fileName = fileName;
		newPhotoUploadModel.retryCount = 0;
		newPhotoUploadModel.seq = seq || 9999;

		photosUploadModels.push(newPhotoUploadModel);

		this.setPhotoUploadModels(photosUploadModels);

		return newPhotoUploadModel;
	}

	async clearAll(): Promise<IPhotoUploadModel[]> {

		this.setPhotoUploadModels([]);

		return this.getAllPhotos();

	}

	async getAllPhotos(): Promise<IPhotoUploadModel[]> {
		return [...this.getPhotoUploadModels()];
	}

	async clearPhoto(photo: IPhotoUploadModel): Promise<void> {

		let photoUploadModels = this.getPhotoUploadModels();

		photoUploadModels = photoUploadModels.filter(x => x.uuid !== photo.uuid);

		this.setPhotoUploadModels(photoUploadModels);

		try {
			await Filesystem.deleteFile({
				path: `jobsight-photos/${photo.fileName}`,
				directory: Directory.Data
			});
		}
		catch (err) {
			console.error(err);
		}
	}

	async updatePhoto(photo: IPhotoUploadModel): Promise<IPhotoUploadModel> {

		let photoUploadModels = this.getPhotoUploadModels();

		const photoIdx = photoUploadModels.findIndex(x => x.uuid === photo.uuid);

		if (photoIdx >= 0)
			photoUploadModels[photoIdx] = photo;

		this.setPhotoUploadModels(photoUploadModels);

		return photoUploadModels[photoIdx];
	}

	private getPhotoUploadModels(): IPhotoUploadModel[] {

		const photoModelStr = localStorage.getItem(LOCALSTORAGE_KEY);

		if (!photoModelStr)
			return [];

		return JSON.parse(photoModelStr);
	}

	private setPhotoUploadModels(photoModels: IPhotoUploadModel[]) {
		const photoModelStr = JSON.stringify(photoModels);

		localStorage.setItem(LOCALSTORAGE_KEY, photoModelStr);
	}
}
