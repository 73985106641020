export interface IAppointmentReminderDefaultModel {
	appointmentReminderDefaultId: number;
	reminderText: string;
	active: boolean;
}

export class AppointmentReminderDefaultModel implements IAppointmentReminderDefaultModel {
	appointmentReminderDefaultId: number;
	reminderText: string;
	active: boolean;
}