
<div class="d-flex flex-fill align-items-center">
    <div class="d-flex flex-fill ms-1">

        <div class="input-group">
            <span class="input-group-text"><span class="far fa-search"></span></span>
            <input #autoCompleteInputRef type="text" class="form-control" [ngStyle]="{ 'border-top-right-radius': showButtons ? '0' : '', 'border-bottom-right-radius': showButtons ? '0' : '' }" autocomplete="off" [(ngModel)]="customerSearchText" name="customer" placeholder="Customer search..." autofocus (change)="checkEmpty()" />
        </div>

        <button *ngIf="showButtons" [disabled]="!canUpdateCustomers || !selectedCustomer || selectedCustomer.customerId === 0" class="btn btn-sm btn-primary" style="border-radius: 0; position: relative; left: -1px;" (click)="editCustomer()"><i class="fad fa-pencil"></i></button>
        <button *ngIf="showButtons" [disabled]="!canCreateCustomers || (!alwaysAllowAddNew && selectedCustomer?.customerId > 0)" class="btn btn-sm btn-primary" style="border-top-left-radius: 0; border-bottom-left-radius: 0; position: relative; left: -1px;" (click)="addNewCustomer()"><i class="far fa-plus"></i></button>

        <slick-auto-complete [inputRef]="autoCompleteInputRef" textField="fullName" [items]="results" (onRefresh)="search($event)" (onSelect)="onSelectCustomer($event)">
            <ng-template let-searchCustomer [slickAutoCompleteListItemTemplate]>
                <div style='line-height: 1.1em; font-size: 12px;'>
                    <div *ngIf="searchCustomer.companyName">
                        <span style='font-size: 16px; font-weight: bold;'>{{searchCustomer.companyName}}</span><br />
                        <span *ngIf="searchCustomer.fullName">{{searchCustomer.fullName}}</span><br />
                    </div>
                    <div *ngIf="!searchCustomer.companyName">
                        <span style='font-size: 16px; font-weight: bold;'>{{searchCustomer.fullName}}</span><br />
                    </div>
                    <span *ngIf="searchCustomer.primaryAddress1">{{searchCustomer.primaryAddress1}}<br /></span>
                    <span *ngIf="searchCustomer.primaryAddress2">{{searchCustomer.primaryAddress2}}<br /></span>
                    <div style="margin-bottom: 5px;">{{searchCustomer.primaryCityStatePostal}}</div>
                </div>
            </ng-template>
        </slick-auto-complete>
    </div>
</div>
<customer-edit-dialog #customerEditDialogRef [customerModel]="dialogCustomerModel" (onSave)="onSave($event)"></customer-edit-dialog>