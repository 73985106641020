import { Injectable } from "@angular/core";
import { UtilsService } from "./utils/utils.service";
import { HttpService } from "./utils/http.service";
import { LookupService } from "./utils/lookup.service";
import { IContactModel, ContactModel, IAddressModel, AddressModel, IPhoneNumberModel, PhoneNumberModel, IEmailAddressModel, EmailAddressModel  } from "@models";

@Injectable() 
export class ContactsService {
	constructor(private httpService: HttpService, private lookupService: LookupService) {

	}

	getNewContactModel(): IContactModel {
		var contactModel: IContactModel = new ContactModel();
		contactModel.contactId = 0;
		contactModel.defaultAddress = this.getNewAddressModel();
		contactModel.defaultHome = this.getNewPhoneNumberModel(true, 1);
		contactModel.defaultWork = this.getNewPhoneNumberModel(true, 2);
		contactModel.defaultCell = this.getNewPhoneNumberModel(true, 3);
		contactModel.defaultAlt1 = this.getNewPhoneNumberModel(true, 6);
		contactModel.defaultAlt2 = this.getNewPhoneNumberModel(true, 7);
		contactModel.defaultEmailAddress = this.getNewEmailAddressModel(true);

		return contactModel;
	}

	public getNewAddressModel(isDefault?: boolean): IAddressModel {
		isDefault = !!isDefault;
		var newAddress: IAddressModel = new AddressModel();
		newAddress.uuid = UtilsService.newGuid();
		newAddress.addressTypeId = 1;
		newAddress.addressTypeName = "Home";
		newAddress.country = "US";
		newAddress.isDefault = isDefault;
		newAddress.active = true;
		//newAddress.address1 = "1681 East 260 North";
		//newAddress.city = "Spanish Fork";
		//newAddress.state = "UT";
		//newAddress.postalCode = "84660";

		return newAddress;
	}

	public getNewPhoneNumberModel(isDefault?: boolean, phoneNumberType?: number): IPhoneNumberModel {
		isDefault = !!isDefault;
		var newPhoneNumber: IPhoneNumberModel = new PhoneNumberModel();
		newPhoneNumber.uuid = UtilsService.newGuid();
		newPhoneNumber.phoneNumberTypeId = 1;
		newPhoneNumber.phoneNumberTypeName = "Home";
		newPhoneNumber.isDefault = isDefault;
		newPhoneNumber.active = true;

		if (phoneNumberType) {
			switch (phoneNumberType) {
				case 1:
					newPhoneNumber.phoneNumberTypeId = 1;
					newPhoneNumber.phoneNumberTypeName = "Home";
					break;

				case 2:
					newPhoneNumber.phoneNumberTypeId = 2;
					newPhoneNumber.phoneNumberTypeName = "Work";
					break;

				case 3:
					newPhoneNumber.phoneNumberTypeId = 3;
					newPhoneNumber.phoneNumberTypeName = "Cell";
					break;

				case 4:
					newPhoneNumber.phoneNumberTypeId = 4;
					newPhoneNumber.phoneNumberTypeName = "Fax";
					break;

				case 5:
					newPhoneNumber.phoneNumberTypeId = 5;
					newPhoneNumber.phoneNumberTypeName = "Other";
					break;

				case 6:
					newPhoneNumber.phoneNumberTypeId = 6;
					newPhoneNumber.phoneNumberTypeName = "Alt 1";
					break;

				case 7:
					newPhoneNumber.phoneNumberTypeId = 7;
					newPhoneNumber.phoneNumberTypeName = "Alt 2";
					break;
			}
		}

		return newPhoneNumber;
	}

	public getNewEmailAddressModel(isDefault?: boolean): IEmailAddressModel {
		isDefault = !!isDefault;
		var newEmailAddress: IEmailAddressModel = new EmailAddressModel();
		newEmailAddress.uuid = UtilsService.newGuid();
		newEmailAddress.emailAddressTypeId = 1;
		newEmailAddress.emailAddressTypeName = "Home";
		newEmailAddress.isDefault = isDefault;
		newEmailAddress.active = true;

		return newEmailAddress;
	}

	async getContact(contactId: number): Promise<IContactModel> {
		try {
			return Promise.resolve(await this.httpService.get("/contacts/getContact", { contactId: contactId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getContactByUserId(userId: number): Promise<IContactModel> {
		try {
			return Promise.resolve(await this.httpService.get("/contacts/getContactByUserId", { userId: userId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getContactsForJob(jobId: number): Promise<IContactModel[]> {
		return Promise.resolve(await this.httpService.get("/contacts/getContactsForJob", { jobId: jobId }));
	}

	async updateContact(contactModel: IContactModel): Promise<IContactModel> {
		try {
			if (contactModel.contactId === 0) {
				let returnModel = await this.httpService.post("/contacts/addContact", contactModel);
				this.lookupService.refreshEstimators();
				this.lookupService.refreshEmailerAddresses();
				return Promise.resolve(returnModel);
			}
			else {
				let returnModel = await this.httpService.post("/contacts/updateContact", contactModel)
				this.lookupService.refreshEstimators();
				this.lookupService.refreshEmailerAddresses();
				return Promise.resolve(returnModel);
			}
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}
}
