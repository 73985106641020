<footer *ngIf="isLoggedIn === true && isLoginPage === false" class="bg-white footer-bar">
	<div style="float: right; padding-right: 20px; line-height: 26px;">v.{{version}} <button class="ms-1 btn btn-link" (click)="showVersionHistory()"> Versions <i class="fal fa-history"></i> </button></div>
	<div style="min-height: 1px;" class="bg-light"></div>
	<div class="container-fluid text-dark">
		<div class="d-flex">
			<div class="me-4">&copy;{{currentYear}} JobSight</div>
			<div *ngIf="isDebug" class="bg-danger text-white px-5">DEV SERVER</div>
			<div *ngIf="isStaging" class="bg-danger text-white px-5">STAGING SERVER</div>
			<div *ngIf="isRC" class="bg-danger text-white px-5">RELEASE CANDIDATE SERVER</div>
		</div>
	</div>
</footer>

<version-release-dialog #dialogRef [isMobile]="false"></version-release-dialog>