export interface ITimeOffDetailModel {
	timeOffDetailId: number;
	employeeId: number;
	timeclockEntryId?: number;
	detailType: string;
	startDate: Date;
	endDate?: Date;
	adjustedHours?: number; 
	requestInfo: string;
	approved: boolean;
	approvedBy: string;
	totalHours: number;
}

export class TimeOffDetailModel implements ITimeOffDetailModel {
	timeOffDetailId: number;
	timeclockEntryId?: number;
	employeeId: number;
	detailType: string;
	startDate: Date;
	endDate?: Date;
	adjustedHours?: number;
	requestInfo: string;
	approved: boolean;
	approvedBy: string;
	totalHours: number;
}
