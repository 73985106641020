import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { CustomerEditComponent } from "./customer-edit.component";
import { CustomerEditDialogComponent } from "./customer-edit-dialog.component";
import { SlickButtonSpinnerModule, SlickDialogModule, SlickDropDownModule } from "slick-components";
import { SharedModule } from "@components";
import { ContactsModule } from "@components/contacts";
import { CustomersService } from "@services";

@NgModule({
	imports: [CommonModule, FormsModule, SharedModule, ContactsModule, SlickButtonSpinnerModule, SlickDialogModule, SlickDropDownModule],
	declarations: [CustomerEditComponent, CustomerEditDialogComponent],
	exports: [CustomerEditComponent, CustomerEditDialogComponent],
	providers: [CustomersService]
})
export class CustomerEditModule {
}
export { CustomerEditComponent, CustomerEditDialogComponent }




