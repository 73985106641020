import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { FormErrorComponent } from "./form-error.component";

@NgModule({
	imports: [CommonModule,
		FormsModule],
	declarations: [FormErrorComponent],
	exports: [FormErrorComponent]
})
export class FormErrorModule { }
