import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IDocumentModel, IDocumentFolderModel } from "@models";
import { GlobalsService } from "./utils/globals.service";
import { UtilsService } from './utils/utils.service';

@Injectable()
export class DocumentsService {
	constructor(private httpService: HttpService) {
	}
	async getAllFolders(rootUuid: string): Promise<IDocumentFolderModel[]> {
		return this.httpService.get(`/documents/getAllFolders?rootUuid=${rootUuid}`);
	}

	async addFolder(folder: IDocumentFolderModel): Promise<IDocumentFolderModel> {
		return this.httpService.post('documents/addFolder', folder);
	}

	async updateFolder(folder: IDocumentFolderModel): Promise<IDocumentFolderModel> {
		return this.httpService.post('documents/updateFolder', folder);
	}

	async deleteFolders(folders: IDocumentFolderModel[]): Promise<boolean> {
		const folderUuids = folders.map(x => x.uuid);
		return this.httpService.delete('documents/deleteFolders', { folderUuids: folderUuids });
	}

	async getDocuments(folderUuid: string): Promise<IDocumentModel[]> {
		if (!folderUuid || folderUuid === '0')
			return [];
		else
			return this.httpService.get(`/documents/getDocuments?folderUuid=${folderUuid}`);
	}

	async updateDocument(fileModel: IDocumentModel): Promise<IDocumentModel> {
		let returnFileModel = this.httpService.post(`/documents/updateDocument`, fileModel);
		return returnFileModel;
	}

	async updateDocuments(folderUuid: string, fileModels: IDocumentModel[]): Promise<IDocumentModel[]> {
		let formData: FormData = new FormData();
		fileModels.forEach(file => {
			if (file.base64Image && file.base64Image.length > 0) {
				let fileCopy: IDocumentModel = UtilsService.clone(file);
				delete fileCopy.file;
				delete fileCopy.thumbnailBase64Image;
				let fileJSON = JSON.stringify(fileCopy);
				formData.append('image_' + file.uuid, fileJSON);
			}
			else {
				formData.append('file', file.file, file.file.name);
			}
		})

		let returnFileModels = this.httpService.postMultipart(`/documents/updateDocuments?folderUuid=${folderUuid}`, formData);
		return Promise.resolve(returnFileModels);
	}

	async deleteDocument(documentUuid: string): Promise<boolean> {
		return this.httpService.delete(`/documents/deleteDocument?documentUuid=${documentUuid}`);
	}

	async downloadSingleFile(fileModel: IDocumentModel, downloadSize: string): Promise<string> {
		try {
			window.location.assign(`${GlobalsService.apiUrl}/documents/downloadSingleFile?companyId=${GlobalsService.companyInfo.companyId}&uuid=${fileModel.uuid}&downloadSize=${downloadSize}`);

			return Promise.resolve("Ok");
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async downloadZipFile(zipFileName: string, fileModels: IDocumentModel[], downloadSize: string): Promise<string> {
		try {

			var params = {
				zipFileName: zipFileName,
				downloadSize: downloadSize,
				fileModels: fileModels
			}
			const zipFileUrl = await this.httpService.post("/documents/getZipFileUrl", params);
			window.location.assign(zipFileUrl.url);

			return Promise.resolve("Ok");
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async openDocument(documentId: number) {
		try {

			window.open(`${GlobalsService.apiUrl}/documents/openDocument?companyId=${GlobalsService.companyInfo.companyId}&documentId=${documentId}`);

			return Promise.resolve("Ok");
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getDocumentUrl(documentId: number): Promise<string> {
		try {
			return new Promise<string>(async (resolve) => {
				const response = await this.httpService.get("/documents/getDocumentUrl", { documentId: documentId });
				resolve(response.documentUrl);
			});
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}
}