import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { SleepService, LookupService, NotesService, CustomersService, TasksService, UtilsService } from "@services";
import { IEmailerModel, ITaskModel } from "@models";
import { EmailerDialogComponent } from "@components";
import { SlickDialogComponent } from "slick-components";
import { TaskEditComponent } from "./task-edit.component";

@Component({
	selector: "task-edit-dialog",
	template: `
		<slick-dialog #dialogRef [header]="dialogHeader" [width]='600' top='50'>
			<task-edit #taskEditRef [(taskModel)]='taskModel' [showButtons]="false"></task-edit>			
			<slick-dialog-footer>
				<div class="form-group form-save-buttons">
					<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary save-button" (click)="onSaveTask()"><i class="far fa-save"></i>&nbsp;Save</button>
					<button type="button" style='width: 180px' (click)="onSaveAndEmailTask()" [slick-button-spinner]="saveAndEmailSpinnerStatus" class="btn btn-outline-primary save-button"><i class="far fa-envelope"></i>&nbsp;Save &amp; Email</button>
					<button type="button" class="btn btn-outline-danger cancel-button" (click)="onCancelTask()"><i class="far fa-ban"></i>&nbsp;Cancel</button>
				</div>
			</slick-dialog-footer>
		</slick-dialog>
		<emailer-dialog #emailerDialogRef (sendEmailClick)="onEmailSent($event)"></emailer-dialog>
`,
	providers: [NotesService]
})
export class TaskEditDialogComponent {
	@Input() taskModel: ITaskModel;

	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild("taskEditRef", { static: true }) taskEditRef: TaskEditComponent;
	@ViewChild("emailerDialogRef", { static: true }) emailerDialogRef: EmailerDialogComponent;

	res: any;

	dialogHeader: string;
	spinnerStatus: string;
	isSubmitted: boolean;
	saveAndEmailSpinnerStatus: string;

	constructor(private lookupService: LookupService,
		private tasksService: TasksService,
		private notesService: NotesService) {
	}

	showDialog(taskModel: ITaskModel): Promise<ITaskModel> {
		return new Promise<ITaskModel>((res) => {
			this.res = res;
			this.taskModel = UtilsService.clone(taskModel);

			if (this.taskModel.taskId === 0)
				this.dialogHeader = "Add Task";
			else
				this.dialogHeader = "Edit Task";

			this.dialogRef.showDialog();
		});
	}

	async onSaveTask() {
		try {
			this.taskEditRef.isSubmitted = true;
			this.saveAndEmailSpinnerStatus = "reset";
			this.spinnerStatus = "reset";

			if (this.taskEditRef.validate() == false) {
				this.spinnerStatus = "error";
				return;
			}

			await SleepService.sleep(500);
			this.spinnerStatus = "spin";

			this.taskModel = await this.taskEditRef.saveTask();

			this.spinnerStatus = "ok";

			await SleepService.sleep(1000);

			this.res(this.taskModel);
			this.dialogRef.hideDialog();
		}
		catch {
			this.spinnerStatus = "error";
		}
	}

	async onSaveAndEmailTask() {
		try {
			if (this.taskEditRef.validate() == false) {
				this.saveAndEmailSpinnerStatus = "error";
				return;
			}

			this.saveAndEmailSpinnerStatus = "spin";
			await SleepService.sleep(500);

			this.taskModel = await this.taskEditRef.saveTask();
			const emailerModel = await this.tasksService.getEmailerModel(this.taskModel);

			this.saveAndEmailSpinnerStatus = "ok";

			this.res();
			this.dialogRef.hideDialog();

			this.emailerDialogRef.showDialog(emailerModel);
		}
		catch (error) {
			this.saveAndEmailSpinnerStatus = "error";
			console.error(error);
		}
	}

	async onEmailSent(emailerModel: IEmailerModel) {

	}

	onCancelTask() {
		this.dialogRef.hideDialog();

		this.res(null);
	}
}


