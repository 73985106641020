import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { INotificationModel, IPayPeriodQuickbooksDetailModel, IQBWCTaskGridModel, ITaskModel } from "@models";
import { MessageQueueFeatureTypes } from "./message-queue-feature-types.model";
import { TasksStore } from "@stores";

@Injectable()
export class MessageQueueDispatcher {

	// Notifications
	addNotification: Subject<INotificationModel> = new Subject<INotificationModel>();
	removeNotification: Subject<number> = new Subject<number>();

	// Quickbooks
	quickbooksAddTask: Subject<number> = new Subject<number>();
	quickbooksUpdateTask: Subject<number> = new Subject<number>();
	quickbooksUpdatePayPeriodDetail: Subject<IPayPeriodQuickbooksDetailModel> = new Subject<IPayPeriodQuickbooksDetailModel>();

	// Payables
	payablesAwaitingApprovalCount: Subject<number> = new Subject<number>();

	// Tasks
	// Uses TasksStore

	// Xactware
	xactAssignmentsCount: Subject<number> = new Subject<number>();

	// Test
	test: Subject<string> = new Subject<string>();

	constructor(private tasksStore: TasksStore) {
	}

	async init() {
	}

	onSendMessage(featureType: MessageQueueFeatureTypes, action: string, data: any) {
		switch (featureType) {
			case MessageQueueFeatureTypes.Notification:
				this.processNotification(action, data);
				break;

			case MessageQueueFeatureTypes.Quickbooks:
				this.processQuickbooks(action, data);
				break;

			case MessageQueueFeatureTypes.Payables:
				this.processPayables(action, data);
				break;

			case MessageQueueFeatureTypes.Tasks:
				this.processTasks(action, data);
				break;

			case MessageQueueFeatureTypes.Xactware:
				this.processXactware(action, data);
				break;

			case MessageQueueFeatureTypes.Test:
				this.processTest(action, data);
				break;
		}
	}

	private processNotification(action: string, data: any) {
		switch (action) {
			case "ADD_NOTIFICATION":
				this.addNotification.next(<INotificationModel>data);
				break;

			case "REMOVE_NOTIFICATION":
				this.removeNotification.next(<number>data);
				break;
		}
	}

	private processQuickbooks(action: string, data: any) {
		switch (action) {
			case "QB_ADD_TASK":
				this.quickbooksAddTask.next(<number>data);
				break;

			case "QB_UPDATE_TASK":
				this.quickbooksUpdateTask.next(<number>data);
				break;

			case "QB_UPDATE_PAY_PERIOD_DETAIL":
				this.quickbooksUpdatePayPeriodDetail.next(<IPayPeriodQuickbooksDetailModel>data);
				break;

		}

	}

	private processPayables(action: string, data: any) {
		switch (action) {
			case "PAYABLES_AWAITING_APPROVAL_COUNT":
				this.payablesAwaitingApprovalCount.next(<number>data);
				break;
		}
	}

	private processTasks(action: string, data: any) {
		switch (action) {
			case "REFRESH_TASKS":
				this.tasksStore.refreshTasks();
				break;

			case "UPDATE_TASK":
				this.tasksStore.updateTask(<ITaskModel>data);
				break;
		}
	}

	private processXactware(action: string, data: any) {
		switch (action) {
			case "ADD_XACT_ASSIGNMENT":
				this.xactAssignmentsCount.next(data);
				//this.xactwareStore.setXactAssignmentCount(data);
				break;
		}
	}

	private processTest(action: string, data: any) {
		switch (action) {
			case "TEST":
				this.test.next(<string>data);
				break;
		}

	}
}