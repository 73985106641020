import { Component, Input, forwardRef, ChangeDetectorRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ContactsService } from "@services";
import { IAddressModel, AddressModel } from "@models";

@Component({
	selector: "address",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AddressComponent),
			multi: true
		}],
	templateUrl: "address.component.html"
})
export class AddressComponent implements ControlValueAccessor {
	@Input() disabled: boolean = false;
	@Input() showPlaceholder: boolean = false;
	@Input() showAddressSearch: boolean = true;

	countries: any[];
	googleAddressSearch: string = "";
	addressModel: IAddressModel;
	showAddress2: boolean = false;

	constructor(private contactsService: ContactsService,
		private changeDetector: ChangeDetectorRef) {
		this.addressModel = contactsService.getNewAddressModel();

		this.countries = [
			{ id: 'US', text: "US" },
			{ id: 'CA', text: "CA" }
		]
	}

	private propagateChange = (_: any) => { };

	// this is the initial value set to the component
	public writeValue(obj: IAddressModel) {
		if (obj) {
			this.addressModel = obj;
			this.addressModel.country = "US";
			this.showAddress2 = !!this.addressModel.address2;
			this.googleAddressSearch = "";
		}
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	onGoogleMapAddressSelect (placeResult: google.maps.places.PlaceResult) {
		this.addressModel = this.setAddress(this.addressModel, placeResult);
		this.changeDetector.detectChanges();
	}

	private setAddress(addressModel: IAddressModel, placeResult: google.maps.places.PlaceResult): IAddressModel {
		addressModel.address1 = "";
		addressModel.city = "";
		addressModel.state = "";
		addressModel.postalCode = "";
		addressModel.country = "";

		placeResult.address_components.forEach(adr => {
			adr.types.forEach(type => {
				switch (type) {
					case 'street_number':
					case 'route':
						addressModel.address1 += adr.long_name + ' ';
						break;

					case 'locality':
						addressModel.city = adr.long_name;
						break;

					case 'administrative_area_level_1':
						addressModel.state = adr.short_name;
						break;

					case 'postal_code':
						addressModel.postalCode = adr.short_name;
						break;

					case 'country':
						addressModel.country = adr.short_name;
						break;
				};
			})
		});

		addressModel.isValid = false;
		if (placeResult.geometry) {
			addressModel.latitude = placeResult.geometry.location.lat();
			addressModel.longitude = placeResult.geometry.location.lng();
			addressModel.isValid = true;
		}

		return addressModel;
	}


}
