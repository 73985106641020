import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { SleepService, LookupService, NotesService, CustomersService } from "@services";
import { EmailerDialogComponent } from "@components";
import { SlickDialogComponent } from "slick-components";
import { JobEditComponent } from "./job-edit.component";
import { IJobModel, IEmailerModel, EmailerModel } from "@models";
import * as momentImported from "moment"; const moment = momentImported;

@Component({
	selector: "job-edit-dialog",
	template: `
		<slick-dialog #dialogRef [header]="dialogHeader" [width]='800' top='50'>
			<job-edit #jobEditRef [(jobModel)]='jobModel' [showButtons]="false"></job-edit>			
			<slick-dialog-footer>
				<div class="form-group form-save-buttons">
					<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary save-button" (click)="onSaveJob()"><i class="far fa-save"></i>&nbsp;Save</button>
					<button type="button" style='width: 180px' (click)="onSaveAndEmailJob()" [slick-button-spinner]="saveAndEmailSpinnerStatus" class="btn btn-outline-primary save-button"><i class="far fa-envelope"></i>&nbsp;Save &amp; Email</button>
					<button type="button" class="btn btn-outline-danger cancel-button" (click)="onCancelJob()"><i class="far fa-ban"></i>&nbsp;Cancel</button>
				</div>
			</slick-dialog-footer>
		</slick-dialog>
		<emailer-dialog #emailerDialogRef (sendEmailClick)='onSendEmailClicked($event)'></emailer-dialog>
`,
	providers: [NotesService]
})
export class JobEditDialogComponent {
	@Input() jobModel: IJobModel;
	@Output() onSave: EventEmitter<IJobModel> = new EventEmitter<IJobModel>();
	@Output() onRefreshNotes: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild("jobEditRef", { static: true }) jobEditRef: JobEditComponent;
	@ViewChild("emailerDialogRef", { static: true }) emailerDialogRef: EmailerDialogComponent;

	dialogHeader: string;
	spinnerStatus: string;
	saveAndEmailSpinnerStatus: string;

	constructor(private lookupService: LookupService,
		private customersService: CustomersService,
		private notesService: NotesService) {
	}

	showDialog() {
		setTimeout(() => {
			this.spinnerStatus = "reset";
			this.saveAndEmailSpinnerStatus = "reset";
			this.dialogHeader = (this.jobModel && this.jobModel.jobId > 0) ? "Job Edit" : "Add Job";
			this.dialogRef.showDialog();
		});
	}

	async onSaveJob() {
		try {
			this.saveAndEmailSpinnerStatus = "reset";
			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			var isValid = this.jobEditRef.validate();
			if (!isValid) {
				this.spinnerStatus = "error";
				return;
			}

			if (this.jobModel.jobId > 0) {
				let concurrencyIsValid = await this.jobEditRef.validateConcurrencyIsValid();
				if (concurrencyIsValid === false) {
					this.spinnerStatus = "error";
					return;
				}
			}

			this.jobModel.primaryCustomer = this.customersService.setPrimaryCustomer(this.jobModel.primaryCustomer);
			if (!this.jobModel.billingCustomer || this.jobModel.billingCustomer.customerId === this.jobModel.primaryCustomer.customerId)
				this.jobModel.billingCustomer = null
			else
				this.jobModel.billingCustomer = this.customersService.setPrimaryCustomer(this.jobModel.billingCustomer);

			const customerToUse = await this.jobEditRef.checkForDuplicateAddress(this.jobModel.primaryCustomer);

			if (customerToUse === null) {
				this.spinnerStatus = "reset";
				this.jobEditRef.isSubmitted = false;
				return;
			}

			let updatedModel: IJobModel = await this.jobEditRef.saveJob(this.jobModel);
			this.spinnerStatus = "ok";
			this.dialogRef.hideDialog();
			this.onSave.emit(updatedModel);
		}
		catch (error) {
			console.error(error);
			this.spinnerStatus = "error";
		}
	}

	async onSaveAndEmailJob() {
		try {
			this.spinnerStatus = "reset";
			this.saveAndEmailSpinnerStatus = "spin";

			await SleepService.sleep(500);

			var isValid = this.jobEditRef.validate();
			if (!isValid) {
				this.saveAndEmailSpinnerStatus = "error";
				return;
			}

			if (this.jobModel.jobId > 0) {
				let concurrencyIsValid = await this.jobEditRef.validateConcurrencyIsValid();
				if (concurrencyIsValid === false) {
					this.spinnerStatus = "error";
					return;
				}
			}

			this.jobModel.primaryCustomer = this.customersService.setPrimaryCustomer(this.jobModel.primaryCustomer);
			if (!this.jobModel.billingCustomer || this.jobModel.billingCustomer.customerId === this.jobModel.primaryCustomer.customerId)
				this.jobModel.billingCustomer = null
			else
				this.jobModel.billingCustomer = this.customersService.setPrimaryCustomer(this.jobModel.billingCustomer);

			const customerToUse = await this.jobEditRef.checkForDuplicateAddress(this.jobModel.primaryCustomer);

			if (customerToUse === null) {
				this.spinnerStatus = "reset";
				this.jobEditRef.isSubmitted = false;
				return;
			}

			this.jobModel = await this.jobEditRef.saveJob(this.jobModel);
			this.saveAndEmailSpinnerStatus = "ok";
			this.dialogRef.hideDialog();
			this.onSave.emit(this.jobModel);

			let emailerModel: IEmailerModel = new EmailerModel();
			emailerModel.referenceId = this.jobModel.jobId;
			emailerModel.subject = this.jobModel.jobName;
            emailerModel.body = `
<h2>${this.jobModel.jobName}</h2>
${this.jobModel.primaryCustomer.contact.displayName}<br />
${this.jobModel.primaryCustomer.contact.defaultAddress.fullAddress}<br />`;
            // Cell Phone #
            if (this.jobModel.primaryCustomer.contact.defaultCell && this.jobModel.primaryCustomer.contact.defaultCell.phoneNumber)
                emailerModel.body += `Cell: ${this.jobModel.primaryCustomer.contact.defaultCell.phoneNumber}<br />`;
            // Alt1 Phone #
            if (this.jobModel.primaryCustomer.contact.defaultAlt1 && this.jobModel.primaryCustomer.contact.defaultAlt1.phoneNumber)
                emailerModel.body += `Alt1: ${this.jobModel.primaryCustomer.contact.defaultAlt1.phoneNumber}<br />`;
            // Alt2 Phone #
            if (this.jobModel.primaryCustomer.contact.defaultAlt2 && this.jobModel.primaryCustomer.contact.defaultAlt2.phoneNumber)
                emailerModel.body += `Alt2: ${this.jobModel.primaryCustomer.contact.defaultAlt2.phoneNumber}<br />`;

            emailerModel.body += `Status: ${this.jobModel.jobStatusDescription}<br />`;
			// Claim #
			if (this.jobModel.claimNumber)
				emailerModel.body += `Date of Loss: ${this.jobModel.claimNumber}<br />`;
			// Date of loss
			if (this.jobModel.dateOfLoss)
				emailerModel.body += `Date of Loss: ${moment(this.jobModel.dateOfLoss).format()}<br />`;
			// Claim #
			if (this.jobModel.claimNumber)
				emailerModel.body += `Date of Loss: ${this.jobModel.claimNumber}<br />`;
			// Estimator
			if (this.jobModel.estimatorName)
				emailerModel.body += `Estimator: ${this.jobModel.estimatorName}<br />`;
			// Production Manager
			if (this.jobModel.productionManagerName)
				emailerModel.body += `Production Manager: ${this.jobModel.productionManagerName}<br />`;
			// Specialty Manager
			if (this.jobModel.specialtyManagerName)
				emailerModel.body += `Specialty Manager: ${this.jobModel.specialtyManagerName}<br />`;
			// Referral
			if (this.jobModel.referralDisplayName)
				emailerModel.body += `Referral: ${this.jobModel.referralDisplayName}<br />`;
			// Adjuster
			if (this.jobModel.insuranceAdjusterName)
				emailerModel.body += `Adjuster: ${this.jobModel.insuranceAdjusterName}<br />`;
			// Agent
			if (this.jobModel.insuranceAgentName)
				emailerModel.body += `Agent: ${this.jobModel.insuranceAgentName}<br />`;
			// Scratchpad
			if (this.jobModel.scratchPad)
				emailerModel.body += `Additional Notes: <pre>${this.jobModel.scratchPad}</pre><br />`;


			emailerModel.toEmailAddresses = [];
			if (this.jobModel.estimatorUserId) {
				const estimator = (await this.lookupService.getEmployees(this.jobModel.estimatorUserId, true)).find(x => x.userId === this.jobModel.estimatorUserId);
				if (estimator && estimator.emailAddress) {
					emailerModel.toEmailAddresses.push({
						contactId: estimator.userId,
						displayName: estimator.fullName,
						emailAddress: estimator.emailAddress
					});
				}
			}

			this.emailerDialogRef.showDialog(emailerModel);
		}
		catch (error) {
			console.error(error);
			this.saveAndEmailSpinnerStatus = "error";
		}
	}

	async onSendEmailClicked(emailerModel: IEmailerModel) {
		this.notesService.noteType = 'Job';
		this.notesService.referenceId = emailerModel.referenceId;
		const emailerAddresses = emailerModel.toEmailAddresses.map(x => x.displayName).join(", ");
		const noteModel = await this.notesService.saveNote(`Job emailed to ${emailerAddresses}`);
		await this.notesService.linkNoteToEmail(noteModel.uuid, emailerModel.uuid);
		this.onRefreshNotes.emit();
	}

	onCancelJob() {
		this.dialogRef.hideDialog();
		this.onCancel.emit();
	}
}


