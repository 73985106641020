import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, OnInit } from "@angular/core";
import { ICustomerModel, IDropdownModel } from "@models";
import { ContactEditComponent } from "@components/contacts";
import { SleepService, CustomersService, ConcurrencyValidationService, ConcurrencyRecordTypes, LookupService } from "@services";
import { ConcurrencyErrorDialogComponent } from "@components";

@Component({
	selector: "customer-edit",
	templateUrl: "customer-edit.component.html"
})

export class CustomerEditComponent implements OnInit, OnChanges {
	@Input() customerModel: ICustomerModel;
	@Input() showQuickbooksName: boolean = false;
	@Input() showButtons: boolean = true;
	@Output() onSave: EventEmitter<ICustomerModel> = new EventEmitter<ICustomerModel>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("contactEditRef") contactEditRef: ContactEditComponent;
	@ViewChild("concurrencyErrorDialogRef", { static: true }) concurrencyErrorDialogRef: ConcurrencyErrorDialogComponent;

	spinnerStatus: string;
	isSubmitted: boolean = false;
	concurrencyError: string;

	paymentTerms: IDropdownModel[];

	constructor(
		private customersService: CustomersService,
		private lookupService: LookupService,
		private concurrencyValidationService: ConcurrencyValidationService
	) { }

	async ngOnInit() {
		this.paymentTerms = await this.lookupService.getPaymentTermsForDropdown();
	}

	ngOnChanges() {
		this.isSubmitted = false;
		this.spinnerStatus = "reset";
	}

	async onPaymentTermSelected(paymentTerm: IDropdownModel) {
		if (!paymentTerm) {
			this.customerModel.defaultPaymentTermId = null;
			this.customerModel.defaultPaymentTermDescription = null;
		}
		else {
			this.customerModel.defaultPaymentTermId = paymentTerm.id;
			this.customerModel.defaultPaymentTermDescription = paymentTerm.text;
		}
	}

	validate(): boolean {
		this.isSubmitted = true;

		let valid = true;

		if (this.contactEditRef.validate() === false)
			valid = false;

		return valid;
	}

	async validateConcurrencyIsValid(): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			this.concurrencyError = await this.concurrencyValidationService.checkConcurrency(this.customerModel.customerId, ConcurrencyRecordTypes.Customer, this.customerModel.version);
			if (this.concurrencyError) {
				await this.concurrencyErrorDialogRef.show("Concurrency Error", this.concurrencyError);
				resolve(false);
			}

			resolve(true);
		})
	}

	async onSaveCustomer() {
		try {
			// Any validation needs to go here
			if (this.validate() === false)
				return;

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			if (this.customerModel.customerId > 0) {
				let concurrencyIsValid = await this.validateConcurrencyIsValid();
				if (concurrencyIsValid === false) {
					this.spinnerStatus = "error";
					return;
				}
			}

			let updatedModel: ICustomerModel = await this.customersService.updateCustomer(this.customerModel);
			this.spinnerStatus = "ok";
			this.onSave.emit(updatedModel);
		}
		catch (error) {
			console.error(error);
			this.spinnerStatus = "error";
		}
	}

	onCancelCustomer() {
		this.onCancel.emit();
	}
}