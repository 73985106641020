import { Directive, Input, OnChanges, OnInit } from "@angular/core";
import { DropdownModel, EmailerAddressModel, EmailerModel, IDropdownModel, IEmailerModel, ITaskModel } from "@models";
import { GlobalsService, HttpService, LookupService, SleepService, TasksService, UtilsService } from "@services";
import { TasksStore } from "@stores";

import * as moment from 'moment';

@Directive()
export class TaskEditBase implements OnChanges, OnInit {
	@Input() taskModel: ITaskModel;

	dueDate: Date;
	dueDateTime: string;
	isValidTime: boolean;

	reminderOptions: DropdownModel[] = [
		new DropdownModel("No Reminder", "No Reminder"),
		new DropdownModel("0 minutes", "0 minutes"),
		new DropdownModel("1 minute before", "1 minute before"),
		new DropdownModel("5 minutes before", "5 minutes before"),
		new DropdownModel("10 minutes before", "10 minute before"),
		new DropdownModel("15 minutes before", "15 minutes before"),
		new DropdownModel("30 minutes before", "30 minutes before"),
		new DropdownModel("1 hour before", "1 hour before"),
		new DropdownModel("2 hours before", "2 hours before"),
		new DropdownModel("4 hours before", "4 hours before"),
		new DropdownModel("8 hours before", "8 hours before"),
		new DropdownModel("1 day before", "1 day before"),
	]

	employees: IDropdownModel[] = [];
	activeJobs: IDropdownModel[] = [];


	constructor(protected tasksService: TasksService,
		protected tasksStore: TasksStore,
		protected lookupService: LookupService,
		protected httpService: HttpService) {
	}

	async ngOnInit() {
		const results = await Promise.all([
			this.lookupService.getEmployeesForDropdown(),
			this.httpService.get("/jobs/getActiveJobsForTasks")
		])

		this.employees = results[0];
		this.activeJobs = results[1];

	}

	ngOnChanges() {
		this.isValidTime = true;

		this.setDueDate();
	}

	async onDueDateTimeChanged() {
		await SleepService.sleep();

		this.isValidTime = true;

		if (!this.dueDateTime) {
			this.dueDateTime = null;
		}
		else {
			const parsed = moment(this.dueDateTime, "hh:mm a", false);
			if (parsed.isValid() == false) {
				this.isValidTime = false;
				return;
			}

			this.dueDateTime = parsed.format("h:mm a");
		}
	}

	validate(): boolean {
		if (!this.taskModel.title)
			return false;

		if (!this.isValidTime)
			return false;


		return true;
	}

	async saveTask(): Promise<ITaskModel> {
		if (moment(this.dueDate).isValid() || moment(this.dueDateTime, "hh:mm a", false).isValid()) {
			if (moment(this.dueDate).isValid())
				this.dueDate = moment(this.dueDate).startOf("day").toDate();
			else
				this.dueDate = moment().startOf("day").toDate();

			if (moment(this.dueDateTime, "hh:mm a", false).isValid()) {
				const time = moment(this.dueDateTime, "hh:mm a", false);

				this.dueDate = moment(this.dueDate).set({
					hour: time.get("hour"),
					minute: time.get("minute")
				}).toDate();
			}
		}

		this.taskModel.dueDate = this.dueDate;

		if ((this.taskModel.assignedToUserIds?.length ?? 0) === 0)
			this.taskModel.assignedToUserIds = [GlobalsService.userInfo.userId];

		this.setDueDate();

		if (this.taskModel.taskId === 0)
			this.taskModel = await this.tasksService.addTask(this.taskModel);
		else
			this.taskModel = await this.tasksService.updateTask(this.taskModel);

		if (this.taskModel.isCompleted)
			this.tasksStore.removeTask(this.taskModel.taskId);

		this.tasksStore.updateTask(this.taskModel);

		return this.taskModel;
	}

	private setDueDate() {
		this.dueDate = null;
		this.dueDateTime = null;

		if (this.taskModel) {
			if (this.taskModel.dueDate) {
				this.dueDate = moment(this.taskModel.dueDate).startOf("day").toDate();
				this.dueDateTime = moment(this.taskModel.dueDate).format("h:mm a");
			}
		}
	}
}
