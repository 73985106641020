import { ContactModel, IContactModel } from "../contacts/contact.model";
import { UtilsService } from "@services/utils/utils.service";

export interface IBusinessDevelopmentContactModel {
	businessDevelopmentAccountId: number;
	businessDevelopmentContactId: number;
	businessDevelopmentContactRoleId?: number;
	businessDevelopmentContactRoleName: string;
	uuid: string;
	contactId: number;
	isDeleted: boolean;
	note: string;

	contact: IContactModel
}

export class BusinessDevelopmentContactModel implements IBusinessDevelopmentContactModel {
	businessDevelopmentAccountId: number;
	businessDevelopmentContactId: number;
	businessDevelopmentContactRoleId?: number;
	businessDevelopmentContactRoleName: string;
	uuid: string;
	contactId: number;
	isDeleted: boolean;
	note: string;

	contact: IContactModel

	constructor(businessDevelopmentAccountId: number) {
		this.businessDevelopmentContactId = 0;
		this.businessDevelopmentAccountId = businessDevelopmentAccountId;
		this.businessDevelopmentContactRoleId = null;
		this.uuid = UtilsService.newGuid();
		this.contact = new ContactModel();
	}
}