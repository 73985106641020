import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IEmailerModel } from "@models";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn: "root"})
export class EmailerService {
	constructor(private httpService: HttpService) { }

	public async getEmail(emailId: number): Promise<IEmailerModel> {
		return Promise.resolve(await this.httpService.get("/emailer/getEmail", { emailId: emailId }));
	}
}

