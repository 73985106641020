export interface IAddressModel {
	addressId: number;
	uuid: string;
	addressTypeId: number;
	addressTypeName: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	fullAddress: string;
	cityStatePostal: string;
	latitude?: number;
	longitude?: number;
	isValid: boolean;
	isDefault: boolean;
	active: boolean;
}

export class AddressModel implements IAddressModel {
	addressId: number;
	uuid: string;
	addressTypeId: number;
	addressTypeName: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	fullAddress: string;
	cityStatePostal: string;
	latitude?: number;
	longitude?: number;
	isValid: boolean;
	isDefault: boolean;
	active: boolean;
}
