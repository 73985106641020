export interface IItemModel {
	itemId: number;
	qbwcClassId?: number;
	qbwcClassName: string;
	itemCode: string;
	description: string;
	defaultMargin: number;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	quickbooksCategoryId: string;
	quickbooksTimeclockLaborCode: string;
	active: boolean;
}

export class ItemModel implements IItemModel {
	itemId: number;
	qbwcClassId?: number;
	qbwcClassName: string;
	itemCode: string;
	description: string;
	defaultMargin: number;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	quickbooksCategoryId: string;
	quickbooksTimeclockLaborCode: string;
	active: boolean;
}