import { ContactModel, IContactModel } from "@models/contacts/contact.model";
import { ISubcontractorTradeModel } from "./subcontractor-trade.model";
import { UtilsService } from "@services";

export interface ISubcontractorModel {
	subcontractorId: number;
	uuid: string;
	documentFolderUuid: string;
	version: number;
	userName: string;
	password: string;
	showOnSchedule: boolean;
	scheduleColor: string;
	visible: boolean;
	active: boolean;

	contact: IContactModel;
	trades: ISubcontractorTradeModel[];
}

export class SubcontractorModel implements ISubcontractorModel {
	constructor() {
		this.subcontractorId = 0;
		this.uuid = UtilsService.newGuid();
		this.documentFolderUuid = UtilsService.newGuid();
		this.version = 1;
		this.showOnSchedule = true;
		this.scheduleColor = "#cccccc";
		this.active = true;

		this.contact = new ContactModel();
		this.trades = [];
	}

	subcontractorId: number;
	uuid: string;
	documentFolderUuid: string;
	version: number;
	userName: string;
	password: string;
	showOnSchedule: boolean;
	scheduleColor: string;
	visible: boolean;
	active: boolean;

	contact: IContactModel;
	trades: ISubcontractorTradeModel[];
}
