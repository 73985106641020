import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { FormErrorModule } from "@shared-components/form-error";
import { NotAcceptedReasonDialogComponent } from "./not-accepted-reason-dialog.component";
import { SlickButtonSpinnerModule, SlickDialogModule, SlickDropDownModule } from "slick-components";

@NgModule({
	imports: [CommonModule,
		FormsModule,	
		FormErrorModule,
		SlickButtonSpinnerModule, SlickDialogModule, SlickDropDownModule],
	declarations: [NotAcceptedReasonDialogComponent],
	exports: [NotAcceptedReasonDialogComponent]
})
export class NotAcceptedReasonDialogModule { }

