import { UtilsService } from "@services";
import * as moment from 'moment';

export interface IAppliedPaymentModel {
	appliedPaymentId: number;
	uuid: string;
	paymentId: number;
	paymentReferenceNumber: string;
	jobId: number;
	jobName: string;
	invoiceId?: number;
	invoiceNumber: string;
	appliedAmount: number;
	appliedDate: Date;
}

export class AppliedPaymentModel implements IAppliedPaymentModel {
	constructor() {
		this.appliedPaymentId = 0;
		this.uuid = UtilsService.newGuid();
		this.appliedDate = moment().startOf('day').toDate();
	}

	appliedPaymentId: number;
	uuid: string;
	paymentId: number;
	paymentReferenceNumber: string;
	jobId: number;
	jobName: string;
	invoiceId?: number;
	invoiceNumber: string;
	appliedAmount: number;
	appliedDate: Date;
}