import { IEmployeePayRateLaborCode } from "../payroll/employee-pay-rate-labor-code.model";

export interface IEmployeePayRateModel {
	employeePayRateId: number;
	userId: number;
	effectiveDate: Date;

	regularPayRate: number;
	overtimePayRate: number;

	employeePayRateLaborCodes: IEmployeePayRateLaborCode[];

	editing: boolean;
}

export class EmployeePayRateModel implements IEmployeePayRateModel {
	employeePayRateId: number;
	userId: number;
	effectiveDate: Date;

	regularPayRate: number;
	overtimePayRate: number;

	employeePayRateLaborCodes: IEmployeePayRateLaborCode[];

	editing: boolean;
}