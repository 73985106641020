export interface IDamageTypeModel {
	damageTypeId: number;
	description: string;
	active: boolean;
}

export class DamageTypeModel implements IDamageTypeModel {
	damageTypeId: number;
	description: string;
	active: boolean;
}