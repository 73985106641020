import { Injectable } from "@angular/core";
import { ITimeclockEntryModel, ITimeclockBreakModel, ITimeclockWeeklySummaryModel, ITimeclockUserGridModel, ITimeclockUserCurrentJobModel } from "@models";
import { HttpService } from "./utils/http.service";
import * as moment from "moment";
import { IPayPeriodModel } from '@models';

@Injectable()
export class TimeclockService {
	constructor(private httpService: HttpService) { }

	async getTimeclockEntry(timeclockEntryId: number): Promise<ITimeclockEntryModel> {
		const params = {
			timeclockEntryId: timeclockEntryId
		}
		return await this.httpService.get("/timeclock/getTimeclockEntry", params);
	}

    async getTimeclockUserForGrid(userId: number, payPeriodId: number): Promise<ITimeclockUserGridModel> {
        return await this.httpService.get(`/timeclock/getTimeclockUserForGrid?userId=${userId}&payPeriodId=${payPeriodId}`);
	}

	async getTimeclockUsersCurrentJobs(): Promise<ITimeclockUserCurrentJobModel[]> {
		return await this.httpService.get('/timeclock/getTimeclockUsersCurrentJobs');
	}

	async getPayPeriodSyncForUser(employeeId: number, payPeriodId: number): Promise<boolean> {
		const params = {
			employeeId: employeeId,
			payPeriodId: payPeriodId
		}
		return this.httpService.get("/timeclock/getPayPeriodSyncForUser", params);

	}

	async getAllTimeclockEntriesForUser(employeeId: number, payPeriodId: number, filter: string): Promise<ITimeclockEntryModel[]> {
		const params = {
			employeeId: employeeId,
			payPeriodId: payPeriodId,
			filter: filter || 'all'
		}
		return await this.httpService.get("/timeclock/getAllTimeclockEntriesForUser", params);

	}

	async getLastTimeclockEntry(userId: number): Promise<ITimeclockEntryModel> {
		const params = {
			userId: userId
		}
		return await this.httpService.get("/timeclock/getLastTimeclockEntry", params);
	}

	async getWeeklySummaryForUser(userId: number, startDate: Date, endDate: Date): Promise<ITimeclockWeeklySummaryModel> {
		const params = {
			userId: userId,
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
		}
		return await this.httpService.get("/timeclock/getWeeklySummaryForUser", params);
	}

	async clockIn(timeclockEntryModel: ITimeclockEntryModel): Promise<ITimeclockEntryModel> {
		return await this.httpService.post("/timeclock/clockIn", timeclockEntryModel);
	}

	async clockOut(timeclockEntryModel: ITimeclockEntryModel): Promise<ITimeclockEntryModel> {
		return await this.httpService.post("/timeclock/clockOut", timeclockEntryModel);
	}

	async startBreak(timeclockBreakModel: ITimeclockBreakModel): Promise<ITimeclockBreakModel> {
		return await this.httpService.post("/timeclock/startBreak", timeclockBreakModel);
	}

	async endBreak(timeclockBreakModel: ITimeclockBreakModel): Promise<ITimeclockBreakModel> {
		return await this.httpService.post("/timeclock/endBreak", timeclockBreakModel);
	}

	async addTimeclockEntry(timeclockEntryModel: ITimeclockEntryModel): Promise<ITimeclockEntryModel> {
		return await this.httpService.post("/timeclock/addTimeclockEntry", timeclockEntryModel);
	}

	async updateTimeclockEntry(timeclockEntryModel: ITimeclockEntryModel): Promise<ITimeclockEntryModel> {
		return await this.httpService.post("/timeclock/updateTimeclockEntry", timeclockEntryModel);
	}

	async deleteTimeclockEntry(timeclockEntryId: number): Promise<void> {
        return await this.httpService.delete(`/timeclock/deleteTimeclockEntry?timeclockEntryId=${timeclockEntryId}`);
	}

    getTotalMinutes(timeclockEntry: ITimeclockEntryModel): number {
		return Math.trunc(moment.duration(moment(timeclockEntry.clockOut || new Date()).diff(moment(timeclockEntry.clockIn))).asMinutes());
	}

	getTotalSeconds(timeclockEntry: ITimeclockEntryModel): number {
		return Math.trunc(moment.duration(moment(timeclockEntry.clockOut || new Date()).diff(moment(timeclockEntry.clockIn))).asSeconds());
	}

	getTotalMinutesDisplay(timeclockEntry: ITimeclockEntryModel, includeSeconds: boolean = true): string {
		const format = includeSeconds ? "H:mm:ss" : "H:mm";
		return moment.utc(this.getTotalSeconds(timeclockEntry) * 1000).format(format);
	}

	getTotalMinutesWorked(timeclockEntry: ITimeclockEntryModel): number {
		return Math.trunc(this.getTotalMinutes(timeclockEntry) - this.getTotalBreakMinutes(timeclockEntry));
	}

	getTotalMinutesWorkedDisplay(timeclockEntry: ITimeclockEntryModel, includeSeconds: boolean = true): string {
		const format = includeSeconds ? "H:mm:ss" : "H:mm";
		return moment.utc(this.getTotalMinutesWorked(timeclockEntry) * 60 * 1000).format(format);
	}

	getTotalBreakMinutes(timeclockEntry: ITimeclockEntryModel): number {
		let total = 0;
        timeclockEntry.breaks.forEach(b => {
            if (b.totalMinutes)
                total += b.totalMinutes
            else
			    total += moment.duration(moment(b.breakEnd).diff(moment(b.breakStart))).asMinutes();
		});

		return Math.trunc(total);
	}

	getTotalBreakMinutesDisplay(timeclockEntry: ITimeclockEntryModel, includeSeconds: boolean = true): string {
		const format = includeSeconds ? "H:mm:ss" : "H:mm";
		return moment.utc(this.getTotalBreakMinutes(timeclockEntry) * 60 * 1000).format(format);
	}

	recalc(timeclockEntry: ITimeclockEntryModel): ITimeclockEntryModel {
		timeclockEntry.totalMinutes = this.getTotalMinutes(timeclockEntry);
		timeclockEntry.totalDisplay = this.getTotalMinutesDisplay(timeclockEntry, false);
		timeclockEntry.totalWorkedMinutes = this.getTotalMinutesWorked(timeclockEntry);
		timeclockEntry.totalWorkedDisplay = this.getTotalMinutesWorkedDisplay(timeclockEntry, false);
		timeclockEntry.totalBreakMinutes = this.getTotalBreakMinutes(timeclockEntry);
		timeclockEntry.totalBreakDisplay = this.getTotalBreakMinutesDisplay(timeclockEntry, false);
		return timeclockEntry;
	}

	validateTimeclockEntry(timeclockEntry: ITimeclockEntryModel) {

	}

	async approveTimeclockEntry(timeclockEntryId: number): Promise<ITimeclockEntryModel> {		
		return await this.httpService.patch(`/timeclock/approveTimeclockEntry?timeclockEntryId=${timeclockEntryId}`);
	}

	getCoordString(coords: GeolocationCoordinates): string {
		return JSON.stringify({ latitude: coords.latitude, longitude: coords.longitude });
	}
}
