import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { SlickDialogModule } from "slick-components";
import { QuickbooksTaskDisplayComponent } from "./quickbooks-task-display.component";
import { QuickbooksTaskDisplayDialogComponent } from "./quickbooks-task-display-dialog.component";

@NgModule({
	imports: [CommonModule, FormsModule, SlickDialogModule],
	declarations: [QuickbooksTaskDisplayComponent, QuickbooksTaskDisplayDialogComponent],
	exports: [QuickbooksTaskDisplayComponent, QuickbooksTaskDisplayDialogComponent]
})
export class QuickbooksTaskDisplayModule {
}
export { QuickbooksTaskDisplayComponent } 
export { QuickbooksTaskDisplayDialogComponent }




