<slick-container *ngIf="taskModel" maxHeight="calc(100vh - 70px)">
    <slick-container-body>
        <div class="d-flex flex-column" style="min-width: 520px; max-width: 520px;">
            <div class="d-flex mb-1">
                <div class="flex-fill">
                    <label>Title</label>
                    <input #titleRef type="text" class="form-control" [(ngModel)]="taskModel.title" [slick-validation-indicator]="taskModel.title" />
                    <form-error *ngIf="isSubmitted && !taskModel.title">Title is required</form-error>
                </div>
            </div>

            <div class="d-flex mb-1">
                <div class="flex-fill">
                    <label>Description</label>
                    <textarea class="form-control" rows="2" [(ngModel)]="taskModel.description"></textarea>
                </div>
            </div>

            <div class="d-flex mb-1">
                <div style="min-width: 520px; max-width: 520px;">
                    <label>Owner</label>
                    <slick-drop-down [(ngModel)]="taskModel.ownerUserId"
                                     [items]="employees"
                                     [allowEmpty]="false"></slick-drop-down>
                </div>
            </div>

            <div class="d-flex mb-1">
                <div style="min-width: 520px; max-width: 520px;">
                    <label>Assigned To</label>
                    <slick-multi-select name="assignedUsers"
                                        [(ngModel)]="taskModel.assignedToUserIds"
                                        [items]="employees"
                                        searchType="eachWord">
                    </slick-multi-select>

                </div>
            </div>


            <div class="d-flex mb-1">
                <div style="min-width: 520px; max-width: 520px;">
                    <label>Job</label>
                    <slick-drop-down #jobDropdownRef
                                     [(ngModel)]="taskModel.jobId"
                                     [items]="activeJobs"
                                     name="jobId"
                                     placeholder="-- None --"
                                     searchType="eachWord"
                                     [disabled]="taskModel.lockJobDropDown"></slick-drop-down>

                </div>
            </div>

            <div class="d-flex mb-1 me-1">
                <div class="me-1">
                    <label>Due Date</label>
                    <input type="text" class="form-control" style="text-align: center; width: 110px" [slick-date-picker] [(ngModel)]="dueDate" (change)="onDueDateChanged()" />
                </div>

                <div>
                    <label>Time</label>
                    <input type="text" class="form-control" style="text-align: center; width: 100px" [(ngModel)]="dueDateTime" (change)="onDueDateTimeChanged()" />
                    <form-error *ngIf="!isValidTime">Invalid time</form-error>
                </div>
            </div>

            <div class="d-flex mb-1">
                <div>
                    <label>Reminder</label>
                    <slick-drop-down [(ngModel)]="taskModel.reminderLength"
                                     [items]="reminderOptions"
                                     [allowEmpty]="false"></slick-drop-down>
                </div>
            </div>

            <div class="d-flex mt-2">
                <div>
                    <input type="checkbox" [(ngModel)]="taskModel.isCompleted" />Complete
                </div>
            </div>
            <div *ngIf="taskModel.completedDate" class="d-flex mb-1">
                Completed {{taskModel.completedDate | date:'M/d/yyyy h:mm:ss a'}}
            </div>
        </div>
    </slick-container-body>

    <slick-container-footer *ngIf="showButtons">
        <div class="border-top mt-2 pt-2 mb-3 me-3" style="min-width: 500px; max-width: 1200px;">
            <button class="save-button btn btn-outline-success me-2" (click)="onSave()" [slick-button-spinner]="spinnerStatus" style="width: 180px;"><i class="far fa-save"></i> Save</button>
            <button type="button" style='width: 180px' (click)="onSaveAndEmailTask()" [slick-button-spinner]="saveAndEmailSpinnerStatus" class="btn btn-outline-primary save-button"><i class="far fa-envelope"></i>&nbsp;Save &amp; Email</button>
            <button class="cancel-button btn btn-outline-danger" (click)="onCancel()" style="width: 180px;"><i class="far fa-ban"></i> Cancel</button>
        </div>
    </slick-container-footer>

</slick-container>

<emailer-dialog #emailerDialogRef (sendEmailClick)="onEmailSent($event)"></emailer-dialog>
