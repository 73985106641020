import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, Pipe, PipeTransform } from "@angular/core";
import { Form, NgForm } from "@angular/forms";
import { SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickDialogComponent } from "slick-components";
import { NoDefaultPipe } from "../no-default.pipe";
import { IAddressModel, AddressModel } from "@models";
import { UtilsService, SleepService, ContactsService } from "@services";

@Component({
	selector: "addressesEdit",
	templateUrl: "addresses-edit.component.html",
	styles: [`
		.addressBlock { padding: 5px; padding-left: 10px; line-height: 1.1em; }
		.addressBlock:nth-child(even) { background: #f8f8f8; }
		.addressBlock .far { margin-right: 3px; }`]
})
export class AddressesEditComponent implements OnChanges {
	@Input() addressModels: IAddressModel[];
	@Output() addressModelsChange: EventEmitter<IAddressModel[]> = new EventEmitter<IAddressModel[]>();

	defaultAddress: IAddressModel = this.contactsService.getNewAddressModel();
	dialogModel: IAddressModel = this.contactsService.getNewAddressModel();
	countries: any[];

	@ViewChild("addressesEditForm") addressEditForm: NgForm;
	@ViewChild("addressDialogRef", { static: true }) addressDialogRef: SlickDialogComponent;
	@ViewChild("deleteAddressRef", { static: true }) deleteAddressRef: SlickConfirmDialogComponent;

	constructor(private contactsService: ContactsService) {


		this.countries = [
			{ id: 'US', text: "US" },
			{ id: 'CA', text: "CA" }
		]
	}

	async ngOnChanges() {
		await SleepService.sleep();
		if (!this.addressModels || this.addressModels.length === 0) {
			this.addressModels = new Array<AddressModel>();
			this.addressModels.push(this.contactsService.getNewAddressModel(true));
		}

		// Find the address with default address checked
		this.defaultAddress = this.addressModels.find((a) => a.isDefault === true);
		// If none are checked, just grab the first one
		if (this.defaultAddress === null)
			this.defaultAddress = this.addressModels[0];

		this.addressModelsChange.emit(this.addressModels);
	}

	addNewAddress() {
		this.dialogModel = this.contactsService.getNewAddressModel();
		this.addressDialogRef.showDialog();
	}

	editAddress(addressModel: IAddressModel) {
		this.dialogModel = UtilsService.clone(addressModel);
		this.addressDialogRef.showDialog();
		this.addressModelsChange.emit(this.addressModels);
	}

	async deleteAddress(addressModel: IAddressModel) {
		let confirmResults: SlickConfirmDialogResults = await this.deleteAddressRef.confirm();

		if (confirmResults === SlickConfirmDialogResults.Ok) {
			this.addressModels = this.addressModels.filter((a: IAddressModel) => a.uuid !== addressModel.uuid);
			this.addressModelsChange.emit(this.addressModels);
		}
	}

	onUpdate() {
		let addressModel = this.addressModels.find((a: IAddressModel) => a.uuid === this.dialogModel.uuid);
		if (!addressModel)
			this.addressModels.push(this.dialogModel);
		else
			Object.assign(addressModel, this.dialogModel);

		// If true, we're changing the default address
		if (this.dialogModel.isDefault === true) {
			this.addressModels.forEach((a: IAddressModel) => a.isDefault = false);
			let newDefaultAddressModel = this.addressModels.find((a: IAddressModel) => a.uuid === this.dialogModel.uuid);
			newDefaultAddressModel.isDefault = true;
			this.defaultAddress = newDefaultAddressModel;
		}

		this.addressDialogRef.hideDialog();

		this.addressModelsChange.emit(this.addressModels);
	}

	onCancel() {
		this.addressDialogRef.hideDialog();
	}
}
