export interface IJobCustomStatusModel {
	jobCustomStatusId: number;
	status: string;
	active: boolean;
}

export class JobCustomStatusModel implements IJobCustomStatusModel {
	jobCustomStatusId: number;
	status: string;
	active: boolean;
}