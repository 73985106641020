export interface IMobileBusinessDevelopmentListSettingsModel {
	sortBy: number;
}

export class MobileBusinessDevelopmentListSettingsModel implements IMobileBusinessDevelopmentListSettingsModel {
	constructor() {
		this.sortBy = 0;
	}

	sortBy: number;
}