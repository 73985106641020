import { Injectable } from "@angular/core";
import { UtilsService } from "./utils/utils.service";
import { HttpService } from "./utils/http.service";
import { IAppliedPaymentJobInvoiceSummaryModel, IAppliedPaymentModel, IAppliedPaymentSummaryModel, IEmailerModel, IInvoiceModel, IJobModel, IPaymentGridModel, IPaymentModel, PaymentModel } from "@models";
import { GlobalsService } from "./utils/globals.service";
import { ISlickFileModel } from "slick-components";

@Injectable()
export class PaymentsService {
	constructor(private httpService: HttpService) {
	}

	async getPayment(paymentId: number): Promise<IPaymentModel> {
		return this.httpService.get("/payments/getPayment", { paymentId: paymentId });
	}

	async getPaymentForGrid(paymentId: number): Promise<IPaymentGridModel> {
		return this.httpService.get("/payments/getPaymentForGrid", { paymentId: paymentId });
	}

	async applyPayment(appliedPayment: IAppliedPaymentModel): Promise<IAppliedPaymentModel> {
		return this.httpService.post("/payments/applyPayment", appliedPayment);
	}

	async unapplyPayment(appliedPaymentUuid: string): Promise<void> {
		return this.httpService.delete(`/payments/unapplyPayment?appliedPaymentUuid=${appliedPaymentUuid}`);
	}

	async updateAppliedPayment(appliedPayment: IAppliedPaymentModel): Promise<IAppliedPaymentModel> {
		return this.httpService.post("/payments/updateAppliedPayment", appliedPayment);
	}

	async getAppliedPayment(appliedPaymentId: number): Promise<IAppliedPaymentModel> {
		return this.httpService.get(`/payments/getAppliedPayment`, { appliedPaymentId: appliedPaymentId })
	}

	async getAppliedPaymentsForJob(jobId: number): Promise<IAppliedPaymentSummaryModel[]> {
		return this.httpService.get("/payments/getAppliedPaymentsForJob", { jobId: jobId });
	}

	async getAppliedPaymentsForInvoice(invoiceId: number): Promise<IAppliedPaymentSummaryModel[]> {
		return this.httpService.get("/payments/getAppliedPaymentsForInvoice", { invoiceId: invoiceId });
	}

	async getAppliedPaymentsForPayment(paymentId: number): Promise<IAppliedPaymentSummaryModel[]> {
		return this.httpService.get("/payments/getAppliedPaymentsForPayment", { paymentId: paymentId });
	}

	async getUnappliedPayments(searchString: string): Promise<IPaymentModel[]> {
		return this.httpService.get("/payments/getUnappliedPayments", { searchString: searchString });
	}

	async getAppliedPaymentJobInvoiceSummariesByBillingId(customerId: number): Promise<IAppliedPaymentJobInvoiceSummaryModel[]> {
		return this.httpService.get(`/payments/getAppliedPaymentJobInvoiceSummariesByBillingId?customerId=${customerId}`);
	}

	async getAppliedPaymentJobInvoiceSummariesBySearchString(searchString: string): Promise<IAppliedPaymentJobInvoiceSummaryModel[]> {
		return this.httpService.get(`/payments/getAppliedPaymentJobInvoiceSummariesBySearchString?searchString=${searchString}`);
	}

	async getPaymentsByBillingId(customerId: number): Promise<IPaymentModel[]> {
		return this.httpService.get(`/payments/getPaymentsByBillingId?customerId=${customerId}`);
	}

	async getPaymentsBySearchString(searchString: string): Promise<IPaymentModel[]> {
		return this.httpService.get(`/payments/getPaymentsBySearchString?searchString=${searchString}`);
	}

	async getUnappliedPaymentsForCustomer(customerId: number): Promise<IPaymentModel[]> {
		return this.httpService.get("/payments/getUnappliedPaymentsForCustomer", { customerId: customerId });
	}

	async updatePayment(paymentModel: IPaymentModel): Promise<IPaymentModel> {
			if (paymentModel.paymentId === 0) {
				return this.httpService.post("/payments/addPayment", paymentModel);
			}
			else {
				return this.httpService.post("/payments/updatePayment", paymentModel);
			}
	}

	async deletePayment(paymentId: number, uuid: string): Promise<boolean> {
		return this.httpService.delete("/payments/deletePayment", { paymentId: paymentId, uuid: uuid });
	}

	async generatePaymentPDF(paymentModel: IPaymentModel): Promise<string> {
		const response = await this.httpService.get(`/payments/generatePaymentPDF?paymentId=${paymentModel.paymentId}`);
		return response.url;
	}

	async getPaymentEmail(paymentModel: IPaymentModel): Promise<IEmailerModel> {
		return this.httpService.get(`/payments/getPaymentEmail?paymentId=${paymentModel.paymentId}`);
	}

	async generatePaymentReceiptPDF(paymentId: number): Promise<string> {
		const response = await this.httpService.get(`/payments/generatePaymentReceiptPDF`, { paymentId: paymentId });
		return response.url;
	}

	async sendPaymentReceiptText(paymentId: number, toPhoneNumber: string, textBody: string) {
		const params = {
			paymentId,
			toPhoneNumber,
			textBody
		}

		return this.httpService.post(`/payments/sendPaymentReceiptText`, params);
	}

	async generatePaymentReceiptEmail(paymentId: number): Promise<IEmailerModel> {
		return this.httpService.get(`/payments/generatePaymentReceiptEmail`, { paymentId: paymentId });
	}

	async sendPaymentReceiptEmail(paymentId: number, emailerModel: IEmailerModel): Promise<IEmailerModel> {

		const formData: FormData = new FormData();
		emailerModel.attachments.forEach(file => {
			if (file.base64Image && file.base64Image.length > 0) {
				let fileCopy: ISlickFileModel = UtilsService.clone(file);
				let fileName = file.name;
				delete fileCopy.file;
				delete fileCopy.thumbnailBase64Image;
				let fileJSON = JSON.stringify(fileCopy);
				formData.append('image_' + file.uuid, fileJSON);
			}
			else if (file.file) {
				formData.append('file', file.file, file.file.name);
			}
		})

		let emailerModelTemp: IEmailerModel = UtilsService.clone(emailerModel);
		emailerModelTemp.attachments = emailerModelTemp.attachments.map(att => {
			delete att.file;
			delete att.base64Image;
			delete att.thumbnailBase64Image;
			return att;
		});
		formData.append("emailerModel", JSON.stringify(emailerModelTemp));

		return this.httpService.postMultipart(`/payments/sendPaymentReceiptEmail?paymentId=${paymentId}`, formData);
	}

	async sendPaymentRequestText(paymentModel: IPaymentModel, toPhoneNumber: string, textBody: string) {
		const params = {
			paymentModel,
			toPhoneNumber,
			textBody
		}

		return this.httpService.post(`/payments/sendPaymentRequestText`, params);
	}

	async generatePaymentRequestEmail(paymentModel: IPaymentModel, customerId: number, jobId: number): Promise<IEmailerModel> {
		const params = {
			paymentModel,
			customerId,
			jobId
		}

		return this.httpService.post(`/payments/generatePaymentRequestEmail`, params);
	}


	calcGrandTotal(paymentModels: IPaymentModel[]): number {
		let grandTotal = 0;
		if (paymentModels && paymentModels.length > 0) {
			paymentModels.forEach(e => {
				grandTotal += e.paymentAmount;
			})
		}

		grandTotal = UtilsService.round(grandTotal);

		return grandTotal;
	}
}

