import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SlickAutoCompleteModule, SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickDropDownModule, SlickDialogModule, SlickGoogleAddressSearchModule, SlickTabsModule, SlickValidationIndicatorModule } from "slick-components";
import { ContactsService } from "@services";
import { ContactEditComponent } from "./contact-edit/contact-edit.component";
import { ContactEditDialogComponent } from "./contact-edit/contact-edit-dialog.component";
import { ContactDisplayComponent } from "./contact-display/contact-display.component";
import { AddressComponent } from "./addresses/address.component";
import { AddressesEditComponent } from "./addresses/addresses-edit.component";
import { PhoneNumbersEditComponent } from "./phone-numbers/phone-numbers-edit.component";
import { EmailAddressesEditComponent } from "./email-addresses/email-addresses-edit.component";
import { NoDefaultPipe } from "./no-default.pipe";
import { SharedModule } from "@components";

@NgModule({
	imports: [CommonModule, FormsModule, SharedModule, SlickAutoCompleteModule, SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickDropDownModule, SlickDialogModule, SlickGoogleAddressSearchModule, SlickTabsModule, SlickValidationIndicatorModule],
	declarations: [ContactEditComponent, ContactEditDialogComponent, ContactDisplayComponent, AddressComponent, AddressesEditComponent, PhoneNumbersEditComponent, EmailAddressesEditComponent, NoDefaultPipe],
	exports: [ContactEditComponent, ContactEditDialogComponent, ContactDisplayComponent, AddressComponent, AddressesEditComponent, PhoneNumbersEditComponent, EmailAddressesEditComponent, NoDefaultPipe],
	providers: [ContactsService]
})
export class ContactsModule {
}
export { ContactsService }
export { ContactEditComponent }
export { ContactEditDialogComponent }
export { ContactDisplayComponent }

