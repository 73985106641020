import { Injectable } from "@angular/core";
import { GlobalsService } from "@services/utils/globals.service";
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import { TextChatStore } from "@stores";
import { LoggerStore } from "@stores";
import { ITextChatConversationSummaryModel, ITextChatMessageModel } from "@models";

@Injectable()
export class TextChatHubService {
    private static isFirst: boolean = true;
    private static hubConnection: HubConnection = null;

    constructor(private loggerStore: LoggerStore,
        private readonly textChatStore: TextChatStore) {
    }

    private startConnection() {
        this.loggerStore.addLog(`TextChatHub start connection (State: ${TextChatHubService.hubConnection.state})`);
        if (TextChatHubService.hubConnection.state === HubConnectionState.Connected) {
            this.loggerStore.addLog("Exiting because connected");
            return;
        }

        TextChatHubService.hubConnection
            .start()
            .then(() => {
                this.loggerStore.addLog("TextChatHub connected");
                // Only refresh the chat store on re-connect
                if (TextChatHubService.isFirst)
                    TextChatHubService.isFirst = false;
                else
                    this.textChatStore.refresh();

            })
            .catch(err => {
                //console.error(err);
                setTimeout(() => {
                    this.startConnection();
                }, 5000);
            });
    }

    init() {
        if (GlobalsService.isDebug)
            return;

        TextChatHubService.hubConnection = new HubConnectionBuilder()
            //.configureLogging(LogLevel.Trace)
            .withUrl(GlobalsService.apiUrl + "/textChatHub", { accessTokenFactory: () => GlobalsService.jwtToken })
            .build();

        TextChatHubService.hubConnection.on("AddConversation", (conversation: ITextChatConversationSummaryModel) => this.textChatStore.onAddConversation(conversation));
        TextChatHubService.hubConnection.on("UpdateConversation", (conversation: ITextChatConversationSummaryModel) => this.textChatStore.onUpdateConversation(conversation));
        TextChatHubService.hubConnection.on("CloseConversation", (conversation: ITextChatConversationSummaryModel) => this.textChatStore.onCloseConversation(conversation));
        TextChatHubService.hubConnection.on("ReopenConversation", (conversation: ITextChatConversationSummaryModel) => this.textChatStore.onReopenConversation(conversation));
        TextChatHubService.hubConnection.on("NewMessage", (message: ITextChatMessageModel) => this.textChatStore.onNewMessage(message));

        TextChatHubService.hubConnection.onclose(() => {
            this.loggerStore.addLog("TextChatHub onclose");
            setTimeout(() => {
                this.startConnection();
            }, 10000);
        });

        this.startConnection();
    }
}
