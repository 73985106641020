import { Injectable } from "@angular/core";
import { ITimeclockQuickbooksEmployeeModel, IPayPeriodQuickbooksDetailModel } from "@models";
import { HttpService } from "./utils/http.service";
@Injectable()
export class TimeclockQuickbooksService {
	constructor(private httpService: HttpService) { }

	async getPayPeriodQuickbooksSummary(employeeId: number, payPeriodId: number): Promise<ITimeclockQuickbooksEmployeeModel> {
		const params = {
			employeeId: employeeId,
			payPeriodId: payPeriodId
		}

		return await this.httpService.get("/timeclockQuickbooks/getPayPeriodQuickbooksSummary", params);
	}

	async getPayPeriodQuickbooksDetails(employeeId: number, payPeriodId: number): Promise<IPayPeriodQuickbooksDetailModel[]> {
		const params = {
			employeeId: employeeId,
			payPeriodId: payPeriodId
		}

		return await this.httpService.get("/timeclockQuickbooks/getPayPeriodQuickbooksDetails", params);
	}

	async approvePayPeriodForUser(employeeId: number, payPeriodId: number): Promise<ITimeclockQuickbooksEmployeeModel> {
		return await this.httpService.patch(`/timeclockQuickbooks/approvePayPeriodForUser?employeeId=${employeeId}&payPeriodId=${payPeriodId}`);
	}

	async sendToQuickbooks(employeeId: number, payPeriodId: number): Promise<IPayPeriodQuickbooksDetailModel[]>{
		return await this.httpService.patch(`/timeclockQuickbooks/sendToQuickbooks?employeeId=${employeeId}&payPeriodId=${payPeriodId}`);
	}

}
