export interface INotAcceptedReasonModel {
	notAcceptedReasonId: number;
	reason: string;
	active: boolean;
}

export class NotAcceptedReasonModel implements INotAcceptedReasonModel {
	notAcceptedReasonId: number;
	reason: string;
	active: boolean;
}