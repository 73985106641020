<div *ngIf="qbwcTaskModel">
	<div class="qbwcTaskDetailLabel">Request Id:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.requestId}}</div>
	<div class="qbwcTaskDetailLabel">Requested By:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.requestedBy}}</div>
	<div class="qbwcTaskDetailLabel">Ticket Id:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.ticketId}}</div>
	<div class="qbwcTaskDetailLabel">XML Debug:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.xmlFileName}}</div>
	<div class="qbwcTaskDetailLabel">Request:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.request}}</div>
	<div class="qbwcTaskDetailLabel">Request Date:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.requestDate | date:'M/d/yy h:mm:ss a'}}</div>
	<div class="qbwcTaskDetailLabel">Request Sent:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.requestSentDate | date:'M/d/yy h:mm:ss a'}}</div>
	<div class="qbwcTaskDetailLabel">Response Received:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.responseReceivedDate | date:'M/d/yy h:mm:ss a'}}</div>
	<div class="qbwcTaskDetailLabel">Status:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.status}}</div>
	<div class="qbwcTaskDetailLabel" *ngIf="qbwcTaskModel.errorMessage">Error Message:</div><div class="qbwcTaskDetailText" *ngIf="qbwcTaskModel.errorMessage">{{qbwcTaskModel.errorMessage}}</div>
	<div class="qbwcTaskDetailLabel" *ngIf="qbwcTaskModel.displayErrorClearedBy">Error cleared by:</div><div class="qbwcTaskDetailText" *ngIf="qbwcTaskModel.displayErrorClearedBy">{{qbwcTaskModel.displayErrorClearedBy}} at {{qbwcTaskModel.displayErrorClearedDate | date:'M/d/yyyy hh:mm:ss a'}}</div>
	<div class="qbwcTaskDetailLabel">Custom Field:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.customField}}</div>
	<div class="qbwcTaskDetailLabel">Pending:</div><div class="qbwcTaskDetailText">{{qbwcTaskModel.isPending}}</div>
	<div class="qbwcTaskDetailLabel">
		Request XML:
		<div *ngIf="isScott" style="font-size: 10px;"><button type="button" class="btn btn-link" (click)="editRequest()">Edit Request&nbsp;</button></div>
	</div>
	<div class="qbwcTaskDetailText">
		<pre class="card card-body" style="min-height: 200px; max-height: 200px; overflow-y: auto; font-size: .8em;">{{requestXML}}</pre>
	</div>
	<div style="height: 10px; min-height: 10px;"></div>
	<div class="qbwcTaskDetailLabel">
		<i (click)="copyResponse()" class="fad fa-clipboard" style="cursor: pointer"></i> Response XML:<br />
		<div *ngIf="isScott" style="font-size: 10px;"><button type="button" class="btn btn-link" (click)="processResponseXML()">Re-Process&nbsp;</button></div>
	</div>
	<div class="qbwcTaskDetailText">
		<pre class="card card-body" style="min-height: 200px; max-height: 200px; overflow-y: auto; font-size: .8em;">{{responseXML}}</pre>
		<button type="button" class="btn btn-link" *ngIf="qbwcTaskModel.isResponseTruncated === true" style="font-size: 12px;" (click)="getTaskResponseXML()">Show All...</button>
	</div>
</div>

<slick-dialog #editRequestDialogRef width="1000">
	<textarea class="form-control mb-1" style="min-height: 250px" [(ngModel)]="editRequestXML"></textarea>

	<input type="checkbox" [(ngModel)]="editIsPending" />Pending

	<slick-dialog-footer>
		<button class="btn btn-primary me-3" (click)="saveEdit()">Save</button>
		<button class="btn btn-danger" (click)="cancelEdit()">Cancel</button>
	</slick-dialog-footer>
</slick-dialog>