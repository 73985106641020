import { IVendorModel } from "@models/vendors/vendor.model";
import { IEstimatorModel } from "@models/lookups/estimator.model";
import { IDocumentModel } from "@models/documents/document.model";
import { IPayableLineItemModel } from "./payable-line-item.model";
import { ISubcontractorModel } from "../subcontractors/subcontractor.model";
import { IROIExpenseModel } from "../roi/roi-expense.model";

export interface IPayableModel {
	payableId: number;
	uuid: string;
	version: number;
	vendorId?: number
	vendor: IVendorModel;
	subcontractorId?: number;
	subcontractor: ISubcontractorModel;
	estimatorId: number;
	estimator: IEstimatorModel;
	accountingUserId?: number;
	accountingUserFullName: string;
	mobileUploadUserId?: number;
	mobileUploadUserFullName: string;
	invoiceDocumentId?: number;
	invoiceDocument: IDocumentModel;
	jobId?: number;
	jobName?: string;
	jobEstimatorId?: number;
	jobStatusDescription: string;
	payableStatus: string;
	paymentType: string;
	creditCard: string;
	invoiceNumber?: string;
	invoiceDate?: Date;
    invoiceAmount?: number;
    memo: string;
	inventoryMemo: string;
	approvedBy?: string; 
	approvedDate?: Date;
	manualQuickbooksSync: boolean;
	syncToQuickbooks: boolean;
	quickbooksId?: string;
	quickbooksBatchNumber?: number;
	isDuplicate: boolean;
	hasNotes: boolean;
	payableLineItems: IPayableLineItemModel[];
	roiExpenses: IROIExpenseModel[]
}

export class PayableModel implements IPayableModel {
	payableId: number;
	uuid: string;
	version: number;
	vendorId?: number
	vendor: IVendorModel;
	subcontractorId?: number;
	subcontractor: ISubcontractorModel;
	estimatorId: number;
	estimator: IEstimatorModel;
	accountingUserId?: number;
	accountingUserFullName: string;
	mobileUploadUserId?: number
	mobileUploadUserFullName: string;
	invoiceDocumentId?: number;
	invoiceDocument: IDocumentModel;
	jobId?: number;
	jobName?: string;
	jobEstimatorId?: number;
	jobStatusDescription: string;
	payableStatus: string;
	paymentType: string;
	creditCard: string;
	invoiceNumber?: string;
	invoiceDate?: Date;
    invoiceAmount?: number;
	memo: string;
	inventoryMemo: string;
	approvedBy?: string;
	approvedDate?: Date;
	manualQuickbooksSync: boolean;
	syncToQuickbooks: boolean;
	quickbooksId?: string;
	quickbooksBatchNumber?: number;
	isDuplicate: boolean;
	hasNotes: boolean;
	payableLineItems: IPayableLineItemModel[];
	roiExpenses: IROIExpenseModel[]
}