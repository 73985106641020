import { GlobalsService, UtilsService } from "@services";
import * as moment from 'moment';

export interface IROIExpenseModel {
	roiExpenseId: number;
	uuid: string;
	version: number;
	employeeId: number;
	employeeFullName: string;
	businessDevelopmentAccountId: number;
	businessDevelopmentAccountName: string;
	payableId: number;
	payableVendorDisplayName: string;
	expenseType: string;
	expenseDate: Date;
	expenseAmount: number;
	note: string;
}

export class ROIExpenseModel implements IROIExpenseModel {
	constructor() {
		this.roiExpenseId = 0;
		this.uuid = UtilsService.newGuid();
		this.expenseAmount = 0;
		this.employeeId = GlobalsService.userInfo.userId;
		this.employeeFullName = GlobalsService.userInfo.fullName;
		this.expenseDate = moment().startOf("day").toDate();
	}

	roiExpenseId: number;
	uuid: string;
	version: number;
	employeeId: number;
	employeeFullName: string;
	businessDevelopmentAccountId: number;
	businessDevelopmentAccountName: string;
	payableId: number;
	payableVendorDisplayName: string;
	expenseType: string;
	expenseDate: Date;
	expenseAmount: number;
	note: string;
}