import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { UtilsService, GlobalsService, HttpService, SleepService, LookupService } from "@services";
import { EmailerDialogComponent } from "@components";
import { SlickDialogComponent } from "slick-components";
import { JobEditComponent } from "./job-edit.component";
import { IJobModel, JobModel, IEmailerModel, EmailerModel, IEmailAddressModel, EmailAddressModel, IEstimatorModel, ICustomerModel } from "@models";
import * as momentImported from "moment"; const moment = momentImported;

@Component({
	selector: "duplicate-customer-dialog",
	template: `

<slick-dialog #dialogRef header="Duplicate Customer" [width]='500' top='250' (onClose)='backToJob()'>
	<div style='text-align: center'>
		It appears that this customer is already in the system.<br />
		Do you want to use the existing customer record?
		<div *ngIf='originalCustomerModel && duplicateCustomerModel' class='d-flex mt-3' style='line-height: 1.2em;'>
			<div style='width: 45%; margin-right: 10px; text-align: center'>
				Existing Customer:<br />
				{{duplicateCustomerModel.contact.fullName}}<br />
				{{duplicateCustomerModel.contact.defaultAddress.address1}}<br />
				<span *ngIf="duplicateCustomerModel.contact.defaultAddress.address2">{{duplicateCustomerModel.contact.defaultAddress.address2}}<br /></span>
				{{duplicateCustomerModel.contact.defaultAddress.cityStatePostal}}
				<div class="mt-3 mb-3">
					<button type="button" style='width: 160px' class="btn btn-outline-success" (click)="useExistingCustomer()">Use existing customer</button>
				</div>
			</div>

			<div style='width: 45%; margin-right: 10px;text-align: center'>
				Your Customer:<br />
				{{originalCustomerModel.contact.firstName}} {{originalCustomerModel.contact.lastName}}<br />
				{{originalCustomerModel.contact.defaultAddress.address1}}<br />
				<span *ngIf="originalCustomerModel.contact.defaultAddress.address2">{{originalCustomerModel.contact.defaultAddress.address2}}<br /></span>
				{{originalCustomerModel.contact.defaultAddress.city}}, {{originalCustomerModel.contact.defaultAddress.state}}, {{originalCustomerModel.contact.defaultAddress.postalCode}}, {{originalCustomerModel.contact.defaultAddress.country}}
				<div class="mt-3 mb-3">
					<button type="button" style='width: 160px' class="btn btn-outline-primary" (click)="useMyCustomer()">Keep new customer</button>
				</div>
			</div>
		</div>
	</div>
</slick-dialog>
`
})
export class DuplicateCustomerDialogComponent {
	@Output() onSave: EventEmitter<IJobModel> = new EventEmitter<IJobModel>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;


	originalCustomerModel: ICustomerModel;
	duplicateCustomerModel: ICustomerModel;
	private resolve: any;

	constructor() {	}

	showDialog(originalCustomerModel: ICustomerModel, duplicateCustomerModel: ICustomerModel): Promise<ICustomerModel> {
		return new Promise<ICustomerModel>((resolve) => {
			this.resolve = resolve;

			this.originalCustomerModel = originalCustomerModel;
			this.duplicateCustomerModel = duplicateCustomerModel;
			this.dialogRef.showDialog();
		});
	}

	useMyCustomer() {
		this.dialogRef.hideDialog();
		this.resolve(this.originalCustomerModel);
	}

	useExistingCustomer() {
		this.dialogRef.hideDialog();
		this.resolve(this.duplicateCustomerModel);
	}

	backToJob() {
		this.dialogRef.hideDialog();
		this.resolve(null);
	}
}


