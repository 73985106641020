import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, Pipe, PipeTransform } from "@angular/core";
import { SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickDialogComponent } from "slick-components";
import { NoDefaultPipe } from "../no-default.pipe";
import { IEmailAddressModel, EmailAddressModel } from "@models";
import { UtilsService, SleepService, ContactsService } from "@services";

@Component({
	selector: "emailAddressesEdit",
	templateUrl: "email-addresses-edit.component.html",
	styles: [`
		.emailAddressBlock { padding: 5px; padding-left: 10px; line-height: 1.1em; }
		.emailAddressBlock:nth-child(even) { background: #f8f8f8; }
		.emailAddressBlock .far { margin-right: 3px; }`]
})
export class EmailAddressesEditComponent implements OnChanges {
	@Input() emailAddressModels: IEmailAddressModel[];
	@Output() emailAddressModelsChange: EventEmitter<IEmailAddressModel[]> = new EventEmitter<IEmailAddressModel[]>();

	@ViewChild("emailAddressDialogRef", { static: true }) emailAddressDialogRef: SlickDialogComponent;
	@ViewChild("deleteEmailAddressRef", { static: true }) deleteEmailAddressRef: SlickConfirmDialogComponent;

	defaultEmailAddress: IEmailAddressModel = new EmailAddressModel();
	dialogModel: IEmailAddressModel = this.contactsService.getNewEmailAddressModel();
	emailAddressTypes: any[];
	showDialog: boolean = false;

	constructor(private contactsService: ContactsService) {


		this.emailAddressTypes = [
			{ id: 2, text: 'Secondary' },
			{ id: 3, text: 'Home' },
			{ id: 4, text: 'Work' },
			{ id: 5, text: 'Other' }
		]
	}

	async ngOnChanges() {
		await SleepService.sleep();
		if (!this.emailAddressModels || this.emailAddressModels.length === 0) 
			this.emailAddressModels = new Array<EmailAddressModel>();			

		this.defaultEmailAddress = this.emailAddressModels.find((p) => p.isDefault === true);
		if (!this.defaultEmailAddress) {
			this.defaultEmailAddress = this.contactsService.getNewEmailAddressModel(true);
			this.defaultEmailAddress.emailAddressTypeId = 1;
			this.defaultEmailAddress.emailAddressTypeName = "Default";
			this.emailAddressModels.push(this.defaultEmailAddress);
		}

		this.emailAddressModelsChange.emit(this.emailAddressModels);
	}

	addNewEmailAddress() {
		this.dialogModel = this.contactsService.getNewEmailAddressModel();
		this.dialogModel.emailAddressTypeId = 2;
		this.dialogModel.emailAddressTypeName = "Home";
		this.emailAddressDialogRef.showDialog();
	}

	editEmailAddress(emailAddressModel: IEmailAddressModel) {
		this.dialogModel = UtilsService.clone(emailAddressModel);
		this.emailAddressDialogRef.showDialog();
		this.emailAddressModelsChange.emit(this.emailAddressModels);
	}

	async deleteEmailAddress(emailAddressModel: IEmailAddressModel) {
		let confirmResults: SlickConfirmDialogResults = await this.deleteEmailAddressRef.confirm();

		if (confirmResults === SlickConfirmDialogResults.Ok) {
			this.emailAddressModels = this.emailAddressModels.filter((a: IEmailAddressModel) => a.uuid !== emailAddressModel.uuid);
			this.emailAddressModelsChange.emit(this.emailAddressModels);
		}
	}

	onEmailAddressTypeSelect(emailAddressType) {
		if (emailAddressType)
			this.dialogModel.emailAddressTypeName = emailAddressType.text;
		else
			this.dialogModel.emailAddressTypeName = null;
	}

	onUpdate() {
		let emailAddressModel = this.emailAddressModels.find((a: IEmailAddressModel) => a.uuid === this.dialogModel.uuid);
		if (!emailAddressModel)
			this.emailAddressModels.push(this.dialogModel);
		else
			Object.assign(emailAddressModel, this.dialogModel);		

		// If true, we're changing the default emailAddress
		if (this.dialogModel.isDefault === true) {
			this.emailAddressModels.forEach((a: IEmailAddressModel) => a.isDefault = false);
			let newDefaultEmailAddressModel = this.emailAddressModels.find((a: IEmailAddressModel) => a.uuid === this.dialogModel.uuid);
			newDefaultEmailAddressModel.isDefault = true;
			this.defaultEmailAddress = newDefaultEmailAddressModel;
		}

		this.emailAddressDialogRef.hideDialog();

		this.emailAddressModelsChange.emit(this.emailAddressModels);
	}

	onCancel() {
		this.emailAddressDialogRef.hideDialog();
	}
}
