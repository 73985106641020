export interface IPaymentTermModel {
	paymentTermId: number;
	description: string;
	daysUntilOverdue: number;
	isDefaultPaymentTerm: boolean;
	active: boolean;
}

export class PaymentTermModel implements IPaymentTermModel {
	paymentTermId: number;
	description: string;
	daysUntilOverdue: number;
	isDefaultPaymentTerm: boolean;
	active: boolean;
}