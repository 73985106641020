import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { SleepService } from '@services';
import * as moment from "moment";

@Component({
	selector: "date-time-entry",
	templateUrl: "date-time-entry.component.html",
	providers: [DateTimeEntryComponent]

})
export class DateTimeEntryComponent implements OnChanges {
	@ViewChild("timeInputRef", { static: true }) timeInputRef: ElementRef;

	@Input() date: Date;
    @Output() onDateChanged = new EventEmitter<Date>();
    @Input() hideDate: boolean = false;
	time: string;
	isValid: boolean;

	constructor() {

	}

	ngOnChanges(changes: SimpleChanges) {
		this.isValid = true;
		this.time = null;

		if (!this.date) {
			return;
		}
		else { 
			if (moment(this.date).isValid() === false) {
				this.isValid = false;
				return;
			}

			this.time = moment(this.date).format("h:mm a");
			this.date = moment(this.date).startOf("date").toDate();

		}
	}

	validate() {
		this.isValid = true;
		if (!this.date) {
			this.time = null;
			return;
		}

		const dateTimeString = moment(this.date).format("MM/DD/YYYY") + ' ' + this.time;
		const dateTime = moment(dateTimeString, 'MM/DD/YYYY HH:mm a').startOf("minute");

		this.isValid = dateTime.isValid();

		if (this.isValid) {
			this.date = moment(dateTime).startOf("date").toDate();
			this.time = moment(dateTime).format("h:mm a");
			this.onDateChanged.emit(dateTime.toDate());
		}
	}

	focusTime() {
		(<HTMLInputElement>this.timeInputRef.nativeElement).focus();
		(<HTMLInputElement>this.timeInputRef.nativeElement).select();
	}

	async onDateChange(newDate) {
		this.date = newDate;
		this.validate();
	}

	async onTimeChange() {
		await SleepService.sleep();
		this.validate();		
	}
}
