import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { CustomerSearchComponent } from "./customer-search.component";
import { SlickAutoCompleteModule } from "slick-components";
import { SharedModule } from "@components";
import { CustomersService } from "@services";
import { CustomerEditModule } from "@app/customers/customer-edit/customer-edit.module";

@NgModule({
	imports: [CommonModule, FormsModule, SharedModule, CustomerEditModule, SlickAutoCompleteModule],
	declarations: [CustomerSearchComponent],
	exports: [CustomerSearchComponent],
	providers: [CustomersService]
})
export class CustomerSearchModule {
}
export { CustomerSearchComponent }




