import { Injectable } from "@angular/core";
import { GlobalsService } from "@services";
import { HttpClient } from "@angular/common/http";
import * as moment from 'moment';

@Injectable()
export class InactivityTimeoutService {
	private static inactivityTimeout: NodeJS.Timeout;

	constructor(private httpClient: HttpClient) { }

	async resetInactivityTimeout() {
		// Check the inactivity timer every hour
		const inactivityTimeoutLength = 360000;
		clearTimeout(InactivityTimeoutService.inactivityTimeout);

		if (GlobalsService.isLoggedIn === true) {
			if (!localStorage.getItem("last_action"))
				localStorage.setItem("last_action", new Date().getTime().toString());

			InactivityTimeoutService.inactivityTimeout = setTimeout(async () => {
				const lastAction = localStorage.getItem("last_action");
				if (lastAction) {
					const lastActionDate = moment(new Date(parseInt(lastAction)));
					if (moment().diff(lastActionDate, "hour") > 12) {
						await this.httpClient.get("/login/autoLogout");
						console.info("Clearing Token");
						localStorage.removeItem("jwt_token");
						window.location.href = "/";
					}
					else {
						this.resetInactivityTimeout();
					}
				}
			}, inactivityTimeoutLength);
		}
	}
}