import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { GlobalsService } from "@services";
import { MessageQueueDispatcher } from "@message-queue";

@Injectable()
export class XactwareStore {
	private _xactAssignmentCount: number = 0;

	xactAssignmentCountCount$: Subscription;

	constructor(private messageQueueDispatcher: MessageQueueDispatcher) {
		this._xactAssignmentCount = GlobalsService.userBadges.xactAssignmentCount;
		this.xactAssignmentCountCount$ = this.messageQueueDispatcher.xactAssignmentsCount.subscribe(x => this.setXactAssignmentCount(x));

	}

	xactAssignmentCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(GlobalsService.userBadges.xactAssignmentCount);
	setXactAssignmentCount(count: number) {
		GlobalsService.userBadges.xactAssignmentCount = count;
		this._xactAssignmentCount = count;
		this.xactAssignmentCountSubject.next(this._xactAssignmentCount);
	}

	get xactAssignmentCount(): number {
		return this._xactAssignmentCount;
	}

}
