import { IAppointmentModel } from "./appointment.model";
import * as moment from "moment";

export interface IAppointmentDisplayModel {
	appointmentId: number;
	version: number;
	jobId: number;
	jobName: string;
	jobCustomerDisplayName: string;
	jobAddress1: string;
	jobCity: string;
	jobCellPhone: string;
	subcontractorDisplayName: string;
	resourceNames: string;
	laborCodeNames: string;
	resourceColors: string[];
	color: string;
	isOnClipboard: boolean;
	clipboardUserFullName: string;
	scratchPad: string;
	isPending: boolean;
	isTimeBlock: boolean;
	isComplate: boolean;
	startDate: Date;
	endDate: Date;
	duration: number;
	column: number;	
	maxSiblings: number;
	startTimeSlot: number;
	endTimeSlot: number;
	dailyStartTimeSlot: number;
	dailyEndTimeSlot: number;
	leftSiblings: number[];
	rightSiblings: number[];
	top: string;
	left: string;
	width: string;
	height: string;
	trackByKey: number;
}

export class AppointmentDisplayModel implements IAppointmentDisplayModel {
	constructor(appointmentModel: IAppointmentModel, startDate: Date) {
		this.appointmentId = appointmentModel.appointmentId;
		this.version = appointmentModel.version;
		this.startDate = appointmentModel.startDate;
		this.endDate = appointmentModel.endDate;
		this.duration = moment(this.endDate).diff(this.startDate, "minutes");
		this.isOnClipboard = appointmentModel.isOnClipboard;
		this.clipboardUserFullName = appointmentModel.clipboardUserFullName;
		this.scratchPad = appointmentModel.scratchPad;
		this.isPending = appointmentModel.isPending;
		this.isTimeBlock = appointmentModel.isTimeBlock;
		this.isComplate = appointmentModel.isComplate;
		this.jobId = appointmentModel.jobId; 
		this.jobName = appointmentModel.jobName;
		this.jobCustomerDisplayName = appointmentModel.jobCustomerDisplayName;
		this.jobAddress1 = appointmentModel.jobAddress1;
		this.jobCity = appointmentModel.jobCity;
		this.jobCellPhone = appointmentModel.jobCellPhone;
		this.subcontractorDisplayName = appointmentModel.subcontractorDisplayName;
		this.resourceNames = appointmentModel.resources.map(r => r.resourceName).join(", ");
		this.laborCodeNames = appointmentModel.laborCodes.map(lb => lb.laborCodeName).join(", ");
		this.color = (appointmentModel.resources && appointmentModel.resources.length > 0) ? appointmentModel.resources[0].resourceColor || "#6ba5c2" : "#6ba5c2";
		if (appointmentModel.subcontractorScheduleColor)
			this.color = appointmentModel.subcontractorScheduleColor;
		this.resourceColors = [];
		if (appointmentModel.resources && appointmentModel.resources.length > 1) 
			this.resourceColors = appointmentModel.resources.map(x => x.resourceColor);		

		this.startTimeSlot = moment(this.startDate).diff(moment(startDate), "minutes") / 15;
		this.endTimeSlot = (moment(this.startDate).diff(moment(startDate), "minutes") + this.duration) / 15;

		let timeSlotAdj = moment(this.startDate).startOf("day").diff(moment(startDate), "minutes") / 15;
		this.dailyStartTimeSlot = this.startTimeSlot - timeSlotAdj;
		this.dailyEndTimeSlot = this.endTimeSlot - timeSlotAdj;
		// Make sure it ends at midnight
		if (this.dailyEndTimeSlot > 95)
			this.dailyEndTimeSlot = 95;
	}

	appointmentId: number;
	version: number;
	jobId: number;
	jobName: string;
	jobCustomerDisplayName: string;
	jobAddress1: string;
	jobCity: string;
	jobCellPhone: string;
	subcontractorDisplayName: string;
	resourceNames: string;
	laborCodeNames: string;
	resourceColors: string[];
	color: string;
	isOnClipboard: boolean;
	clipboardUserFullName: string;
	scratchPad: string;
	isPending: boolean;
	isTimeBlock: boolean;
	isComplate: boolean;
	startDate: Date;
	endDate: Date;
	duration: number;
	column: number;
	maxSiblings: number;
	startTimeSlot: number;
	endTimeSlot: number;
	dailyStartTimeSlot: number;
	dailyEndTimeSlot: number;
	leftSiblings: number[];
	rightSiblings: number[];
	top: string;
	left: string;
	width: string;
	height: string;
	trackByKey: number;
}

