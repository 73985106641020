import { IAppointmentDisplayModel } from "./appointment-display.model";

export interface IAppointmentRowModel {
	appointmentDisplayModels: IAppointmentDisplayModel[];
}

export class AppointmentRowModel implements IAppointmentRowModel {
	appointmentDisplayModels: IAppointmentDisplayModel[];
}

