import { Injectable } from "@angular/core";
import { AppointmentTypes, IAppointmentModel, ITimeSlotModel } from "@models";
import { Observable, Subject } from "rxjs";
import { UtilsService } from "../services";

export enum ScheduleDisplayTypes { Daily, Weekly, Monthly };
export enum ScheduleOrientations { Vertical, Horizontal };

@Injectable()
export class ScheduleAppointmentStore {
	private _appointmentType: AppointmentTypes;
	private _appointments: IAppointmentModel[];
	private _clipboardAppointments: IAppointmentModel[];
	private _timeSlots: ITimeSlotModel[];

	private appointmentsSubject: Subject<IAppointmentModel[]> = new Subject<IAppointmentModel[]>();
	private clipboardSubject: Subject<IAppointmentModel[]> = new Subject<IAppointmentModel[]>();
	private clipboardVisibleSubject: Subject<boolean> = new Subject<boolean>();

	setAppointmentType(appointmentType: AppointmentTypes) {
		this._appointmentType = appointmentType;
	}

	getAppointmentType(): AppointmentTypes {
		return this._appointmentType;
	}

	clearAppointments() {
		this._appointments = [];
		this.appointmentsSubject.next(this._appointments);
	}

	setAppointments(appointments: IAppointmentModel[]) {
        this._appointments = appointments;
        this.appointmentsSubject.next(this._appointments);
	}

	onAppointmentsChanged(): Observable<IAppointmentModel[]> {
		return this.appointmentsSubject.asObservable();
	}

	get appointments(): IAppointmentModel[] {
		return this._appointments;
	}

	addAppointment(appointment: IAppointmentModel) {
		this._appointments.push(appointment);
		this.appointmentsSubject.next(this._appointments);
	}

	updateAppointment(appointment: IAppointmentModel) {
        let appointmentIdx = this._appointments.findIndex(a => a.appointmentId === appointment.appointmentId);
        if (appointmentIdx >= 0)
			this._appointments.splice(appointmentIdx, 1);
		this._appointments.push(appointment);

		this.appointmentsSubject.next(this._appointments);
    }

    removeAppointment(appointmentId: number) {
        this._appointments = this._appointments.filter(appt => appt.appointmentId !== appointmentId);
        this._clipboardAppointments = this._clipboardAppointments.filter(appt => appt.appointmentId !== appointmentId);

        this.appointmentsSubject.next(this._appointments);
        this.clipboardSubject.next(this._clipboardAppointments);
    }

	getAppointment(appointmentId: number): IAppointmentModel {
		let apptIdx = this._appointments.findIndex(appt => appt.appointmentId === appointmentId);
		if (apptIdx >= 0)
			return this._appointments[apptIdx];
		else
			return null;
	}

	get clipboardAppointments(): IAppointmentModel[] {
		return this._clipboardAppointments;
	}

    setClipboardAppointments(clipboardAppointments: IAppointmentModel[]) {
        this._clipboardAppointments = clipboardAppointments;

        this.clipboardSubject.next(this._clipboardAppointments);
    }

	async moveAppointmentToClipboard(appointmentId: number) {
		let apptIdx = this._appointments.findIndex(appt => appt.appointmentId === appointmentId);
		if (apptIdx >= 0) {
			let clipboardAppt = this._appointments.find(appt => appt.appointmentId === appointmentId);
			if (!this._clipboardAppointments)
				this._clipboardAppointments = [];
            this._clipboardAppointments.push(clipboardAppt);

			this.clipboardSubject.next(this._clipboardAppointments);
			this.clipboardVisibleSubject.next(true);			
		}
	}

	removeAppointmentFromClipboard(appointmentId: number): IAppointmentModel {
		let clipboardApptIdx = this._clipboardAppointments.findIndex(appt => appt.appointmentId === appointmentId);
		let clipboardAppt: IAppointmentModel = null;

		if (clipboardApptIdx >= 0) {
			clipboardAppt = this._clipboardAppointments.splice(clipboardApptIdx, 1)[0];
			this.clipboardSubject.next(this._clipboardAppointments);
			if (this._clipboardAppointments.length === 0)
				this.clipboardVisibleSubject.next(false);
		}

		return clipboardAppt;
	}

	onClipboardAppointmentsChanged(): Observable<IAppointmentModel[]> {
		return this.clipboardSubject.asObservable();
	}

	onClipboardVisible(): Observable<boolean> {
		return this.clipboardVisibleSubject.asObservable();
	}

	setTimeSlots(timeSlots: ITimeSlotModel[]) {
		this._timeSlots = timeSlots;
	}

	get timeSlots(): ITimeSlotModel[] {
		return this._timeSlots;
	}
}

