import { Component, Input, isDevMode, ViewChild} from "@angular/core";
import { SlickDialogComponent } from "slick-components";
import { IVersionReleaseModel } from "@models/version-release/version-release.model";

import { GlobalsService, HttpService } from "@services"

@Component({
	selector: "version-release-dialog",
	templateUrl: "./version-release-dialog.component.html"
})
export class VersionReleaseDialogComponent {
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@Input() isMobile: boolean = false;
	versionModels: IVersionReleaseModel[];

	constructor(private readonly httpService: HttpService) {
	}

	async showDialog() {
		if (this.isMobile) {
			this.dialogRef.width = 360;
		}
		else {
			this.dialogRef.width = 1200;
		}

		const params: any = {
			lastSeenVersion: GlobalsService.userInfo.lastSeenVersion,
			appVersion: GlobalsService.appVersion
		}

		this.versionModels = await this.httpService.get("/versionRelease/getAllVersionReleasesForDialog", params);
		if (this.versionModels.length > 0) {
			this.dialogRef.showDialog();
		}
	}

	async showClickedVersion() {
		this.versionModels = [];
        this.versionModels.push(await this.httpService.get("/versionRelease/getVersionRelease", { appVersion: GlobalsService.appVersion }))
        this.dialogRef.showDialog();
    }

	close() {
		this.dialogRef.hideDialog();
	}
}
