<div style="border-bottom: 1px solid #ccc; margin-bottom: 5px;">
	<div style="display: inline-block; width: 49%;">
		<span style="font-size: 18px;">Addresses</span>
	</div>
	<div style="display: inline-block; width: 50%; text-align: right;">
		<button type="button" class="btn btn-link" (click)="addNewAddress()"><i class="far fa-plus"></i> Add Additional Address</button>
	</div>
	
</div>

<div style="clear: both;" class="form-horizontal">
	<div class="form-group">
		<div class="form-group">
			<div class="col-12">
				<input type="radio" (click)="defaultAddress.addressTypeId = 1" [checked]="defaultAddress.addressTypeId === 1" name="addressType">&nbsp;Home&nbsp;&nbsp;
				<input type="radio" (click)="defaultAddress.addressTypeId = 2" [checked]="defaultAddress.addressTypeId === 2" name="addressType">&nbsp;Work
			</div>
		</div>

		<div class="form-group">
			<div class="col-12">
				<address name="defaultAddress" [(ngModel)]="defaultAddress"></address>
			</div>
		</div>
	</div>
</div>

<div class="form-group">
	<div class="col-12">
		<div class="addressBlock" *ngFor="let address of addressModels | noDefault">
			<div style="display: inline-block; vertical-align: top;"><i style="cursor: pointer;" class="far fa-pencil" (click)="editAddress(address)"></i><i style="cursor: pointer;" class="far fa-trash" (click)="deleteAddress(address)"></i></div>
			<div style="display: inline-block; vertical-align: top; width: calc(100% - 40px);">
				{{address.addressTypeName}}<br />
				{{address.address1}}<br />
				<span *ngIf="address.address2">{{address.address2}}<br /></span>
				{{address.city}}, {{address.state}}, {{address.postalCode}}, {{address.country}}
			</div>
		</div>
	</div>
</div>

<slick-dialog #addressDialogRef [header]="'Address Edit'" [top]="120">
	<form class="form-horizontal" #dialogAddressForm="ngForm" novalidate>
		<div class="row form-group">
			<div class="col-12">
				<input type="radio" (click)="dialogModel.addressTypeId = 1" [checked]="dialogModel.addressTypeId === 1" name="addressType">&nbsp;Home&nbsp;&nbsp;
				<input type="radio" (click)="dialogModel.addressTypeId = 2" [checked]="dialogModel.addressTypeId === 2" name="addressType">&nbsp;Work
			</div>
		</div>

		<address name="dialogAddress" [(ngModel)]="dialogModel"></address>

		<div class="row form-group">
			<div class="col-12">
				<input type="checkbox" [(ngModel)]="dialogModel.isDefault" name="isDefault" />Set as default address
			</div>
		</div>

		<slick-dialog-footer>
			<button type="button" class="btn btn-outline-primary" (click)="onUpdate()">Update</button>
			<button type="button" class="btn btn-outline-danger" (click)="onCancel()">Cancel</button>
		</slick-dialog-footer>

	</form>
</slick-dialog>

<slick-confirm-dialog #deleteAddressRef>
	Are you sure that you want to delete this address?
</slick-confirm-dialog>
