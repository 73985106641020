<div *ngIf="showPage === true && isLoggedIn === true && isCustomerForms === false && isSurvey === false && isQuickbooksOAuthResponse === false" class="main-container">
	<div class="content">
		<side-menu></side-menu>
		<div id="main-area-container" class="main-area-container">
			<header-bar></header-bar>
			<div class="main-area">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
	<div class="main-footer">
		<footer-bar></footer-bar>
	</div>
</div>

<div *ngIf="showPage === true && (isLoggedIn === false || isCustomerForms === true || isSurvey === true || isQuickbooksOAuthResponse === true)">
	<router-outlet></router-outlet>
</div>

<div *ngIf="showPage === true && isOnlinePayments">
	<router-outlet></router-outlet>
</div>

<http-error-dialog></http-error-dialog>
<version-release-dialog #dialogRef [isMobile]="false"></version-release-dialog>