import { Routes } from "@angular/router";
import { LandingComponent } from "./landing/landing.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

export const AppRoutes: Routes = [
	{ path: "", pathMatch: "full", component: LandingComponent },
	{ path: "login", loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
	{ path: "forgot-password", loadChildren: () => import('./login/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
	{ path: "reset-password", loadChildren: () => import('./login/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
	{ path: "customer-forms", loadChildren: () => import('./customer-forms/customer-forms.module').then(m => m.CustomerFormsModule) },
	{ path: "dashboard", loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
	{ path: "jobs", loadChildren: () => import('./jobs/jobs-page/jobs-page.module').then(m => m.JobsPageModule) },
	{ path: "customers", loadChildren: () => import('./customers/customers-page/customers-page.module').then(m => m.CustomersPageModule) },
	{ path: "tasks", loadChildren: () => import('./tasks/tasks-page/tasks-page.module').then(m => m.TasksPageModule) },
	{ path: "invoices", loadChildren: () => import('./invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule) },
	{ path: "xactware", loadChildren: () => import('./xactware/xactware-page/xactware-page.module').then(m => m.XactwarePageModule) },
	{ path: "subcontractors", loadChildren: () => import('./subcontractors/subcontractors-page/subcontractors-page.module').then(m => m.SubcontractorsPageModule) },
	{ path: "vendors", loadChildren: () => import('./vendors/vendors-page/vendors-page.module').then(m => m.VendorsPageModule) },
	{ path: "equipment", loadChildren: () => import('./equipment/equipment-page/equipment-page.module').then(m => m.EquipmentPageModule) },
	{ path: "inventory", loadChildren: () => import('./inventory/inventory-page/inventory-page.module').then(m => m.InventoryPageModule) },
	{ path: "schedule", loadChildren: () => import('./schedule/schedule-page/schedule-page.module').then(m => m.SchedulePageModule) },
	{ path: "quickbooks", loadChildren: () => import('./quickbooks/quickbooks-page/quickbooks-page.module').then(m => m.QuickbooksPageModule) },
	{ path: "room-designer", loadChildren: () => import('./room-designer/room-designer-page/room-designer-page.module').then(m => m.RoomDesignerPageModule) },
	{ path: "emailLogs", loadChildren: () => import('./email-logs/email-logs.module').then(m => m.EmailLogsModule) },
	{ path: "payables", loadChildren: () => import('./payables/payables-page/payables-page.module').then(m => m.PayablesPageModule) },
	{ path: "payments", loadChildren: () => import('./payments/payments-page/payments-page.module').then(m => m.PaymentsPageModule) },
	{ path: "text-chat", loadChildren: () => import('./text-chat/text-chat-page/text-chat-page.module').then(m => m.TextChatPageModule) },
	{ path: "timeclock", loadChildren: () => import('./timeclock/timeclock-page/timeclock-page.module').then(m => m.TimeclockPageModule) },
	{ path: "timeclock-export", loadChildren: () => import('./timeclock/timeclock-export-page/timeclock-export-page.module').then(m => m.TimeclockExportPageModule) },
	{ path: "workOrders", loadChildren: () => import('./work-orders/work-orders-page/work-orders-page.module').then(m => m.WorkOrdersPageModule) },
	{ path: "businessDevelopment", loadChildren: () => import('./business-development/business-development-page/business-development-page.module').then(m => m.BusinessDevelopmentPageModule) },
	{ path: "commissions", loadChildren: () => import('./commissions/commissions-page/commissions-page.module').then(m => m.CommissionsPageModule) },
	{ path: "survey/:linkCode", loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule) },
	{ path: "reporting", loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule) },
	{ path: "test", loadChildren: () => import('./test/test.module').then(m => m.TestModule) },
	{ path: "users", loadChildren: () => import('./users/users-page/users-page.module').then(m => m.UsersPageModule) },
	{ path: "userSettings", loadChildren: () => import('./user-settings/user-settings.module').then(m => m.UserSettingsModule) },
	{ path: "setup", loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule) },
	{ path: "globalAdmin", loadChildren: () => import('./global-admin/global-admin.module').then(m => m.GlobalAdminModule) },
	{ path: "online-payments", loadChildren: () => import('./online-payments/online-payments.module').then(m => m.OnlinePaymentsModule) },
	{ path: "quickbooksOAuthResponse", loadChildren: () => import('./quickbooks/quickbooks-oauth-response/quickbooks-oauth-response.module').then(m => m.QuickbooksOAuthResponseModule) },
	{ path: "corporate-view", loadChildren: () => import('./corporate-view/corporate-view.module').then(m => m.CorporateViewModule) },

	{ path: "404", component: PageNotFoundComponent }, 
	{ path: '**', component: PageNotFoundComponent }
];
