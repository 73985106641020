import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalsService } from "@services";


@Component({
	selector: "page-not-found",
	templateUrl: 'page-not-found.component.html',
	styleUrls: ['page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
	constructor(private router: Router) { }

	ngOnInit() {
		if (this.router.url.indexOf("/404") === -1)
			this.router.navigateByUrl("/404");

		if (GlobalsService.isLoggedIn === false)
			window.location.href = '/#/login';
	}
}