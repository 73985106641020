<div (click)="showTasks()" style="cursor: pointer;" class="tasks border border-dark border-top-0 border-left-0 border-right-0 ps-3 pe-3 ms-2 me-0 bg-white">
    <div class="tasks-icon text-info" style="cursor: pointer">
        <i *ngIf="taskCount ?? 0 > 0" class="badge bg-danger">{{taskCount}}</i>
        <i class="fal fa-list-check text-info"></i>
        <i class="fal fa-chevron-down"></i>
    </div>
</div>
<div class="task-details card card-body bg-white" [ngClass]="{ 'fade-in': showingTasks === true, 'fade-out': showingTasks === false }">
    <div class="d-flex mb-2 justify-content-end">
        <button class="btn btn-link" (click)="addTask()"><i style="font-size: 12px;" class="far fa-plus"></i> Add Task</button>
    </div>

    <div class="d-flex flex-fill text-body" style="min-width: 600px; max-width: 800px;">
        <task-list (hideTaskWindow)="hideTaskWindow()"></task-list>
    </div>
</div>

<task-edit-dialog #taskEditDialogRef></task-edit-dialog>

<slick-dialog #reminderDialogRef header="Reminder" (onClose)="onReminderDialogClose()" width="650px">
    Reminders:<br />
    <div *ngFor="let task of reminderTasks" class="d-flex flex-column mb-1 p-2 border">
        <div class="d-flex">
            <div><i class="fal fa-clock" style="font-size: 16px;"></i>&nbsp;</div>
            <div class="d-flex flex-column">
                <div style="line-height: 18px;"><b>{{task.title}}</b></div>
                <div *ngIf="task.dueDate" style="font-size: 12px;">{{task.dueDate | date:'M/d/yyy h:mm a'}}</div>
                <div *ngIf="task.jobName" style="font-size: 12px;">{{task.jobName}}</div>
                <pre *ngIf="task.description" style="font-size: 14px; padding: 0; margin: 0;">{{task.description}}</pre>

                <div class="mt-2">
                    <label>Snooze</label>
                    <div class="d-flex">
                        <button class="btn btn-xs btn-outline-primary me-1" (click)="snooze(task, 0)">Dismiss</button>
                        <button class="btn btn-xs btn-outline-primary me-1" (click)="snooze(task, 1)">1 minute</button>
                        <button class="btn btn-xs btn-outline-primary me-1" (click)="snooze(task, 5)">5 minutes</button>
                        <button class="btn btn-xs btn-outline-primary me-1" (click)="snooze(task, 10)">10 minutes</button>
                        <button class="btn btn-xs btn-outline-primary me-1" (click)="snooze(task, 15)">15 minutes</button>
                        <button class="btn btn-xs btn-outline-primary me-1" (click)="snooze(task, 30)">30 minutes</button>
                    </div>
                </div>

                <div class="d-flex mt-2">
                    <div class="complete-task" (click)="openTask(task)">
                        <i class="far fa-clipboard-list-check"></i> Open task
                    </div>
                    <div class="complete-task ms-3" (click)="completeTask($event, task)">
                        <span *ngIf="task.isCompleting" class="waiting-gif"></span><i class="far fa-check-circle"></i> Mark as Complete
                    </div>
                </div>
            </div>
        </div>
    </div>

</slick-dialog>