import { Injectable } from "@angular/core";
import { GlobalsService, SleepService, TasksService, UtilsService } from "@services";
import { BehaviorSubject, Subject } from "rxjs";
import { ITaskModel } from "../models";
import { sortBy } from "sort-by-typescript";
import * as moment from 'moment';

@Injectable()
export class TasksStore {
	private static uuid: string;
	private static _isMobile: boolean;
	private static _userTasks: ITaskModel[] = [];
	private static _nextReminderTimer: NodeJS.Timeout;
	private static _taskCount: number = 0;

	refreshTasks$ = new Subject<ITaskModel[]>();
	reminder$ = new Subject<ITaskModel[]>();

	constructor(private tasksService: TasksService) {
		TasksStore.uuid = UtilsService.newGuid();
	}

	getUuid(): string {
		return TasksStore.uuid;
	}

	async init(isMobile: boolean): Promise<void> {
		TasksStore._isMobile = isMobile;

		setTimeout(async () => {
			await this.refreshTasks();
		}, 500)
	}

	//*****************************************************************************
	//* taskCount
	//*****************************************************************************
	taskCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(TasksStore._taskCount);
	get taskCount(): number {
		return TasksStore._taskCount;
	}

	async refreshTasks(): Promise<ITaskModel[]> {
		TasksStore._userTasks = await this.tasksService.getAllTasksForUser(GlobalsService.userInfo.userId, false);
		this.setNextReminder();
		this.setTaskCount();

		this.refreshTasks$.next([...TasksStore._userTasks]);

		return [...TasksStore._userTasks];
	}

	getActiveTasks(): ITaskModel[] {
		return [...TasksStore._userTasks];
	}

	updateTask(taskModel: ITaskModel) {
		if (!taskModel.isCancelled && !taskModel.isCompleted && !taskModel.isDeleted) {
			const taskIdx = TasksStore._userTasks.findIndex(x => x.taskId === taskModel.taskId);

			if (taskIdx < 0)
				TasksStore._userTasks.push(taskModel);
			else
				TasksStore._userTasks[taskIdx] = taskModel;
		}

		this.setNextReminder();
		this.setTaskCount();

		this.refreshTasks$.next([...TasksStore._userTasks]);
	}

	removeTask(taskId: number) {
		TasksStore._userTasks = TasksStore._userTasks.filter(x => x.taskId !== taskId);

		this.setNextReminder();
		this.setTaskCount();

		this.refreshTasks$.next([...TasksStore._userTasks]);
	}

	public setNextReminder() {
		clearTimeout(TasksStore._nextReminderTimer);

		if (TasksStore._userTasks?.length > 0) {
			this.recalcNextReminderMilliseconds();
			const reminderTasks = TasksStore._userTasks.filter(x => x.assignedToUserIds.find(y => y === GlobalsService.userInfo.userId) && x.nextReminderMilliseconds !== null);
			if (reminderTasks?.length > 0) {
				reminderTasks.sort(sortBy("nextReminderMilliseconds^"));

				let nextReminder = reminderTasks[0];
				console.info(`Next Reminder in ${nextReminder.nextReminderMilliseconds} ms`)

				// If we have some reminders that are overdue, immediately show the reminder window
				if (nextReminder.nextReminderMilliseconds < 0) {
					const allReminders = reminderTasks.filter(r => r.nextReminderMilliseconds <= 0);
					this.reminder$.next(allReminders);
				}

				// Find the next reminder that is in the future
				nextReminder = reminderTasks.find(x => x.nextReminderMilliseconds > 0)

				if (nextReminder) {
					console.info(`Next Reminder Timer in ${nextReminder.nextReminderMilliseconds} ms`)
					TasksStore._nextReminderTimer = setTimeout(async () => {
						console.info('Task reminders triggered');
						// Need to recalc because the NextReminderMilliseconds would have been something like 34059
						this.recalcNextReminderMilliseconds();

						const allReminders = reminderTasks.filter(r => r.nextReminderMilliseconds <= 0);
						this.reminder$.next(allReminders);
						// Just in case we go into an infinite loop
						await SleepService.sleep(1000);
						this.setNextReminder();
					}, nextReminder.nextReminderMilliseconds);
				}
				else {
					console.info("No reminder timeout");
				}
			}
			else {
				console.info(`No task reminders`)
			}
		}

	}

	private setTaskCount(): number {
		const userId = GlobalsService.userInfo.userId;

		TasksStore._taskCount = TasksStore._userTasks
			.filter(x => (x.assignedToUserIds.find(u => u === userId)) && !x.isCancelled && !x.isCompleted)
			.length;

		this.taskCountSubject.next(TasksStore._taskCount);

		return TasksStore._taskCount;
	}

	private recalcNextReminderMilliseconds() {
		TasksStore._userTasks.forEach((task: ITaskModel) => {
			task.nextReminderMilliseconds = null;
			if (task.nextReminder && !task.isCancelled && !task.isCompleted && !task.isDeleted) {
				task.nextReminderMilliseconds = moment(task.nextReminder).diff(moment(), "milliseconds");
			}
		});
	}
}
