import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IEmailTemplateModel } from "@models";
import { TemplatesService } from "./templates.service";


@Injectable()
export class EmailTemplatesService extends TemplatesService {
	constructor(private httpService: HttpService) {
		super();
	}

	async getAllEmailTemplates(): Promise<IEmailTemplateModel[]> {
		return this.httpService.get(`/emailTemplates/getAllEmailTemplates`);
	}

	async updateEmailTemplate(emailTemplateModel: IEmailTemplateModel): Promise<IEmailTemplateModel> {
		return this.httpService.post(`/emailTemplates/updateEmailTemplate`, emailTemplateModel);
	}
}

