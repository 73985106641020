export interface IBusinessDevelopmentAccountTypeModel {
	businessDevelopmentAccountTypeId: number;
	description: string;
	active: boolean;
}

export class BusinessDevelopmentAccountTypeModel implements IBusinessDevelopmentAccountTypeModel {
	constructor() {
		this.businessDevelopmentAccountTypeId = 0;
		this.active = true;
	}

	businessDevelopmentAccountTypeId: number;
	description: string;
	active: boolean;
}