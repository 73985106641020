import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import Swal from 'sweetalert2';
import { IEmailerModel, ISurveyModel, ISurveyQuestionModel, ITwilioSettings } from "@models";


@Injectable()
export class SurveysService {
	constructor(private httpService: HttpService) { }

	doesSurveyExist(jobId: number): Promise<boolean> {
		return this.httpService.get("/surveys/doesSurveyExist", { jobId: jobId });
	}

	getSurveyForJob(jobId: number): Promise<ISurveyModel> {
		return this.httpService.get("/surveys/getSurveyForJob", { jobId: jobId });
	}

	async sendSurvey(jobId: number, phoneNumber: string): Promise<ISurveyModel> {

		const surveyExists = await this.doesSurveyExist(jobId);

		if (surveyExists) {
			const swalResult = await Swal.fire({
				icon: 'warning',
				title: 'Survey already sent',
				text: 'A survey for this job has already been sent.  Are you sure you want to send it again?',
				showConfirmButton: true,
				confirmButtonColor: '#007bff',
				showCancelButton: true,
				width: '28em',
				heightAuto: false
			});

			if (swalResult.isDismissed) {
				return null;
			}
		}

		const params = {
			jobId: jobId,
			phoneNumber: phoneNumber
		}

		return this.httpService.post('/surveys/sendSurvey', params);
	}

	getTwilioSettings(): Promise<ITwilioSettings> {
		return this.httpService.get("/surveys/getTwilioSettings");
	}

	updateTwilioSettings(twilioSettings: ITwilioSettings): Promise<ITwilioSettings> {
		return this.httpService.post("/surveys/updateTwilioSettings", twilioSettings);
	}

	getSurveyQuestions(): Promise<ISurveyQuestionModel[]> {
		return this.httpService.get("/surveys/getSurveyQuestions");
	}

	getSurveyQuestion(surveyQuestionId: number): Promise<ISurveyQuestionModel> {
		return this.httpService.get("/surveys/getSurveyQuestion", { surveyQuestionId: surveyQuestionId });
	}

	updateSurveyQuestion(surveyQuestionModels: ISurveyQuestionModel[]): Promise<ISurveyQuestionModel[]> {
		return this.httpService.post("/surveys/updateSurveyQuestions", surveyQuestionModels);
	}

	getSurveysForBusinessDevelopmentAccount(businessDevelopmentAccountId: number): Promise<ISurveyModel[]> {
		return this.httpService.get("/surveys/getSurveysForBusinessDevelopmentAccount", { businessDevelopmentAccountId})
	}

	generateSurveyEmail(surveyId: number): Promise<IEmailerModel> {
		return this.httpService.get("/surveys/generateSurveyEmail", { surveyId })
	}
}