import { GlobalsService, UtilsService } from "@services";
import { ICustomerModel } from "../customers/customer.model";
import { IAppliedPaymentModel } from "./applied-payment.model";
import * as moment from 'moment';
import { InvoiceModel } from "../invoices/invoice.model";

export interface IPaymentModel {
	paymentId: number;
	uuid: string;
	version: number;
	companyId: number;
	customerId: number;
	jobId?: number;
	paymentTypeId?: number;
	paymentTypeName: string;
	paymentDate: Date;
	paymentAmount: number;	
	creditCardFee?: number;
	creditCardFeeDescription: string;
	achFee?: number;
	achFeeDescription: string;
	referenceNumber: string;
	authCode: string;
	isPaymentRequest: boolean;
	notes: string;
	applyingPaymentSpinner: string;
	appliedPaymentsTotal: number;
	unappliedAmount: number;
	syncToQuickbooks: boolean;
	quickbooksId: string;

	customer: ICustomerModel;
	appliedPayments: IAppliedPaymentModel[];
}

export class PaymentModel implements IPaymentModel {
	constructor() {
		this.paymentId = 0;
		this.uuid = UtilsService.newGuid();
		this.paymentDate = moment().startOf("day").toDate();
		this.paymentAmount = 0.0;
		this.isPaymentRequest = false;

		this.appliedPayments = [];
	}

	paymentId: number;
	uuid: string;
	version: number;
	companyId: number;
	customerId: number;
	jobId?: number;
	paymentTypeId?: number;
	paymentTypeName: string;
	paymentDate: Date;
	paymentAmount: number;
	creditCardFee?: number;
	creditCardFeeDescription: string;
	achFee?: number;
	achFeeDescription: string;
	referenceNumber: string;
	authCode: string;
	isPaymentRequest: boolean;
	notes: string;
	appliedPaymentsTotal: number;
	unappliedAmount: number;
	syncToQuickbooks: boolean;
	quickbooksId: string;

	applyingPaymentSpinner: string;

	customer: ICustomerModel;
	appliedPayments: IAppliedPaymentModel[];
}