export interface ILoginModel {
	userName: string;
	password: string;
	rememberMe: boolean;
	version: string;
	recaptcha: string;
	isAdmin: boolean;
}

export class LoginModel implements ILoginModel {
	userName: string;
	password: string;
	rememberMe: boolean;
	version: string;
	recaptcha: string;
	isAdmin: boolean;
}

