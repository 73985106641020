export interface ICreditCardProcessPaymentModel {
	customerId: number;
	jobId?: number;
	invoiceId?: number;
	paymentId?: number;
	creditCardPaymentTypeId: number;
	amount: number;
	creditCardFee?: number;
	creditCardFeePercent?: number;
	creditCardFeeDescription: string;
	achFee?: number;
	achFeePercent?: number;
	achFeeDescription: string;
	cardCode: string;
	invoiceNumber: string;
	poNumber: string;
	paymentReferenceCode: string;
	isOnlinePayment: boolean;
	isDownPayment: boolean;
	syncToQuickbooks: boolean;
}

export class CreditCardProcessPaymentModel implements ICreditCardProcessPaymentModel {
	customerId: number;
	jobId?: number;
	invoiceId?: number;
	paymentId?: number;
	creditCardPaymentTypeId: number;
	amount: number;
	creditCardFee?: number;
	creditCardFeePercent?: number;
	creditCardFeeDescription: string;
	achFee?: number;
	achFeePercent?: number;
	achFeeDescription: string;
	cardCode: string;
	invoiceNumber: string;
	poNumber: string;
	paymentReferenceCode: string;
	isOnlinePayment: boolean;
	isDownPayment: boolean;
	syncToQuickbooks: boolean;
}