import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { GlobalsService } from "./utils/globals.service";
import { ILoginModel } from "@models";
import { IResetPasswordModel } from "@models";
import { Observable } from 'rxjs';

@Injectable()
export class ForgotPasswordService {
	readonly NUM_BACKGROUND_IMGS: number = 9;
	static imgName: string;

	constructor(private httpService: HttpService) { }


	async getResetPasswordUser(userIdentifier: string): Promise<string>{
		return this.httpService.get(`/forgotPassword/getResetPasswordUser?userIdentifier=${userIdentifier}`);
	}

	async verifyResetPasswordCode(uuid: string, authCode: string): Promise<string> {
		return this.httpService.post(`/forgotPassword/verifyResetPasswordCode`, { uuid, authCode });
	}

	async sendResetPassword(emailAddress: string): Promise<boolean> {
		return this.httpService.post(`/forgotPassword/requestPasswordReset?email=${emailAddress}`, {});
	}

	requestPasswordReset(emailAddress: string): Promise<any> {
		return this.httpService.get(`/forgotPassword/requestPasswordReset?emailAddress=${emailAddress}`);
	}

	resetPasswordWithToken(token: string, authCode: string, newPassword: string): Promise<string> {
		const params = {
			token,
			authCode, 
			newPassword
		}
		return this.httpService.post(`/forgotPassword/ResetPasswordWithToken`, params);
	}
	
}