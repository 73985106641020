export interface ICalendarSubModel {
	subcontractorId: number;
	displayName: string;
	trades: string;
	visible: boolean;
	scheduleColor: string;
}

export class CalendarSubModel implements ICalendarSubModel {
	subcontractorId: number;
	displayName: string;
	trades: string;
	visible: boolean;
	scheduleColor: string;
}