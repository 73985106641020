import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IQBWCTaskGridModel, IQBWCTaskModel } from "@models";

@Injectable()
export class QuickbooksService {
	constructor(private httpService: HttpService) {

	}

	async getQBWCTask(qbwcTaskId: number): Promise<IQBWCTaskModel> {
		try {
			return await Promise.resolve(await this.httpService.get("/quickbooks/getQBWCTask", { qbwcTaskId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	} 

	async getQBWCTaskGrid(qbwcTaskId: number): Promise<IQBWCTaskGridModel> {
		try {
			return await Promise.resolve(await this.httpService.get("/quickbooks/getQBWCTaskGrid", { qbwcTaskId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async updateQBWCTask(qbwcTaskModel: IQBWCTaskModel): Promise<IQBWCTaskModel> {
		return await this.httpService.post("/quickbooks/updateQBWCTask", qbwcTaskModel);
	}

	async syncClasses(): Promise<IQBWCTaskGridModel> {
		try {
			return await Promise.resolve(await this.httpService.get("/quickbooks/syncClasses"));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async syncChartOfAccounts(): Promise<IQBWCTaskGridModel> {
		try {
			return await Promise.resolve(await this.httpService.get("/quickbooks/syncChartOfAccounts"));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async syncEmployees(): Promise<IQBWCTaskGridModel> {
		try {
			return await Promise.resolve(await this.httpService.get("/quickbooks/syncEmployees"));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async syncItems(): Promise<IQBWCTaskGridModel> {
		try {
			return await Promise.resolve(await this.httpService.get("/quickbooks/syncItems"));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async syncCustomers(fromName: string, toName: string): Promise<IQBWCTaskGridModel> {
		try {
			const params = {
				fromName: fromName,
				toName: toName
			}
			return this.httpService.get("/quickbooks/syncCustomers", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async syncVendors(): Promise<IQBWCTaskGridModel> {
		try {
			return await Promise.resolve(await this.httpService.get("/quickbooks/syncVendors"));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async processResponseXML(qbwcTaskId) {
		try {
			return await Promise.resolve(await this.httpService.get("/quickbooks/processResponseXML", { qbwcTaskId: qbwcTaskId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}
}