export interface IWIPSummaryItemCodeModel {
	itemCode: string;
	budgetTotal: number;
	expensesTotal: number;
	laborTotal: number;
	total: number;
}

export class WIPSummaryItemCodeModel implements IWIPSummaryItemCodeModel {
	constructor(itemCode: string) {
		this.itemCode = itemCode;
		this.budgetTotal = 0.0;
		this.expensesTotal = 0.0;
		this.laborTotal = 0.0;
		this.total = 0.0;
	}

	itemCode: string;
	budgetTotal: number;
	expensesTotal: number;
	laborTotal: number;
	total: number;
}
