import { UtilsService } from "@services/utils/utils.service";

export interface ICertificationModel {
	certificationId: number;
	uuid: string;
	certificationName: string;
	payIncrease: boolean;
	active: boolean;
}

export class CertificationModel implements ICertificationModel {
	constructor() {
		this.certificationId = 0;
		this.uuid = UtilsService.newGuid();
		this.certificationName = '';
		this.payIncrease = false;
		this.active = true;
	}

	certificationId: number;
	uuid: string;
	certificationName: string;
	payIncrease: boolean;
	active: boolean;
}