import { Component, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { GlobalsService } from "@services";
import { VersionReleaseDialogComponent } from "@shared-components/version-release-dialog"

@Component({
	selector: "footer-bar",
	templateUrl: "footer-bar.component.html",
	styles: [`
		.footer-bar {
			height: 1px;
			min-height: 23px;
			line-height: 23px;
		}`]
})

export class FooterBarComponent {
	@ViewChild("dialogRef") dialogRef: VersionReleaseDialogComponent;

	isLoggedIn: boolean = false;
	isLoginPage: boolean;
	currentYear: number = new Date().getFullYear();
	version: string = GlobalsService.appVersion;
	isDebug = GlobalsService.isDebug;
	isStaging = GlobalsService.isStaging;
	isRC = GlobalsService.isRC;

	constructor(private router: Router) {
		this.isLoggedIn = GlobalsService.isLoggedIn;
		this.isLoginPage = (router.url.toLowerCase() === '/#/login' ||
			router.url.toLowerCase() === '/#/login/forgot-password' ||
			router.url.toLowerCase() === '/#/login/reset-password');
	}

	async showVersionHistory() {
		await this.dialogRef.showClickedVersion();
	}
}