import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SleepService, JobsService, LookupService } from "@services";
import { SlickDialogComponent } from "slick-components";
import { IDropdownModel } from "../../models";

@Component({
	selector: "not-accepted-reason-dialog",
	templateUrl: "not-accepted-reason-dialog.component.html"
})

export class NotAcceptedReasonDialogComponent implements OnInit {
	@Input() isMobile: boolean;
	@ViewChild("notAcceptedReasonDialogRef") notAcceptedReasonDialogRef: SlickDialogComponent;

	protected res: any;

	protected spinnerStatus: string;
	protected isSubmitted: boolean = false;

	notAcceptedReasonSubmitted: boolean;
	notAcceptedReasonId: number = 0;

	notAcceptedReasons: IDropdownModel[];

	width: number = 320;

	constructor(private jobsService: JobsService,
		private lookupService: LookupService) {
	}

	async ngOnInit() {
		this.notAcceptedReasons = await this.lookupService.getNotAcceptedReasonsForDropdown();
		this.isMobile = (!!this.isMobile).toString() === 'true';
	}

	async showDialog(): Promise<number> {
		this.spinnerStatus = "reset";
		this.isSubmitted = false;
		
		this.width = (this.isMobile) ? 320 : 520;
		
		return new Promise<number>((res) => {
			this.res = res;

			this.notAcceptedReasonDialogRef.showDialog();
		})
	}

	async onNotAcceptedReasonSelect() {
		this.notAcceptedReasonSubmitted = true;

		if (!this.notAcceptedReasonId)
			return;

		this.notAcceptedReasonDialogRef.hideDialog();

		this.res(this.notAcceptedReasonId);
	}

	async onNotAcceptedReasonCancel() {
		this.notAcceptedReasonDialogRef.hideDialog();

		this.res(null);
	}



}
