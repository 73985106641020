import { UtilsService } from "@services/utils/utils.service";

export interface ITimeclockBreakModel {
	uuid: string;
	timeclockEntryId: number;
	breakStart?: Date;
	breakStartGeolocation: string;
	breakEnd?: Date;
	breakEndGeolocation: string;
    totalMinutes?: number;
}

export class TimeclockBreakModel implements ITimeclockBreakModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
	}
	uuid: string;
	timeclockEntryId: number;
    breakStart?: Date;
	breakStartGeolocation: string;
	breakEnd?: Date;
	breakEndGeolocation: string;
    totalMinutes?: number;
}