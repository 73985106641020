import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { SleepService } from "@services";
import { SlickDialogComponent } from "slick-components";
import { CustomerEditComponent } from "./customer-edit.component";
import { CustomersService } from "@services";
import { ICustomerModel } from "@models";

@Component({
	selector: "customer-edit-dialog",
	template: `
		<slick-dialog #dialogRef [header]="dialogHeader" [width]='800'>
			<customer-edit #customerEditRef [(customerModel)]='customerModel' [showQuickbooksName]='showQuickbooksName' [showButtons]="false"></customer-edit>			
			<slick-dialog-footer>
				<div class="form-group form-save-buttons">
					<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary save-button" (click)="onSaveCustomer()"><i class="far fa-save"></i>&nbsp;Save</button>
					<button type="button" class="btn btn-outline-danger cancel-button" (click)="onCancelCustomer()"><i class="far fa-ban"></i>&nbsp;Cancel</button>
				</div>
			</slick-dialog-footer>
		</slick-dialog>
`
})
export class CustomerEditDialogComponent {
	@Input() customerModel: ICustomerModel;
	@Input() showQuickbooksName: boolean = false;
	@Output() onSave: EventEmitter<ICustomerModel> = new EventEmitter<ICustomerModel>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild("customerEditRef", { static: true }) customerEditRef: CustomerEditComponent;

	dialogHeader: string;
	spinnerStatus: string;

	constructor(private customersService: CustomersService) {
	}

	showDialog() {
		this.customerEditRef.spinnerStatus = "reset";
		setTimeout(() => {
			if (this.customerModel.customerId > 0)
				this.dialogHeader = this.customerModel.contact.displayName;
			else
				this.dialogHeader = "New Customer";

			this.dialogRef.showDialog();
		})
	}

	async onSaveCustomer() {
		try {
			var isValid = this.customerEditRef.validate();
			if (!isValid)
				return;

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			if (this.customerModel.customerId > 0) {
				let concurrencyIsValid = await this.customerEditRef.validateConcurrencyIsValid();
				if (concurrencyIsValid === false) {
					this.spinnerStatus = "error";
					return;
				}
			}

			let updatedModel = await this.customersService.updateCustomer(this.customerModel)
			this.spinnerStatus = "ok";
			this.dialogRef.hideDialog();
			this.onSave.emit(updatedModel);
		}
		catch (error) {
			console.error(error);
			this.spinnerStatus = "error";
		}
	}

	onCancelCustomer() {
		this.dialogRef.hideDialog();
		this.onCancel.emit();
	}
}


