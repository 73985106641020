import { Component, Input, Output, EventEmitter, ViewChild, forwardRef, SimpleChanges, OnChanges } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { UtilsService, GlobalsService, CustomersService, SleepService } from "@services";
import { CustomerEditDialogComponent } from "@app/customers/customer-edit/customer-edit-dialog.component";
import { ICustomerModel, ICustomerSearchListModel } from "@models";

@Component({
	selector: "customer-search",
	templateUrl: "customer-search.component.html",
	providers: [
		CustomersService,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CustomerSearchComponent),
			multi: true,
		}]
})
export class CustomerSearchComponent implements OnChanges {
	@Input() alwaysAllowAddNew: boolean = true;
	@Input() showButtons: boolean = true;
	@Output() onSelect: EventEmitter<ICustomerModel> = new EventEmitter<ICustomerModel>();
	@Output() onAdd: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("customerEditDialogRef", { static: true }) customerEditDialogRef: CustomerEditDialogComponent;

	customerSearchText: string;
	selectedCustomer: ICustomerModel;
	dialogCustomerModel: ICustomerModel;
	results: ICustomerSearchListModel[];
	canCreateCustomers: boolean = false;
	canUpdateCustomers: boolean = false;

	constructor(private customersService: CustomersService) {
		this.canCreateCustomers = GlobalsService.checkPermission("Customers", "Create");
		this.canUpdateCustomers = GlobalsService.checkPermission("Customers", "Update");	
	}

	ngOnChanges(changes: SimpleChanges) {
		this.alwaysAllowAddNew = (this.alwaysAllowAddNew.toString().toLowerCase() === 'true') ? true : false;
		this.showButtons = (this.showButtons.toString().toLowerCase() === 'true') ? true : false;
	}

	private propagateChange = (_: any) => { };

	// this is the initial value set to the component
	public writeValue(obj: ICustomerModel) {
		if (obj) {
			this.selectedCustomer = obj;
			this.customerSearchText = this.selectedCustomer.contact.displayName;
		}
		else {
			this.selectedCustomer = null;
			this.customerSearchText = null;
		}
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	async search(searchText) {
		this.results = await this.customersService.getCustomerSearchResults(searchText);
	}

	async checkEmpty() {

		await SleepService.sleep();

		if (!this.customerSearchText) {
			this.onSelect.emit(null);
		}

	}

	async onSelectCustomer(event: ICustomerSearchListModel) {
		if (!event) {
			this.selectedCustomer = null;
			if (this.onSelect)
				this.onSelect.emit(null);
			this.propagateChange(null);
			return;
		}

		this.customerSearchText = event.companyName || event.fullName;

		this.selectedCustomer = await this.customersService.getCustomer(event.customerId);
		if (this.onSelect)
			this.onSelect.emit(this.selectedCustomer);
		this.propagateChange(this.selectedCustomer);
	}

	addNewCustomer() {
		this.selectedCustomer = null;

		if (this.onAdd.observers.length > 0) {
			this.onAdd.emit(this.customerSearchText);
		}
		else {
			this.dialogCustomerModel = this.customersService.getNewCustomerModel();
			this.customerEditDialogRef.showDialog();
		}
	}

	async editCustomer() {
		this.dialogCustomerModel = UtilsService.clone(this.selectedCustomer);
		this.customerEditDialogRef.showDialog();
	}

	onSave(customerModel: ICustomerModel) {
		this.selectedCustomer = customerModel;
		if (this.onSelect)
			this.onSelect.emit(this.selectedCustomer);
		this.propagateChange(this.selectedCustomer);
		this.customerSearchText = this.selectedCustomer.contact.displayName;
	}
}


