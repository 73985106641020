import { Component, ViewChild } from "@angular/core";
import { SlickDialogComponent } from "slick-components";


@Component({
	selector: "concurrency-error-dialog",
	template:
		`
<slick-dialog width='350' top='200' #dialogRef header="{{title}}">
	<div style='margin-bottom: 15px;'><b>{{errorMessage}}</b></div>   
	<div style='font-size: 12px'>Concurrency errors occur when another user has updated this record and saving this record would overwrite those changes.  Please re-enter your changes.</div>
</slick-dialog>`
})
export class ConcurrencyErrorDialogComponent {
	title: string;
	errorMessage: string;

	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;

	constructor() { }

	public show(title: string, errorMessage: any) {
		this.title = title;
		this.errorMessage = errorMessage;
		this.dialogRef.showDialog();
	}
}