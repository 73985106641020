import { Injectable } from "@angular/core";
import { IDropdownModel, IEmailerModel, IWorkOrderGridModel, IWorkOrderModel, IWorkOrderSearchListModel, WorkOrderLineItemModel } from "../models";
import { HttpService } from "./utils/http.service";
import { UtilsService } from "./utils/utils.service";
import { ISlickFileModel } from "slick-components";

@Injectable()
export class WorkOrdersService {
	constructor(private httpService: HttpService) { }

	async getWorkOrder(workOrderId: number): Promise<IWorkOrderModel> {
		return this.httpService.get(`/workOrders/getWorkOrder?workOrderId=${workOrderId}`);
	}

	async getWorkOrderForGrid(workOrderId: number): Promise<IWorkOrderGridModel> {
		return this.httpService.get(`/workOrders/getWorkOrderForGrid?workOrderId=${workOrderId}`);
	}

	async getWorkOrdersForInfiniteScroll(pageCount: number, searchString: string): Promise<IWorkOrderGridModel[]> {
		const params = {
			pageCount,
			searchString
		}

		return this.httpService.get(`/workOrders/getWorkOrdersForInfiniteScroll`, params);
	}

	async getAllWorkOrdersForJob(jobId: number): Promise<IWorkOrderModel[]> {
		return this.httpService.get(`/workOrders/getAllWorkOrdersForJob?jobId=${jobId}`);
	}

	async acceptWorkOrder(workOrderId: number): Promise<IWorkOrderModel> {
		return this.httpService.put(`/workOrders/acceptWorkOrder?workOrderId=${workOrderId}`);
	}

	async updateWorkOrder(workOrderModel: IWorkOrderModel): Promise<IWorkOrderModel> {
		if (workOrderModel.workOrderId === 0) 
			return this.httpService.post("/workOrders/addWorkOrder", workOrderModel);
		else 
			return this.httpService.post("/workOrders/updateWorkOrder", workOrderModel)
	}

	async deleteWorkOrder(workOrderId: number): Promise<boolean> {
		return this.httpService.delete(`/workOrders/deleteWorkOrder?workOrderId=${workOrderId}`);
	}

	async getAllJobsForDropdown(): Promise<IDropdownModel[]> {
		return this.httpService.get("/workOrders/getAllJobsForDropdown");
	}

	async getAllSubsForDropdown(): Promise<IDropdownModel[]> {
		return this.httpService.get("/workOrders/getAllSubsForDropdown");
	}

	async pinWorkOrder(workOrderId: number): Promise<boolean> {
		return this.httpService.patch(`/workOrders/pinWorkOrder?workOrderId=${workOrderId}`);
	}

	async unpinWorkOrder(workOrderId: number): Promise<boolean> {
		return this.httpService.patch(`/workOrders/unpinWorkOrder?workOrderId=${workOrderId}`);
	}

	async generateWorkOrderPDF(workOrderModel: IWorkOrderModel): Promise<string> {
		const response = await this.httpService.get(`/workOrders/generateWorkOrderPDF?workOrderId=${workOrderModel.workOrderId}`);
		return response.url;
	}

	async getWorkOrderEmail(workOrderModel: IWorkOrderModel): Promise<IEmailerModel> {
		return this.httpService.get(`/workOrders/getWorkOrderEmail?workOrderId=${workOrderModel.workOrderId}`);
	}

	async sendWorkOrderEmail(workOrderId: number, emailerModel: IEmailerModel): Promise<IEmailerModel> {
		const formData: FormData = new FormData();
		emailerModel.attachments.forEach(file => {
			if (file.base64Image && file.base64Image.length > 0) {
				let fileCopy: ISlickFileModel = UtilsService.clone(file);
				let fileName = file.name;
				delete fileCopy.file;
				delete fileCopy.thumbnailBase64Image;
				let fileJSON = JSON.stringify(fileCopy);
				formData.append('image_' + file.uuid, fileJSON);
			}
			else if (file.file) {
				formData.append('file', file.file, file.file.name);
			}
		})

		let emailerModelTemp: IEmailerModel = UtilsService.clone(emailerModel);
		emailerModelTemp.attachments = emailerModelTemp.attachments.map(att => {
			delete att.file;
			delete att.base64Image;
			delete att.thumbnailBase64Image;
			return att;
		});
		formData.append("emailerModel", JSON.stringify(emailerModelTemp));

		return this.httpService.postMultipart(`/workOrders/sendWorkOrderEmail?workOrderId=${workOrderId}`, formData);
	}


	getNewLineItemModel(displaySeq: number): WorkOrderLineItemModel {
		const newModel = new WorkOrderLineItemModel();
		newModel.uuid = UtilsService.newGuid();
		newModel.displaySeq = displaySeq;
		newModel.description = '';
		newModel.quantity = 1;
		newModel.amount = 0;
		newModel.isNewLineItem = true;

		return newModel;
	}

	async getWorkOrderSearchResults(searchText, subcontractorId?: number): Promise<IWorkOrderSearchListModel[]> {
		const params = {
			searchText: searchText,
			subcontractorId: subcontractorId
		}

		if (!subcontractorId)
			delete params.subcontractorId;

		return this.httpService.get("/workOrders/getWorkOrderSearchResults", params);
	}
}
