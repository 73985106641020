import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { JobEditModule } from "@app/jobs";
import { QuickbooksTaskDisplayModule } from "@app/quickbooks";
import { HeaderBarComponent } from "./header/header-bar.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { TasksComponent } from "./tasks/tasks.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { FooterBarComponent } from "./footer/footer-bar.component";
import { TasksComponentsModule } from "@app/tasks/tasks-components/tasks-components.module"
import { SlickDialogModule, SlickDropDownModule, SlickPopoverModule } from "slick-components";
import { VersionReleaseDialogModule } from "@shared-components/version-release-dialog"
import { LoginService } from "@services"

@NgModule({
	imports: [CommonModule, RouterModule, JobEditModule, TasksComponentsModule, VersionReleaseDialogModule, QuickbooksTaskDisplayModule, SlickDialogModule, SlickDropDownModule, SlickPopoverModule],
	declarations: [HeaderBarComponent, TasksComponent, NotificationsComponent, SideMenuComponent, FooterBarComponent],
	exports: [HeaderBarComponent, SideMenuComponent, FooterBarComponent],
	providers: [LoginService]
})
export class LayoutModule {
}
