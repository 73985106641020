import { Injectable } from '@angular/core';
import { DropdownModel, IDropdownModel, IPayPeriodDefinitionModel, IPayPeriodModel } from '@models';
import { Subject } from 'rxjs';
import { GlobalsService, LookupService } from '@services';

@Injectable()
export class TimeclockStore {
	private _selectedPayPeriodYear: number;
	private _selectedPayPeriodDefinition: IPayPeriodDefinitionModel;
	private _selectedPayPeriodDefinitionId?: number;
	private _selectedPayPeriod: IPayPeriodModel;
	private _selectedPayPeriodId?: number;
	private _filter: string;

	private _allPayPeriods: IPayPeriodModel[] = [];
	private _payPeriods: IPayPeriodModel[] = [];
	private _payPeriodDefinitions: IPayPeriodDefinitionModel[] = [];
	private _payPeriodYears: IDropdownModel[] = [new DropdownModel(2020, '2020'),
	new DropdownModel(2021, '2021'),
		new DropdownModel(2022, '2022'),
		new DropdownModel(2023, '2023'),
		new DropdownModel(2024, '2024'),
		new DropdownModel(2024, '2025'),
		new DropdownModel(2024, '2026')];

	constructor(private lookupService: LookupService) {

	}

	async init() {
		this._selectedPayPeriodYear = parseInt(localStorage.getItem(`TIMECLOCK_SELECTED_PAY_PERIOD_YEAR_${GlobalsService.companyInfo.companyId}`) || '2022');

		const results = await Promise.all([this.lookupService.getPayPeriodDefinitions(), this.lookupService.getPayPeriods()])
		this._payPeriodDefinitions = results[0];
		this._allPayPeriods = results[1];

		
		if (this._payPeriodDefinitions && this._payPeriodDefinitions.length > 0) {
			this.populatePayPeriodDefinition();

			if (this._selectedPayPeriodDefinition) {
				await this.refreshPayPeriods();
				this.populatePayPeriodDefinition();
			}
		}

		this._filter = localStorage.getItem(`TIMECLOCK_FILTER_${GlobalsService.companyInfo.companyId}`) || 'all';
	}

	// Used any time we need to refresh the grid
	refreshGridSubject: Subject<void> = new Subject<void>();

	//*****************************************************************************
	//* payPeriodYear
	//*****************************************************************************
	get selectedPayPeriodYear(): number {
		return this._selectedPayPeriodYear;
	}

	set selectedPayPeriodYear(payPeriodYear: number) {
		this._selectedPayPeriodYear = payPeriodYear;
		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_YEAR_${GlobalsService.companyInfo.companyId}`, (payPeriodYear || 2022).toString())

		this.refreshPayPeriods();

		this.refreshGridSubject.next();
	}

	getPayPeriodYears(): IDropdownModel[] {
		return this._payPeriodYears;
	}

	//*****************************************************************************
	//* payPeriodDefinition
	//*****************************************************************************
	get selectedPayPeriodDefinition(): IPayPeriodDefinitionModel {
		this.populatePayPeriodDefinition();
		return this._selectedPayPeriodDefinition;
	}

	set selectedPayPeriodDefinition(payPeriodDefinition: IPayPeriodDefinitionModel) {
		this._selectedPayPeriodDefinition = payPeriodDefinition;
		this._selectedPayPeriodDefinitionId = payPeriodDefinition?.payPeriodDefinitionId;

		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_DEFINITION_ID_${GlobalsService.companyInfo.companyId}`, (payPeriodDefinition?.payPeriodDefinitionId ?? 0).toString())

		this.refreshPayPeriods();

		this.refreshGridSubject.next();
	}

	getPayPeriodDefinitions(): IPayPeriodDefinitionModel[] {
		return this._payPeriodDefinitions;
	}

	//*****************************************************************************
	//* filter
	//*****************************************************************************
	filterSubject: Subject<string> = new Subject<string>();
	get filter(): string {
		return this._filter;
	}

	set filter(filter: string) {
		this._filter = filter;
		localStorage.setItem(`TIMECLOCK_FILTER_${GlobalsService.companyInfo.companyId}`, filter || 'all');

		this.refreshGridSubject.next();
	}

	//*****************************************************************************
	//* payPeriods
	//*****************************************************************************
	selectedPayPeriodSubject: Subject<IPayPeriodModel> = new Subject<IPayPeriodModel>();
	get selectedPayPeriod(): IPayPeriodModel {
		this.populatePayPeriod();
		return this._selectedPayPeriod;
	}

	set selectedPayPeriod(payPeriod: IPayPeriodModel) {
		this._selectedPayPeriod = payPeriod;
		this._selectedPayPeriodId = this._selectedPayPeriod.payPeriodId;
		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_ID_${GlobalsService.companyInfo.companyId}_${this._selectedPayPeriodYear}_${this._selectedPayPeriodDefinitionId}`, (payPeriod?.payPeriodId ?? 0).toString())

		this.refreshGridSubject.next();
	}

	getPayPeriods(): IPayPeriodModel[] {
		return this._payPeriods;
	}


	payPeriodsChangedSubject: Subject<IPayPeriodModel[]> = new Subject<IPayPeriodModel[]>();
	private refreshPayPeriods() {
		this._payPeriods = this._allPayPeriods
			.filter(x => x.payPeriodDefinitionId === this.selectedPayPeriodDefinition.payPeriodDefinitionId && x.payPeriodYear === this._selectedPayPeriodYear);

		this.populatePayPeriod();

		this.payPeriodsChangedSubject.next(this._payPeriods);
	}

	private populatePayPeriodDefinition() {
		this._selectedPayPeriodDefinition = null;
		this._selectedPayPeriodDefinitionId = null;

		if (this._payPeriodDefinitions && this._payPeriodDefinitions.length > 0) {
			this._selectedPayPeriodDefinitionId = parseInt(localStorage.getItem(`TIMECLOCK_SELECTED_PAY_PERIOD_DEFINITION_ID_${GlobalsService.companyInfo.companyId}`) || this._payPeriodDefinitions[0].payPeriodDefinitionId.toString());
			this._selectedPayPeriodDefinition = this._payPeriodDefinitions.find(x => x.payPeriodDefinitionId === this._selectedPayPeriodDefinitionId);

			if (!this._selectedPayPeriodDefinition) {
				this._selectedPayPeriodDefinition = this._payPeriodDefinitions[0];
				this._selectedPayPeriodDefinitionId = this._selectedPayPeriodDefinition.payPeriodDefinitionId;
				localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_DEFINITION_ID_${GlobalsService.companyInfo.companyId}`, this._selectedPayPeriodDefinitionId.toString());
			}
		}
	}

	private populatePayPeriod() {
		this._selectedPayPeriod = null;
		this._selectedPayPeriodId = null;

		if (this._payPeriods && this._payPeriods.length > 0) {
			this._selectedPayPeriodId = parseInt(localStorage.getItem(`TIMECLOCK_SELECTED_PAY_PERIOD_ID_${GlobalsService.companyInfo.companyId}_${this._selectedPayPeriodYear}_${this._selectedPayPeriodDefinitionId}`) || '0');
			this._selectedPayPeriod = this._payPeriods.find(x => x.payPeriodId === this._selectedPayPeriodId);

			if (!this._selectedPayPeriod) {
				this._selectedPayPeriod = this._payPeriods[0];
				this._selectedPayPeriodId = this._selectedPayPeriod.payPeriodId;
				localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_ID_${GlobalsService.companyInfo.companyId}_${this._selectedPayPeriodYear}_${this._selectedPayPeriodDefinitionId}`, this._selectedPayPeriodId.toString());
			}
		}
	}
}
