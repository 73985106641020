import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { GlobalsService } from '../services';
import { MessageQueueDispatcher } from "@message-queue";

@Injectable()
export class PayablesStore {
	private _payablesAwaitingApprovalCount: number;

	payablesAwaitingApprovalCount$: Subscription;

	constructor(private readonly messageQueueDispatcher: MessageQueueDispatcher) {
		this._payablesAwaitingApprovalCount = GlobalsService.userBadges.payablesAwaitingApprovalCount;
		this.payablesAwaitingApprovalCount$ = this.messageQueueDispatcher.payablesAwaitingApprovalCount.subscribe(x => {
			this.payablesAwaitingApprovalCount = x;
		});
	}

	//*****************************************************************************
	//* payablesAwaitingApprovalCount
	//*****************************************************************************
	payablesAwaitingApprovalCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(GlobalsService.userBadges.payablesAwaitingApprovalCount);
	get payablesAwaitingApprovalCount(): number {
		return this._payablesAwaitingApprovalCount;
	}

	set payablesAwaitingApprovalCount(payablesAwaitingApprovalCount: number) {
		GlobalsService.userBadges.payablesAwaitingApprovalCount = payablesAwaitingApprovalCount;
		this._payablesAwaitingApprovalCount = payablesAwaitingApprovalCount;
		this.payablesAwaitingApprovalCountSubject.next(this._payablesAwaitingApprovalCount);
	}
}