import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IAssignmentConfirmationModel, IXactAssignmentGridModel, IXactAssignmentModel } from "@models";

@Injectable()
export class XactwareService {
	constructor(private httpService: HttpService) { }

	async syncJobToXactAnalysis(jobId: number): Promise<IAssignmentConfirmationModel> {
		return this.httpService.post(`/xactware/syncJobToXactAnalysis?jobId=${jobId}`, {});
	}

	async getXactAssignment(xactAssignmentId: number): Promise<IXactAssignmentModel> {
		return this.httpService.get(`xactware/getXactAssignment?xactAssignmentId=${xactAssignmentId}`);
	}

	async getXactAssignmentForGrid(xactAssignmentId: number): Promise<IXactAssignmentGridModel> {
		return this.httpService.get(`xactware/getXactAssignmentForGrid?xactAssignmentId=${xactAssignmentId}`);
	}

	async acceptXactAssignment(xactAssignmentId: number): Promise<IXactAssignmentModel> {
		return this.httpService.put(`xactware/acceptXactAssignment?xactAssignmentId=${xactAssignmentId}`);
	}


	async createJobFromXactAssignment(xactAssignmentId: number): Promise<IXactAssignmentModel> {
		return this.httpService.put(`xactware/createJobFromXactAssignment?xactAssignmentId=${xactAssignmentId}`);
	}

	async linkJobFromXactAssignment(xactAssignmentId: number, toJobId: number): Promise<IXactAssignmentModel> {
		return this.httpService.put(`xactware/linkJobFromXactAssignment?xactAssignmentId=${xactAssignmentId}&toJobId=${toJobId}`);
	}

	async declineXactAssignment(xactAssignmentId: number): Promise<IXactAssignmentModel> {
		return this.httpService.put(`xactware/declineXactAssignment?xactAssignmentId=${xactAssignmentId}`);
	}
}