import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IDropdownModel, IQuickbooksSettingsModel } from "@models";

@Injectable()
export class QuickbooksSettingsService {
    constructor(private httpService: HttpService) {

    }

    async disconnect(): Promise<IQuickbooksSettingsModel> {
        return await this.httpService.get("/quickbooksSettings/disconnect");
    }

    async getQuickbooksAccountsForDropDown(): Promise<IDropdownModel[]> {
        return await this.httpService.get("/quickbooksSettings/getQuickbooksAccountsForDropDown");
    }

    async getQuickbooksClassesForDropDown(): Promise<IDropdownModel[]> {
        return await this.httpService.get("/quickbooksSettings/getQuickbooksClassesForDropDown");
    }

    async getQuickbooksCustomFieldsForDropDown(): Promise<IDropdownModel[]> {
        return await this.httpService.get("/quickbooksSettings/getQuickbooksCustomFieldsForDropDown");
    }

    async getQuickbooksItemSubcategoriesForDropdown(): Promise<IDropdownModel[]> {
        return await this.httpService.get("/quickbooksSettings/getQuickbooksItemSubcategoriesForDropdown");
    }

    async getQuickbooksItemSalesTaxesForDropDown(): Promise<IDropdownModel[]> {
        return await this.httpService.get("/quickbooksSettings/getQuickbooksItemSalesTaxesForDropDown");
    }

    async getSettings(): Promise<IQuickbooksSettingsModel> {
        return await this.httpService.get("/quickbooksSettings/getSettings");
    }

    async isQuickbooksConnected(): Promise<IQuickbooksSettingsModel> {
        return await this.httpService.get("/quickbooksSettings/isQuickbooksConnected");
    }

    async updateSettings(quickbooksSettingsModel: IQuickbooksSettingsModel): Promise<IQuickbooksSettingsModel> {
        return await this.httpService.post("/quickbooksSettings/updateSettings", quickbooksSettingsModel);
    }
}