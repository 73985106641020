import { IItemModel } from "@models/lookups/item.model";

export interface IPayrollLaborCode {
	payrollLaborCodeId: number;
	itemId: number;
	displayName: string;
	overrideOvertime: boolean;
	quickbooksRegularPayrollItem: string;
	quickbooksOvertimePayrollItem: string;
	active: boolean;

	item: IItemModel;
}

export class PayrollLaborCode implements IPayrollLaborCode {
	payrollLaborCodeId: number;
	itemId: number;
	displayName: string;
	overrideOvertime: boolean;
	quickbooksRegularPayrollItem: string;
	quickbooksOvertimePayrollItem: string;
	active: boolean;

	item: IItemModel;
}