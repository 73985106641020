export interface IEstimateLineItemModel {
	estimateLineItemId: number;
	estimateId: number;
	uuid: string;
	itemId?: number;
	qbwcClassId?: number;
	qbwcClassName: string;
	itemCode: string;
	itemDescription: string;
	laborAndMaterials: number;
	labor?: number;
	materials?: number;
	budget: number;
	marginPercent: number;
	cost: number;
	balance: number;
	actualPercent: number;
	showOnInvoice: boolean;
	addToWorkOrder: boolean;
}

export class EstimateLineItemModel implements IEstimateLineItemModel {
	estimateLineItemId: number;
	estimateId: number;
	uuid: string;
	itemId?: number;
	qbwcClassId?: number;
	qbwcClassName: string;
	itemCode: string;
	itemDescription: string;
	laborAndMaterials: number;
	labor?: number;
	materials?: number;
	budget: number;
	marginPercent: number;
	cost: number;
	balance: number;
	actualPercent: number;
	showOnInvoice: boolean;
	addToWorkOrder: boolean;
}