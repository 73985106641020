export interface IServiceAgreementTemplateModel {
	serviceAgreementTemplateId: number;
	serviceAgreementType: string;
	serviceAgreementDescription: string;
	serviceAgreementHTML: string;
	active: boolean;
}

export class ServiceAgreementTemplateModel implements IServiceAgreementTemplateModel {
	constructor() {
		this.serviceAgreementTemplateId = 0;
		this.serviceAgreementHTML = '';
		this.active = true;
	}

	serviceAgreementTemplateId: number;
	serviceAgreementType: string;
	serviceAgreementDescription: string;
	serviceAgreementHTML: string;
	active: boolean;
}