<div *ngIf="contactModel" style="margin-bottom: 10px;">
    <div style="line-height: 1.2em;">
        <h5 *ngIf="header" style="margin-bottom: 0;">
            <div style="float: right">
                <i *ngIf="canEditContact" class="far fa-pencil" style="cursor: pointer;" (click)="editContact()"></i>
                <i *ngIf="canRemoveContact" class="far fa-trash ms-1" style="cursor: pointer; font-size: 14px;" (click)="removeContact()"></i>
            </div>
            {{header}}
        </h5>
        <span *ngIf="contactModel.companyName && contactModel.companyName !== header">{{contactModel.companyName}}<br /></span>
        <span *ngIf="contactModel.fullName">{{contactModel.fullName}}<br /></span>
        <span *ngIf="hideAddress === false && contactModel?.defaultAddress?.address1">{{contactModel?.defaultAddress?.address1}}<br /></span>
        <span *ngIf="contactModel.defaultAddress?.address2">{{contactModel.defaultAddress?.address2}}<br /></span>
        <div *ngIf="hideAddress === false && contactModel.defaultAddress?.cityStatePostal">{{contactModel.defaultAddress?.cityStatePostal}}</div>
        <div style="margin-bottom: 5px;"></div>
        <span *ngIf="contactModel.defaultCell && contactModel.defaultCell.phoneNumber">Cell: {{contactModel.defaultCell.phoneNumber}}<br /></span>
        <span *ngIf="contactModel.defaultAlt1 && contactModel.defaultAlt1.phoneNumber">Alt 1: {{contactModel.defaultAlt1.phoneNumber}}<br /></span>
        <span *ngIf="contactModel.defaultAlt2 && contactModel.defaultAlt2.phoneNumber">Alt 2: {{contactModel.defaultAlt2.phoneNumber}}<br /></span>
        <span *ngIf="contactModel.defaultEmailAddress && contactModel.defaultEmailAddress.emailAddress">Email: {{contactModel.defaultEmailAddress?.emailAddress}}&nbsp;&nbsp;<i class="far fa-clipboard" style="cursor: pointer" title="Copy email addr to clipboard" (click)="emailToClipboard(contactModel.defaultEmailAddress)"></i><br /></span>
        <span *ngIf="contactModel.secondaryEmailAddress && contactModel.secondaryEmailAddress.emailAddress">Email: {{contactModel.secondaryEmailAddress?.emailAddress}}&nbsp;&nbsp;<i class="far fa-clipboard" style="cursor: pointer" title="Copy email addr to clipboard" (click)="emailToClipboard(contactModel.secondaryEmailAddress)"></i><br /></span>
    </div>
</div>

<contact-edit-dialog #contactEditDialogRef [contactModel]="dialogContactModel" (onSave)="onContactSave($event)"></contact-edit-dialog>