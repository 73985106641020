import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { AuthService, UtilsService, NotificationsService, JobsService, QuickbooksService } from "@services";
import { SlickToastService } from "slick-components";
import { JobEditDialogComponent } from "@app/jobs";
import { QuickbooksTaskDisplayDialogComponent } from "@app/quickbooks";
import { INotificationModel, IJobModel } from "@models";
import { MessageQueueDispatcher } from "@message-queue";
import { Subscription } from "rxjs";

@Component({
	selector: "notifications",
	templateUrl: "notifications.component.html",
	styleUrls: ['notifications.component.css'],
	providers: [NotificationsService, SlickToastService, JobsService]
})

export class NotificationsComponent implements OnInit, OnDestroy {
	@ViewChild("jobEditDialogRef", { static: true }) jobEditDialogRef: JobEditDialogComponent;
	@ViewChild("quickbooksTaskDisplayDialog", { static: true }) quickbooksTaskDisplayDialog: QuickbooksTaskDisplayDialogComponent;

	activeNotifications: INotificationModel[] = [];
	showingNotifications = false;
	jobModel: IJobModel;

	addNotification$: Subscription;

	private fnDocumentClick = (e) => this.documentClick(e);

	constructor(private authService: AuthService,
		private notificationsService: NotificationsService,
		private messageQueueDispatcher: MessageQueueDispatcher,
		private slickToastService: SlickToastService,
		private jobsService: JobsService,
		private quickbooksService: QuickbooksService) { }

	async ngOnInit() {
		this.activeNotifications = await this.notificationsService.getActiveNotifications();

		if (this.authService.isAuthenticated === true) {
			this.addNotification$ = this.messageQueueDispatcher.addNotification.subscribe((notification: INotificationModel) => this.addNotification(notification));
		}
	}

	ngOnDestroy() {
		if (this.addNotification$)
			this.addNotification$.unsubscribe();
	}

	addNotification(notificationModel: INotificationModel) {
		this.activeNotifications.unshift(notificationModel);
		this.slickToastService.showInfo(notificationModel.message, 3000);
	}

	showNotifications() {
		if (this.showingNotifications === false) {
			this.showingNotifications = true;
			document.addEventListener("click", this.fnDocumentClick, true);
		}
		else {
			this.hideNotificationWindow();
		}

	}

	async clearAllNotifications() {
		var result = await this.notificationsService.clearAllNotifications();
		if (result === 'Ok') {
			this.activeNotifications = [];
			this.hideNotificationWindow();
		}
	}

	async clearNotification(notification: INotificationModel) {
		var result = await this.notificationsService.clearNotification(notification.notificationId);
		if (result === 'Ok') {
			this.activeNotifications = this.activeNotifications.filter(x => x.notificationId !== notification.notificationId);
			if (this.activeNotifications.length === 0)
				this.hideNotificationWindow();
		}
	}

	async editJob(jobId: number) {
		this.hideNotificationWindow();

		this.jobModel = await this.jobsService.getJob(jobId);

		await this.jobEditDialogRef.showDialog();
	}

	onJobSave() {
		this.slickToastService.showSuccess("Job Updated");
	}

	async viewQuickbooksTask(qbwcTaskId: number) {
		const qbwcTaskModel = await this.quickbooksService.getQBWCTask(qbwcTaskId);
		this.quickbooksTaskDisplayDialog.showDialog(qbwcTaskModel);
	}

	private hideNotificationWindow() {
		document.removeEventListener("click", this.fnDocumentClick, true);
		this.showingNotifications = false;
	}

	documentClick(e) {
		if (UtilsService.checkParentClassExists(e.target, "notification-details"))
			return;

		this.hideNotificationWindow();
	}
}
