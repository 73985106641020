import { ITeamMemberModel } from "@models/lookups/team-member.model";
export interface ICalendarTeamModel {
	teamId: number;
	teamName: string;
	teamMembers: ITeamMemberModel[];
	visible: boolean;
}

export class CalendarTeamModel implements ICalendarTeamModel {
	teamId: number;
	teamName: string;
	teamMembers: ITeamMemberModel[];
	visible: boolean;
}