import { Injectable } from "@angular/core";
import { IEquipmentGridModel, IMitigationAtmosphericReadingModel, IMitigationPlanModel, IMitigationRoomModel, IMitigationRoomStructureModel, IMitigationRoomStructureReadingModel } from '@models';
import { HttpService } from "./utils/http.service";

@Injectable()
export class MitigationService {
	constructor(private httpService: HttpService) {
	}

	async generateMitigationPlanPDF(jobId: number): Promise<string> {
		const pdfStr = await this.httpService.get("/mitigation/generateMitigationPlanPDF", { jobId })

		return pdfStr.url;
	}

	async getMitigationPlan(jobId: number): Promise<IMitigationPlanModel> {
		return this.httpService.get("/mitigation/getMitigationPlan", { jobId })
	}

	async getMitigationRoom(mitigationRoomId: number): Promise<IMitigationRoomModel> {
		return this.httpService.get("/mitigation/getMitigationRoom", { mitigationRoomId })
	}

	async getEquipmentForRoom(mitigationRoomId: number): Promise<IEquipmentGridModel[]> {
		return this.httpService.get("/mitigation/getEquipmentForRoom", { mitigationRoomId })
	}

	async addMitigationRoom(mitigationRoom: IMitigationRoomModel): Promise<IMitigationRoomModel> {
		return this.httpService.post("/mitigation/addMitigationRoom", mitigationRoom);
	}

	async updateMitigationRoom(mitigationRoom: IMitigationRoomModel): Promise<IMitigationRoomModel> {
		return this.httpService.post("/mitigation/updateMitigationRoom", mitigationRoom);
	}

	async deleteRoom(mitigationRoomId: number): Promise<IMitigationRoomModel[]> {
		return this.httpService.delete("/mitigation/deleteRoom", { mitigationRoomId: mitigationRoomId }); 		
	}

	async getMitigationRoomPhotoFolder(mitigationRoomId: number): Promise<string> {
		return this.httpService.get('/mitigation/getMitigationRoomPhotoFolder', { mitigationRoomId: mitigationRoomId });
	}

	async getMitigationAtmosphericReadings(mitigationRoomId: number): Promise<IMitigationAtmosphericReadingModel[]> {
		return this.httpService.get("/mitigation/getMitigationAtmosphericReadings", { mitigationRoomId });
	}

	async getMitigationAtmosphericReading(mitigationAtmosphericReadingId: number): Promise<IMitigationAtmosphericReadingModel> {
		return this.httpService.get("/mitigation/getMitigationAtmosphericReading", { mitigationAtmosphericReadingId });
	}

	async addMitigationAtmosphericReading(mitigationAtmosphericReading: IMitigationAtmosphericReadingModel): Promise<IMitigationAtmosphericReadingModel> {
		return this.httpService.post("/mitigation/addMitigationAtmosphericReading", mitigationAtmosphericReading);
	}

	async updateMitigationAtmosphericReading(mitigationAtmosphericReading: IMitigationAtmosphericReadingModel): Promise<IMitigationAtmosphericReadingModel> {
		return this.httpService.post("/mitigation/updateMitigationAtmosphericReading", mitigationAtmosphericReading);
	}

	async getMitigationRoomStructure(mitigationRoomStructureId: number): Promise<IMitigationRoomStructureModel> {
		return this.httpService.get("/mitigation/getMitigationRoomStructure", { mitigationRoomStructureId });
	}

	async getMitigationRoomStructures(mitigationRoomId: number): Promise<IMitigationRoomStructureModel[]> {
		return this.httpService.get("/mitigation/getMitigationRoomStructures", { mitigationRoomId });
	}

	async addMitigationRoomStructure(mitigationRoomStructureModel: IMitigationRoomStructureModel): Promise<IMitigationRoomStructureModel> {
		return this.httpService.post("/mitigation/addMitigationRoomStructure", mitigationRoomStructureModel);
	}

	async updateMitigationRoomStructure(mitigationRoomStructureModel: IMitigationRoomStructureModel): Promise<IMitigationRoomStructureModel> {
		return this.httpService.post("/mitigation/updateMitigationRoomStructure", mitigationRoomStructureModel);
	}

	async getMitigationRoomStructureReading(mitigationRoomStructureReadingId: number): Promise<IMitigationRoomStructureReadingModel> {
		return this.httpService.get("/mitigation/getMitigationRoomStructureReading", { mitigationRoomStructureReadingId });
	}

	async addMitigationRoomStructureReading(mitigationRoomStructureReadingModel: IMitigationRoomStructureReadingModel): Promise<IMitigationRoomStructureReadingModel> {
		return this.httpService.post("/mitigation/addMitigationRoomStructureReading", mitigationRoomStructureReadingModel);
	}

	async updateMitigationRoomStructureReading(mitigationRoomStructureReadingModel: IMitigationRoomStructureReadingModel): Promise<IMitigationRoomStructureReadingModel> {
		return this.httpService.post("/mitigation/updateMitigationRoomStructureReading", mitigationRoomStructureReadingModel);
	}

	async completeMitigtationPlan(jobId: number): Promise<IMitigationPlanModel> {
		return this.httpService.get("/mitigation/completeMitigationPlan", { jobId: jobId });
	}

}