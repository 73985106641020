<slick-dialog #notAcceptedReasonDialogRef *ngIf="notAcceptedReasons" [width]="width" [top]="200" [showHeader]="false" (onCancel)="onNotAcceptedReasonCancel()">
    <h4>Why was this job not accepted?</h4>
    <div style="text-align: left" class="p-2">
        <div>
            <slick-drop-down [(ngModel)]="notAcceptedReasonId"
                             [items]="notAcceptedReasons"
                             [allowEmpty]="false"
                             placeholder="Select reason..."></slick-drop-down>
        </div>
        <form-error *ngIf="notAcceptedReasonSubmitted && !notAcceptedReasonId">Reason is required</form-error>
    </div>


    <div class="form-group form-save-buttons mt-3">
        <button type="button" class="btn btn-outline-primary" style="width: 120px" (click)="onNotAcceptedReasonSelect()"><i class="far fa-check"></i> Ok</button>
        <button type="button" class="btn btn-outline-danger" style="width: 120px" (click)="onNotAcceptedReasonCancel()"><i class="far fa-ban"></i> Cancel</button>
    </div>

</slick-dialog>
