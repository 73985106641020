<div *ngIf="!isAdmin && !isCorporateAccount" class="sidenav bg-dark text-light" [ngClass]="{ 'is-open' : isMenuExpanded }">

    <div class="pin">
        <button type="button" (click)="toggleExpanded()"><i class="fal fa-thumbtack text-light"></i></button>
    </div>

    <button [routerLink]="['/']" class="bg-transparent border-0"><div class="logo"></div></button>

    <div style="min-height: calc(100vh - 220px)">
        <!-- MENU ITEMS -->
        <ul class="mainmenu">

            <li *ngIf="canAccessJobs">
                <a href='/jobs'
                   [routerLink]="['/jobs']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-clipboard-list"></i>Jobs
                </a>
            </li>


            <li *ngIf="canAccessSchedule">
                <a href='/schedule'
                   [routerLink]="['/schedule']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-calendar-alt"></i>Schedule
                </a>
            </li>

            <li *ngIf="canAccessCustomers">
                <a href='/customers'
                   [routerLink]="['/customers']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-users"></i>Customers
                </a>
            </li>

            <li *ngIf="canAccessBusinessDevelopment">
                <a href='/businessDevelopment'
                   [routerLink]="['/businessDevelopment']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-handshake"></i>Business Dev
                </a>
            </li>

            <li *ngIf="canAccessCommissions">
                <a href='/commissions'
                   [routerLink]="['/commissions']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-badge-percent"></i>Commissions
                </a>
            </li>

            <li *ngIf="canAccessSubcontractor">
                <a href='/subcontractors'
                   [routerLink]="['/subcontractors']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-user-helmet-safety"></i>Subs
                </a>
            </li>

            <li *ngIf="canAccessWorkOrders">
                <a href='/workOrders'
                   [routerLink]="['/workOrders']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-clipboard-list-check"></i>Work Orders
                </a>
            </li>

            <li *ngIf="canAccessVendors">
                <a href='/vendors'
                   [routerLink]="['/vendors']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-user-circle"></i>Vendors
                </a>
            </li>

            <li>
                <a href='/equipment'
                   [routerLink]="['/equipment']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-tags"></i>Equipment
                </a>
            </li>

            <li *ngIf="canAccessInvoices">
                <a href='/invoices'
                   [routerLink]="['/invoices']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-file-invoice"></i>Invoices
                </a>
            </li>

            <li *ngIf="canAccessPayments">
                <a href='/payments'
                   [routerLink]="['/payments']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-money-check-alt"></i>Payments
                </a>
            </li>

            <li *ngIf="canAccessPayables">
                <a href='/payables'
                   [routerLink]="['/payables']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-envelope-open-dollar"></i>Payables
                    <div *ngIf="payablesAwaitingApprovalCount > 0" class="bg-danger text-white px-2 py-1 side-menu-badge">
                        {{payablesAwaitingApprovalCount}}
                    </div>

                </a>
            </li>

            <li *ngIf="canAccessTimeclock">
                <a href='/timeclock'
                   [routerLink]="['/timeclock']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-clock"></i>Timeclock
                </a>
            </li>

            <li *ngIf="canAccessInventory">
                <a href='/inventory'
                   [routerLink]="['/inventory']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-shelves"></i>Inventory
                </a>
            </li>

            <li *ngIf="useQuickbooksDesktop && canAccessQuickbooks">
                <a href="/quickbooks" [routerLink]="['/quickbooks']"
                   routerLinkActive="bg-primary text-white">
                    <div class="quickbooks">
                        <span class="quickbooks-icon">K</span><span class="quickbooks-text">Quickbooks</span>
                    </div>
                </a>
            </li>

            <li *ngIf="useXactware">
                <a href="/xactware" [routerLink]="['/xactware']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-exchange"></i>Xactware
                    <div *ngIf="xactAssignmentCount > 0" class="bg-danger text-white px-2 py-1 side-menu-badge">
                        {{xactAssignmentCount}}
                    </div>
                </a>
            </li>

            <li *ngIf="canAccessTasks">
                <a href='/tasks'
                   [routerLink]="['/tasks']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-list-check"></i>Tasks
                    <div *ngIf="taskCount > 0" class="bg-danger text-white px-2 py-1 side-menu-badge">
                        {{taskCount}}
                    </div>

                </a>
            </li>

            <li *ngIf="canAccessEmailLogs">
                <a href="/emailLogs" [routerLink]="['/emailLogs']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-envelope"></i>Email Logs
                </a>
            </li>

            <li *ngIf="canAccessReporting">
                <a href="/reporting" [routerLink]="['/reporting']"
                   routerLinkActive="bg-primary text-white">
                    <div class="reporting">
                        <i class="fal fa-chart-pie"></i>Reporting
                    </div>
                </a>
            </li>

            <li *ngIf="canAccessTextChat">
                <a href='/text-chat'
                   [routerLink]="['/text-chat']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-messages"></i>Text Chat
                    <div *ngIf="textChatUnreadMessagesCount > 0" class="bg-danger text-white px-2 py-1 side-menu-badge">
                        {{textChatUnreadMessagesCount}}
                    </div>

                </a>
            </li>

            <li *ngIf="canAccessUsers">
                <a href='/users'
                   [routerLink]="['/users']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-user-tie"></i>Users
                </a>
            </li>

            <li *ngIf="canAccessSetup">
                <a href='/setup'
                   [routerLink]="['/setup']"
                   routerLinkActive="bg-primary text-white">
                    <i class="fal fa-cogs"></i>Setup
                </a>
            </li>

            <li>
                <a href='javascript:void()' (click)="logout()">
                    <i class="fal fa-sign-out"></i>Sign Out
                </a>
            </li>
        </ul>
    </div>
    <div [ngClass]="{ 'support-center-expanded' : isMenuExpanded, 'support-center-collapsed' : !isMenuExpanded }" class="border-top pt-2">
        <ul class="mainmenu">
            <li>
                <a href='https://support.jobsight.com/support-center' target="_blank">
                    <i class="fad fa-info-circle"></i>Support Center
                </a>
            </li>

        </ul>
    </div>

</div>

<div *ngIf="isAdmin" class="sidenav bg-dark text-light" [ngClass]="{ 'is-open' : isMenuExpanded }">

    <div class="pin">
        <button type="button" (click)="toggleExpanded()"><i class="fal fa-thumbtack text-light"></i></button>
    </div>

    <button [routerLink]="['/']" class="bg-transparent border-0"><div class="logo"></div></button>

    <!-- MENU ITEMS -->
    <ul class="mainmenu">
        <li *ngIf="canAccessGlobalAdmin">
            <a href='/globalAdmin'
               [routerLink]="['/globalAdmin']"
               routerLinkActive="bg-primary text-white">
                <i class="fal fa-burst"></i>Global Admin
            </a>
        </li>

        <li>
            <a href='javascript:void()' (click)="logout()">
                <i class="fal fa-sign-out"></i>Sign Out
            </a>
        </li>
    </ul>
</div>

<div *ngIf="isCorporateAccount" class="sidenav bg-dark text-light" [ngClass]="{ 'is-open' : isMenuExpanded }">

    <div class="pin">
        <button type="button" (click)="toggleExpanded()"><i class="fal fa-thumbtack text-light"></i></button>
    </div>

    <button [routerLink]="['/']" class="bg-transparent border-0"><div class="logo"></div></button>

    <!-- MENU ITEMS -->
    <ul class="mainmenu">
        <li>
            <a href='/corporate-view'
               [routerLink]="['/corporate-view']"
               routerLinkActive="bg-primary text-white">
                <i class="fad fa-building"></i>Overview
            </a>
        </li>

        <li>
            <a href='/reporting'
               [routerLink]="['/reporting']"
               routerLinkActive="bg-primary text-white">
                <i class="fad fa-pie-chart"></i>Reports
            </a>
        </li>

        <li class="mt-3">
            <a href='javascript:void()' (click)="logout()">
                <i class="fal fa-sign-out"></i>Sign Out
            </a>
        </li>
    </ul>
</div>