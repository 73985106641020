import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TaskListBase } from "@base-components/task-list.base"
import { TasksService } from "@services";
import { TasksStore } from "@stores";
import { TaskEditDialogComponent } from "@app/tasks/tasks-components";
import { ITaskModel } from "@models";

@Component({
	selector: "task-list",
	templateUrl: "task-list.component.html"
})
export class TaskListComponent extends TaskListBase {	
	@Output("hideTaskWindow") hideTaskWindow: EventEmitter<void> = new EventEmitter();
	
	@ViewChild("taskEditDialogRef") taskEditDialogRef: TaskEditDialogComponent;
	constructor(tasksService: TasksService,
		tasksStore: TasksStore) {

		super(tasksService, tasksStore);

	}

	async openTask(taskModel: ITaskModel) {
		this.hideTaskWindow.emit();

		const returnedTask = await this.taskEditDialogRef.showDialog(taskModel);

		if (returnedTask)
			this.tasksStore.updateTask(returnedTask);
	}
}
