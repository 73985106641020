export interface IPhoneNumberModel {
	phoneNumberId: number;
	uuid: string;
	phoneNumberTypeId: number;
	phoneNumberTypeName: string;
	phoneNumber: string;
	isDefault: boolean;
	active: boolean;
}

export class PhoneNumberModel implements IPhoneNumberModel {
	phoneNumberId: number;
	uuid: string;
	phoneNumberTypeId: number;
	phoneNumberTypeName: string;
	phoneNumber: string;
	isDefault: boolean;
	active: boolean;
}
