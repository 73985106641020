export interface IMitigationAtmosphericReadingModel {
	mitigationAtmosphericReadingId: number;
	mitigationRoomId: number;
	date: Date;
	recordedBy: string;
	rh: number;
	temp: number;
	gpp: number;
	dehuRH?: number;
	dehuTemp?: number;
	dehuGPP?: number;
}

export class MitigationAtmosphericReadingModel implements IMitigationAtmosphericReadingModel {
	constructor() {
		this.mitigationAtmosphericReadingId = 0;
		this.rh = null;
		this.temp = null;
		this.gpp = null;
		this.dehuRH = null;
		this.dehuTemp = null;
		this.dehuGPP = null;
	}
	mitigationAtmosphericReadingId: number;
	mitigationRoomId: number;
	date: Date;
	recordedBy: string;
	rh: number;
	temp: number;
	gpp: number;
	dehuRH?: number;
	dehuTemp?: number;
	dehuGPP?: number;
}
