import { NgModule } from '@angular/core';
import { FunctionLockService } from "@services";
import { SlickToastModule } from 'slick-components';
//import { File } from "@ionic-native/file/ngx";
//import { FileTransfer } from "@ionic-native/file-transfer/ngx";


import { LoggerStore } from "./logger.store";
import { PayablesStore } from "./payables.store";
import { PhotoUploadStore } from "./photo-upload.store";
import { TextChatStore } from "./text-chat.store";
import { TimeclockStore } from "./timeclock.store";
import { TasksStore } from "./tasks.store";
import { XactwareStore } from './xactware.store';

@NgModule({
	providers: [LoggerStore,
		PayablesStore,
		PhotoUploadStore,
		TextChatStore,
		TimeclockStore,
		TasksStore,
		XactwareStore,
		SlickToastModule, FunctionLockService]
})
export class StoresModule { }
