import { Injectable } from "@angular/core";
import { IAppointmentModel, AppointmentModel, IAppointmentSummaryModel, IAppointmentConflictModel, IAppointmentReminderDefaultModel, IAppointmentReminderModel, AppointmentReminderModel } from "@models";
import { UtilsService } from "./utils/utils.service";
import { HttpService } from "./utils/http.service";
import { LookupService } from './utils/lookup.service';
import * as moment from "moment";

@Injectable({
	providedIn: 'root'
})
export class AppointmentsService {
	constructor(private httpService: HttpService, private lookupService: LookupService) {
	}

	getNewAppointmentModel(): IAppointmentModel {
		let newAppointmentModel: IAppointmentModel = new AppointmentModel();
		newAppointmentModel.appointmentId = 0;
		newAppointmentModel.uuid = UtilsService.newGuid();
		newAppointmentModel.appointmentGroupUuid = UtilsService.newGuid();
		newAppointmentModel.isOnClipboard = false;
		newAppointmentModel.isPending = false;
		newAppointmentModel.sendEmailReminder = false;
		newAppointmentModel.sendTextReminder = false;
		newAppointmentModel.startDate = moment().startOf("day").add(8, "hours").toDate();
		newAppointmentModel.endDate = moment().startOf("day").add(9, "hours").toDate();
		newAppointmentModel.duration = 60;
		newAppointmentModel.isTimeBlock = false;
		newAppointmentModel.employeeOrSubcontractor = "E";
		newAppointmentModel.multiDayDates = [];
		newAppointmentModel.resources = [];
		newAppointmentModel.laborCodes = [];
		newAppointmentModel.appointmentReminders = [];

		return newAppointmentModel;
	}

	async getAppointment(appointmentId: number): Promise<IAppointmentModel> {
		try {
			return await this.httpService.get("/appointments/getAppointment", { appointmentId: appointmentId })
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getAppointmentsForDateRange(startDate: Date, endDate: Date, forSubcontractors: boolean = false): Promise<IAppointmentModel[]> {
		try {
			let params = {
				startDate: moment(startDate).format("MM/DD/YYYY"),
				endDate: moment(endDate).format("MM/DD/YYYY"),
				forSubcontractors: forSubcontractors
			}

			return await this.httpService.get("/appointments/getAppointmentsForDateRange", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getUserAppointmentsForDateRange(userId: number, startDate: Date, endDate: Date): Promise<IAppointmentModel[]> {
		try {
			let params = {
				startDate: moment(startDate).format("MM/DD/YYYY"),
				endDate: moment(endDate).format("MM/DD/YYYY"),
				userId: userId
			}

			return await this.httpService.get("/appointments/getUserAppointmentsForDateRange", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}
	async getAppointmentSummary(appointmentId: number): Promise<IAppointmentSummaryModel> {
		try {
			return await this.httpService.get("/appointments/getAppointmentSummary", { appointmentId })
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getAppointmentSummariesForDateRange(startDate: Date, endDate: Date): Promise<IAppointmentSummaryModel[]> {
		try {
			let params = {
				startDate: moment(startDate).format("MM/DD/YYYY"),
				endDate: moment(endDate).format("MM/DD/YYYY")
			}

			return await this.httpService.get("/appointments/getAppointmentSummariesForDateRange", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getUserAppointmentSummariesForDateRange(userId: number, startDate: Date, endDate: Date): Promise<IAppointmentSummaryModel[]> {
		try {
			if (!userId)
				return [];

			let params = {
				startDate: moment(startDate).format("MM/DD/YYYY"),
				endDate: moment(endDate).format("MM/DD/YYYY"),
				userId: userId
			}

			return await this.httpService.get("/appointments/getUserAppointmentSummariesForDateRange", params);

		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}


	async getClipboardAppointmentsForCurrentUser(): Promise<IAppointmentModel[]> {
		try {
			return await this.httpService.get("/appointments/getClipboardAppointmentsForCurrentUser");
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getAppointmentsByGroup(appointmentGroupUuid): Promise<IAppointmentModel[]> {
		try {
			return await this.httpService.get("/appointments/getAppointmentsByGroup", { appointmentGroupUuid: appointmentGroupUuid });
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getAppointmentsByGroupForDateRange(appointmentGroupUuid, startDate, endDate): Promise<IAppointmentModel[]> {
		try {
			let params = {
				appointmentGroupUuid: appointmentGroupUuid,
				startDate: moment(startDate).format("MM/DD/YYYY"),
				endDate: moment(endDate).format("MM/DD/YYYY")
			}

			return await this.httpService.get("/appointments/getAppointmentsByGroupForDateRange", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async adjustAppointmentsInGroup(appointmentIds: number[], daysToAdjust: number): Promise<IAppointmentModel[]> {
		try {
			let params = {
				appointmentIds: appointmentIds,
				daysToAdjust: daysToAdjust
			}

			return await this.httpService.post("/appointments/adjustAppointmentsInGroup", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async updateAppointment(appointmentModel: IAppointmentModel): Promise<IAppointmentModel> {
		try {
			if (appointmentModel.appointmentId === 0) {
				return await this.httpService.post("/appointments/addAppointment", appointmentModel);
			}
			else {
				return await this.httpService.post("/appointments/updateAppointment", appointmentModel);
			}
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async deleteAppointment(appointmentModel: IAppointmentModel): Promise<boolean> {
		try {
			const params = {
				appointmentId: appointmentModel.appointmentId
			}

			return await this.httpService.delete("/appointments/deleteAppointment", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async checkForConflicts(appointmentModel: IAppointmentModel): Promise<IAppointmentConflictModel[]> {
		try {
			return await await this.httpService.post("/appointments/checkForConflicts", appointmentModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async mergeAppointmentReminderDefaults(appointment: IAppointmentModel): Promise<IAppointmentReminderModel[]> {
		const apptReminderDefaults = await this.lookupService.getAppointmentReminderDefaults();
		const apptReminders: IAppointmentReminderModel[] = [];

		// Make sure the array isn't null
		apptReminderDefaults.forEach(x => {
			let apptReminder = appointment.appointmentReminders.find(r => r.appointmentReminderDefaultId === x.appointmentReminderDefaultId);
			if (apptReminder) {
				apptReminder.isChecked = true;
			}
			else {
				apptReminder = new AppointmentReminderModel();
				apptReminder.appointmentReminderDefaultId = x.appointmentReminderDefaultId;
				apptReminder.reminderText = x.reminderText;
				apptReminder.isChecked = false;
			}

			apptReminders.push(apptReminder)
		});

		if (appointment.appointmentReminders)
			apptReminders.concat(appointment.appointmentReminders.filter(x => x.appointmentReminderDefaultId === null));

		return apptReminders;
	}
}