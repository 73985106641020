<div *ngIf="customerModel" class="form-horizontal m-3">
	<contact-edit #contactEditRef [contactModel]="customerModel.contact"
				  [showQuickbooksName]="true"
				  showButtons="false"></contact-edit>


	<div class="mt-2" style="width: 280px">
		<label>Default Payment Term</label>
		<slick-drop-down [ngModel]="customerModel.paymentTermId"
						 [items]="paymentTerms"
						 (onSelect)="onPaymentTermSelected($event)">
		</slick-drop-down>
	</div>
	<div class="mt-2">
		<input type="checkbox" [(ngModel)]="customerModel.textOptIn" /> Texting Opt-In
	</div>

	<div class="row" *ngIf="showButtons">
		<div class="col-12 form-save-buttons">
			<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary save-button" (click)="onSaveContact()"><i class="far fa-save"></i>&nbsp;Save</button>
			<button type="button" class="btn btn-outline-danger cancel-button" (click)="onCancelContact()"><i class="far fa-ban"></i>&nbsp;Cancel</button>
		</div>
	</div>
</div>
<concurrency-error-dialog #concurrencyErrorDialogRef></concurrency-error-dialog>

