import { Injectable, OnInit } from "@angular/core";
import { GlobalsService } from "@services";
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import { MessageQueueDispatcher } from "./message-queue.dispatcher";
import { LoggerStore } from "@stores";
import { MessageQueueFeatureTypes } from "./message-queue-feature-types.model";

@Injectable()
export class MessageQueueHubService {
    private static hubConnection: HubConnection = null;

    constructor(private messageQueueDispatcher: MessageQueueDispatcher,
        private readonly loggerStore: LoggerStore) { }

    private startConnection() {
        this.loggerStore.addLog(`MessageQueueHub start connection (State: ${MessageQueueHubService.hubConnection.state})`);
        if (MessageQueueHubService.hubConnection.state === HubConnectionState.Connected) {
            this.loggerStore.addLog("MessageQueueHub exiting because connected");
            return;
        }

        MessageQueueHubService.hubConnection
            .start()
            .then(() => {
                this.loggerStore.addLog("MessageQueueHub connected");
            })
            .catch(err => {
                setTimeout(() => {
                    if (MessageQueueHubService.hubConnection.state === HubConnectionState.Disconnected)
                        this.startConnection();
                }, 5000);
            });
    }

    init() {
        //if (GlobalsService.isDebug)
        //    return;

        if (MessageQueueHubService.hubConnection === null) {
            MessageQueueHubService.hubConnection = new HubConnectionBuilder()
                .withUrl(GlobalsService.apiUrl + "/messageQueueHub", { accessTokenFactory: () => GlobalsService.jwtToken })
                .build();

            MessageQueueHubService.hubConnection.on('SendMessage', (featureType: MessageQueueFeatureTypes, action: string, data: any) => this.messageQueueDispatcher.onSendMessage(featureType, action, data));

            // detect if the connection is lost and reconnect
            MessageQueueHubService.hubConnection.onclose(() => {
                this.loggerStore.addLog("MessageQueueHub onclose");
                setTimeout(() => {
                    if (MessageQueueHubService.hubConnection.state === HubConnectionState.Disconnected)
                        this.startConnection();
                }, 10000);
            });

            this.startConnection();
        }
    }
}