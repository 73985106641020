import { IAppointmentModel } from "./appointment.model";

export interface IAppointmentPopupModel {
	appointmentModel: IAppointmentModel;
	resources: string;
	laborCodes: string
	top: number;
	left: number;
}

export class AppointmentPopupModel implements IAppointmentPopupModel {
	appointmentModel: IAppointmentModel;
	resources: string;
	laborCodes: string
	top: number;
	left: number;
}