import { Component, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { SlickEmailerDialogComponent } from "slick-components";
import { ISlickEmailerModel, SlickEmailerModel, ISlickEmailerAddressModel, ISlickFileModel, SlickFileModel} from "slick-components";
import { IEmailerModel, EmailerModel, IEmailerAddressModel } from "@models";
import { UtilsService, LookupService, SleepService, HttpService } from "@services";

@Component({
	selector: "emailer-dialog",
	template: `
	<slick-emailer-dialog *ngIf='emailerModel' #emailerDialogRef [(ngModel)]='emailerModel'
		[toEmailAddressList]='toEmailAddresses' 
		(toAddressSearch)='toAddressSearch($event)'
		(sendEmailClick)='onSendEmail($event)'
		(cancelEmailClick)='onCancelEmail()'>
	</slick-emailer-dialog>`,
	providers: []
})
export class EmailerDialogComponent {
	@Output("sendEmailClick") sendEmailClickEmitter: EventEmitter<ISlickEmailerModel> = new EventEmitter();
	@Output("cancelEmailClick") cancelEmailClickEmitter: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("emailerDialogRef") emailerDialogRef: SlickEmailerDialogComponent;

	emailerModel: ISlickEmailerModel;	
	toEmailAddresses: IEmailerAddressModel[] = [];
	additionalEmailAddresses: IEmailerAddressModel[];

	constructor(private httpService: HttpService, private lookupService: LookupService, private changeDetector: ChangeDetectorRef) {
	}

	async showDialog(emailerModel: IEmailerModel = null, additionalEmailAddresses: IEmailerAddressModel[] = null) {		
		this.emailerModel = (emailerModel) ? emailerModel : new SlickEmailerModel();
		this.emailerModel.uuid = (this.emailerModel.uuid) ? this.emailerModel.uuid : UtilsService.newGuid();
		this.additionalEmailAddresses = additionalEmailAddresses || [];
		this.changeDetector.detectChanges();

		await SleepService.sleep();
		this.emailerDialogRef.showDialog();
	}

	async toAddressSearch(searchText) {
		this.toEmailAddresses = await this.findEmailAddress(searchText);
	}

	async onSendEmail(emailerModel: IEmailerModel) {
		try {
			await SleepService.sleep(500);

			const formData: FormData = new FormData();
			emailerModel.attachments.forEach(file => {
				if (file.base64Image && file.base64Image.length > 0) {
					let fileCopy: ISlickFileModel = UtilsService.clone(file);
					let fileName = file.name;
					delete fileCopy.file;
					delete fileCopy.thumbnailBase64Image;
					let fileJSON = JSON.stringify(fileCopy);
					formData.append('image_' + file.uuid, fileJSON);
				}
				else if (file.file) {
					formData.append('file', file.file, file.file.name);
				}
			})

			// Clean up the model and send it
			let emailerModelTemp: IEmailerModel = UtilsService.clone(emailerModel);
			emailerModelTemp.attachments = emailerModelTemp.attachments.map(att => {
				delete att.file;
				delete att.base64Image;
				delete att.thumbnailBase64Image;
				return att;
			});
			formData.append("emailerModel", JSON.stringify(emailerModelTemp));

			await this.httpService.postMultipart("/emailer/sendEmail", formData);
			this.emailerDialogRef.emailSent();
			if (this.sendEmailClickEmitter)
				this.sendEmailClickEmitter.emit(this.emailerModel);
		}
		catch (err) {
			console.error(err);
			this.emailerDialogRef.emailError();
		}
	}

	onCancelEmail() {
		if (this.cancelEmailClickEmitter)
			this.cancelEmailClickEmitter.emit();
	}

	private async findEmailAddress(searchText): Promise<ISlickEmailerAddressModel[]> {
		let returnAddresses: ISlickEmailerAddressModel[];
		let emailerAddresses = [...await this.lookupService.getEmailerAddresses()]
		if (this.additionalEmailAddresses)
			emailerAddresses = [...emailerAddresses, ...this.additionalEmailAddresses];


		let filteredEmailAddresses: IEmailerAddressModel[] = emailerAddresses.filter(x => x.emailAddress?.toLowerCase().startsWith(searchText?.toLowerCase()));
		filteredEmailAddresses = filteredEmailAddresses.concat(emailerAddresses.filter(x => x.displayName?.toLowerCase().startsWith(searchText?.toLowerCase())));

		returnAddresses = [];
		filteredEmailAddresses.forEach(filteredEmailAddress => {
			if (returnAddresses.findIndex(returnAddress => (returnAddress.contactId === filteredEmailAddress.contactId) && (returnAddress.emailAddress?.toLowerCase() === filteredEmailAddress.emailAddress?.toLowerCase())) === -1) 
				returnAddresses.push(filteredEmailAddress);
		});

		returnAddresses = returnAddresses.sort((a: IEmailerAddressModel, b: IEmailerAddressModel) => {
			let aSortText = (a.displayName + " [" + a.emailAddress + "]").toLowerCase();
			let bSortText = (b.displayName + " [" + b.emailAddress + "]").toLowerCase();
			if (aSortText < bSortText) 
				return -1;
			else if (aSortText > bSortText) 
				return 1;
			else 
				return 0;			
		})

		return returnAddresses;
	}
}
