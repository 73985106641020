import { Directive, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ITaskModel, TaskModel } from "@models";
import { SleepService, TasksService, UtilsService } from "@services";
import { TasksStore } from "@stores";
import { Subscription } from "rxjs";

@Directive()
export class TaskListBase implements OnChanges, OnInit, OnDestroy {
	@Input() jobId: number;

	tasks: ITaskModel[] = [];
	quickTaskText: string;

	refreshTasks$: Subscription;

	constructor(protected tasksService: TasksService,
		protected tasksStore: TasksStore) {
	}

	async ngOnInit() {
		this.refreshTasks$ = this.tasksStore.refreshTasks$.subscribe(() => this.refreshTasks());

		this.refreshTasks();
	}

	ngOnDestroy() {
		this.refreshTasks$.unsubscribe();
	}

	ngOnChanges() {
		this.refreshTasks();
	}

	async onKeyDown(e: KeyboardEvent) {
		if (e.key === "Enter" || e.key === "Tab") {
			await SleepService.sleep();

			this.quickTaskText = (this.quickTaskText || '').trim();
			if (!this.quickTaskText)
				return;

			let newQuickTask = new TaskModel();
			newQuickTask.title = this.quickTaskText;
			this.quickTaskText = null;
			newQuickTask = await this.tasksService.addTask(newQuickTask);
			this.tasksStore.updateTask(newQuickTask);
		}
			
	}

	async completeTask(e: MouseEvent, taskModel: ITaskModel) {
		e.preventDefault();
		e.stopPropagation();

		taskModel.isCompleting = true;

		const taskToUpdate = UtilsService.clone(taskModel);
		taskToUpdate.isCompleted = true;
		await SleepService.sleep(500);

		const updatedTask = await this.tasksService.updateTask(taskToUpdate);
		taskModel.isCompleting = false;
		taskModel.isCompleted = true;

		await SleepService.sleep(750);
		this.tasksStore.removeTask(updatedTask.taskId);

	}

	private refreshTasks() {
		this.tasks = this.tasksStore.getActiveTasks();

		if (this.jobId)
			this.tasks = this.tasks.filter(x => x.jobId === this.jobId);

	}
}
