<div class="d-flex">
    <div *ngIf="hideDate === false" class="input-group me-1" style="width: 160px;">
        <span class="input-group-text"><span class="far fa-calendar"></span></span>
        <input type="text" class="form-control" name="date" slick-date-picker [ngModel]="date" (onDateChange)="onDateChange($event)" autocomplete="off" />
    </div>
    <div class="input-group me-1" style="width: 140px;">
        <span class="input-group-text" (click)="focusTime()"><span class="far fa-clock"></span></span>
        <input #timeInputRef type="text" class="form-control" [(ngModel)]="time" (change)="onTimeChange()" autocomplete="off" />
    </div>
</div>
<form-error *ngIf="isValid === false">Invalid entry</form-error>
