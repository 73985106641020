import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UtilsService } from "./utils.service";
import { Observable } from "rxjs";
import { FunctionLockService } from "./function-lock.service";

@Injectable()
export class HttpService {
	constructor(private http: HttpClient,
		private functionLockService: FunctionLockService) { }

	async get(url: string, params?: any): Promise<any> {
		const urlKey = new URL(`https://${url}`).pathname;
		if (this.functionLockService.getLockCount(urlKey) > 0)
			console.info(`Awaiting previous http calls for ${url} ${JSON.stringify(params)}`);		

		await this.functionLockService.lock(urlKey);

		try {
			let responseObj = await this.http.get(url, { params: params }).toPromise();

			responseObj = UtilsService.dateSanitize(responseObj);

			return responseObj;
		}
		finally {
			this.functionLockService.release(urlKey);
		}
	}

	getObservable(url: string, params?: any): Observable<any> {
		return this.http.get(url, { params: params });
	}

	async getPdf(docPath: string, title: string): Promise<Blob> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/pdf');

		const url = `documents/getPDF?docPath=${docPath}&title=${title}`;
		return this.http.get(url, { headers: headers, responseType: "blob" }).toPromise();
	}

	async delete(url: string, params?: any): Promise<any> {
		let responseObj = await this.http.delete(url, { params: params }).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async post(url: string, body: any): Promise<any> {
		// Trim all strings
		let jsonString = JSON.stringify(body);
		let infiniteLoopDetector = 100;
		while ((jsonString.indexOf("\" ") >= 0) && --infiniteLoopDetector > 0)
			jsonString = jsonString.replace("\" ", "\"");

		infiniteLoopDetector = 100;
		while ((jsonString.indexOf(" \"") >= 0) && --infiniteLoopDetector > 0)
			jsonString = jsonString.replace(" \"", "\"");

		body = JSON.parse(jsonString);

		let responseObj = await this.http.post(url, body).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async postMultipart(url: string, formData: FormData): Promise<any> {
		let responseObj = await this.http.post(url, formData,
			{
				headers: { 'Content-Type': "multipart/form-data" }
			}).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async put(url: string, body: any = null): Promise<any> {
		let responseObj = await this.http.put(url, body).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async patch(url: string, body: any = null): Promise<any> {
		let responseObj = await this.http.patch(url, body).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}
}
