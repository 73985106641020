import { Injectable } from "@angular/core";
import { ICertificationModel, IEmployeeCertificationModel } from "@models";
import { HttpService } from "./utils/http.service";

@Injectable()
export class CertificationsService {
	constructor(private httpService: HttpService) {
	}

	async getCertifications(): Promise<ICertificationModel[]> {
		return this.httpService.get("/certifications/getCertifications");
	}

	async updateCertifications(certificationModels: ICertificationModel[]): Promise<ICertificationModel[]> {
		return this.httpService.post("/certifications/updateCertifications", certificationModels);
	}

	async getEmployeeCertifications(employeeId: number): Promise<IEmployeeCertificationModel[]> {
		return this.httpService.get("/certifications/getEmployeeCertifications", { employeeId });
	}

	async updateEmployeeCertification(employeeCertification: IEmployeeCertificationModel): Promise<IEmployeeCertificationModel> {
		return this.httpService.post("/certifications/updateEmployeeCertification", employeeCertification);
	}
}

