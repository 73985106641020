export interface IPayableLineItemModel {
	payableLineItemId: number;
	uuid: string;
	cogsItemId?: number;
	expenseItemId?: number;
	qbwcClassId?: number;
	qbwcClassName: string;
	estimateLineItemId?: number;
	cogsItemCode: string;
	cogsItemDescription: string;
	expenseItemCode: string;
	expenseItemDescription: string;
	amount: number;
}

export class PayableLineItemModel implements IPayableLineItemModel {
	payableLineItemId: number;
	uuid: string;
	cogsItemId?: number;
	expenseItemId?: number;
	qbwcClassId?: number;
	qbwcClassName: string;
	estimateLineItemId?: number;
	cogsItemCode: string;
	cogsItemDescription: string;
	expenseItemCode: string;
	expenseItemDescription: string;
	amount: number;
}