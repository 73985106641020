export interface IPaymentTypeModel {
	paymentTypeId: number;
	name: string;
	active: boolean;
	isSystemType: boolean;
}

export class PaymentTypeModel implements IPaymentTypeModel {
	paymentTypeId: number;
	name: string;
	active: boolean;
	isSystemType: boolean;
}