import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { UtilsService } from "./utils/utils.service";
import { LookupService } from "./utils/lookup.service";
import { ContactsService } from "./contacts.service";
import { IDropdownModel, ISubcontractorSearchListModel, ISubcontractorGridModel, ISubcontractorModel, SubcontractorModel, ISubcontractorNoteModel, DropdownModel } from "@models";

@Injectable()
export class SubcontractorsService {
	constructor(private httpService: HttpService,
		private lookupService: LookupService,
		private contactsService: ContactsService) { }

	getNewSubcontractorModel(): ISubcontractorModel {
		var subcontractorModel: ISubcontractorModel = new SubcontractorModel();
		subcontractorModel.subcontractorId = 0;
		subcontractorModel.uuid = UtilsService.newGuid();
		subcontractorModel.version = 0;
		subcontractorModel.contact = this.contactsService.getNewContactModel();

		return subcontractorModel;
	}

	async getSubcontractor(subcontractorId: number): Promise<ISubcontractorModel> {
		return this.httpService.get("/subcontractors/getSubcontractor", { subcontractorId: subcontractorId });
	}

	async getSubcontractorsForSchedule(): Promise<ISubcontractorModel[]> {
		return this.httpService.get("/subcontractors/getSubcontractorsForSchedule");
	}

	async getSubcontractorSearchListModel(subcontractorId: number): Promise<ISubcontractorSearchListModel> {
		return this.httpService.get("/subcontractors/getSubcontractorSearchListModel", { subcontractorId: subcontractorId });
	}

	async getSubcontractorsForInfiniteScroll(pageCount: number, searchString: string = ""): Promise<ISubcontractorGridModel[]> {
		const params = {
			pageCount: pageCount,
			searchString: searchString
		}

		return this.httpService.get("/subcontractors/getSubcontractorsForInfiniteScroll", params);
	}

	async getSubcontractorsForJob(jobId: number): Promise<ISubcontractorModel[]> {
		return this.httpService.get("/subcontractors/getSubcontractorsForJob", { jobId: jobId });
	}

	async updateSubcontractor(subcontractorModel: ISubcontractorModel): Promise<ISubcontractorModel> {
		if (subcontractorModel.subcontractorId === 0) {
			let returnModel = await this.httpService.post("/subcontractors/addSubcontractor", subcontractorModel);
			this.lookupService.refreshEstimators();
			this.lookupService.refreshEmailerAddresses();
			return Promise.resolve(returnModel);
		}
		else {
			let returnModel = await this.httpService.post("/subcontractors/updateSubcontractor", subcontractorModel)
			this.lookupService.refreshEstimators();
			this.lookupService.refreshEmailerAddresses();
			return Promise.resolve(returnModel);
		}
	}

	async deleteSubcontractor(subcontractorId: number): Promise<boolean> {
		return this.httpService.delete("/subcontractors/deleteSubcontractor", { subcontractorId: subcontractorId });
	}

	async getSubcontractorUserNotes(subcontractorId: number, count: number): Promise<ISubcontractorNoteModel[]> {
		let params = {
			subcontractorId: subcontractorId,
			count: count
		}
		return this.httpService.get("/subcontractors/getSubcontractorUserNotes", params);
	}

	async getSubcontractorSystemNotes(subcontractorId: number, count: number): Promise<ISubcontractorNoteModel[]> {
		let params = {
			subcontractorId: subcontractorId,
			count: count
		}
		return this.httpService.get("/subcontractors/getSubcontractorSystemNotes", params);
	}

	async getSubcontractorSearchResults(searchText: string): Promise<ISubcontractorSearchListModel[]> {
		const params = {
			searchText: searchText
		}

		return this.httpService.get("/subcontractors/getSubcontractorSearchResults", params);
	}

	async getSubcontractorForGrid(subcontractorId: number): Promise<any> {
		return this.httpService.get("/subcontractors/getSubcontractorForGrid", { subcontractorId: subcontractorId });
	}

	async syncToQuickbooks(subcontractorId: number): Promise<ISubcontractorGridModel> {
		return this.httpService.post("/subcontractors/syncToQuickbooks", subcontractorId);
	}

	async unsyncFromQuickbooks(subcontractorId: number): Promise<ISubcontractorGridModel> {
		return this.httpService.post("/subcontractors/unsyncFromQuickbooks", subcontractorId);
	}

	async isUserNameAvailable(subcontractorId: number, userName: string): Promise<boolean> {
		const params = {
			subcontractorId,
			userName
		}

		return this.httpService.get("/subcontractors/isUserNameAvailable", params);
	}

	async updatePassword(subcontractorId: number, password: string): Promise<boolean> {
		const params = {
			userId: subcontractorId,
			password: password
		}

		return this.httpService.patch("/subcontractors/updatePassword", params);
	}
}
