export interface IEmailAddressModel {
	emailAddressId: number;
	uuid: string;
	emailAddressTypeId: number;
	emailAddressTypeName: string;
	emailAddress: string;
	isDefault: boolean;
	isSecondary: boolean;
	active: boolean;
}

export class EmailAddressModel implements IEmailAddressModel {
	emailAddressId: number;
	uuid: string;
	emailAddressTypeId: number;
	emailAddressTypeName: string;
	emailAddress: string;
	isDefault: boolean;
	isSecondary: boolean;
	active: boolean;
}
