export interface IEstimatorModel {
	userId: number;
	fullName: string;
	active: boolean;
}

export class EstimatorModel implements IEstimatorModel {
	userId: number;
	fullName: string;
	active: boolean;
}