import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { DuplicateCustomerDialogComponent } from "./duplicate-customer-dialog.component";
import { JobEditComponent } from "./job-edit.component";
import { JobEditDialogComponent } from "./job-edit-dialog.component";
import { ContactsModule } from "@components/contacts";
import { CustomerSearchModule } from "@components/customer-search"
import { SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickTabsModule, SlickValidationIndicatorModule } from "slick-components";
import { SharedModule } from "@components";
import { NotAcceptedReasonDialogModule } from "@shared-components/not-accepted-reason-dialog";
import { JobsService, ConcurrencyValidationService } from "@services";
import { TextFieldModule } from "@angular/cdk/text-field";

@NgModule({
	imports: [CommonModule,
		FormsModule,
		SharedModule,
		ContactsModule, CustomerSearchModule, TextFieldModule,
		NotAcceptedReasonDialogModule,
		SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickTabsModule, SlickValidationIndicatorModule],
	declarations: [JobEditComponent, JobEditDialogComponent, DuplicateCustomerDialogComponent],
	exports: [JobEditComponent, JobEditDialogComponent],
	providers: [JobsService, ConcurrencyValidationService]
})
export class JobEditModule {
}
export { JobEditComponent, JobEditDialogComponent }




