import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IInventoryItemModel, IInventoryItemGridModel, IInventoryItemForJobModel } from '@models';

@Injectable()
export class InventoryService {
	constructor(private httpService: HttpService) { }

	async getInventoryItemForGrid(inventoryItemId: number): Promise<IInventoryItemGridModel> {
		return await this.httpService.get("/inventory/getInventoryItemForGrid", { inventoryItemId: inventoryItemId });
	}

	async getAllInventoryItems(searchString: string = ''): Promise<IInventoryItemGridModel[]> {
		const params = {
			page: 1,
			recordsPerPage: 1000,
			additionalParams: {}
		}

		const additionalParams = {
			searchString: searchString
		}

		params.additionalParams = additionalParams;

		const inventoryGridResult = await this.httpService.post('/inventory/getInventoryItemsForGrid', params);

		return inventoryGridResult.data;
	}

	async getInventoryItem(inventoryItemId: number): Promise<IInventoryItemModel> {
		return await this.httpService.get("/inventory/getInventoryItem", { inventoryItemId: inventoryItemId });
	}

	async getInventoryItemsForJob(jobId: number): Promise<IInventoryItemForJobModel[]> {
		return await this.httpService.get("/inventory/getInventoryItemsForJob", { jobId: jobId });
	} 

	async updateInventoryItem(inventoryItemModel: IInventoryItemModel): Promise<IInventoryItemModel> {
		if (inventoryItemModel.inventoryItemId) 
			return await this.httpService.post("/inventory/updateInventoryItem", inventoryItemModel);		
		else 
			return await this.httpService.post("/inventory/addInventoryItem", inventoryItemModel);		
	}

	async updateInventoryItemCycleCount(inventoryItemId: number, newCycleCount: number): Promise<IInventoryItemModel> {
		return await this.httpService.put(`/inventory/updateInventoryItemCycleCount?inventoryItemId=${inventoryItemId}`, newCycleCount);
	}

	async inventoryCheckout(inventoryItemId: number, jobId: number, cogsItemId: number, quantity: number) {
		const params = {
			inventoryItemId: inventoryItemId,
			jobId: jobId,
			cogsItemId: cogsItemId,
			quantity: quantity
		}
		return await this.httpService.put(`/inventory/inventoryCheckout`, params);
	}
}
