import { UtilsService } from "@services";

export interface IEstimateCreditLineItemModel {
	estimateCreditLineItemId: number;
	uuid: string;
	itemId?: number;
	itemCode: string;
	description: string;
	quantity: number;
	amount: number;
	total: number;
	showOnInvoice: boolean;
	isNewCreditLineItem: boolean;
}

export class EstimateCreditLineItemModel implements IEstimateCreditLineItemModel {
	estimateCreditLineItemId: number;
	uuid: string;
	itemId?: number;
	itemCode: string;
	description: string;
	quantity: number;
	amount: number;
	total: number;
	showOnInvoice: boolean;
	isNewCreditLineItem: boolean;
}