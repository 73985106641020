import { UtilsService } from '@services/utils/utils.service';

export interface ITimeclockLaborCodeModel {
	uuid: string;
	timeclockEntryId: number;
	laborCodeId: number;
	laborCodeName: string;
	laborCodeClass: string;
	totalMinutes: number;
	totalDisplay: string;
}

export class TimeclockLaborCodeModel implements ITimeclockLaborCodeModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
	}

	uuid: string;
	timeclockEntryId: number;
	laborCodeId: number;
	laborCodeName: string;
	laborCodeClass: string;
	totalMinutes: number;
	totalDisplay: string;
}