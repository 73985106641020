export interface IServiceAgreementModel {
	serviceAgreementId: number;
	uuid: string;
	seq: number;
	serviceAgreementTemplateId: number;
	jobId: number;
	jobGroupId: string;
	documentUuid: string;
	emailId?: number;
	agreementType: string;
	title: string;
	serviceAgreementHTML: string;
	additionalNotes: string;
	amount?: number;
	emailSentBy: string;
	emailSentDate?: Date;
	signature: string;
	signatureImage: string;
	signatureDate?: Date;
	ipAddress: string;
}

export class ServiceAgreementModel implements IServiceAgreementModel {
	serviceAgreementId: number;
	uuid: string;
	seq: number;
	serviceAgreementTemplateId: number;
	jobId: number;
	jobGroupId: string;
	documentUuid: string;
	emailId?: number;	
	agreementType: string;
	title: string;
	serviceAgreementHTML: string;
	additionalNotes: string;
	amount?: number;
	emailSentBy: string;
	emailSentDate?: Date;
	signature: string;
	signatureImage: string;
	signatureDate?: Date;
	ipAddress: string;
}