import { Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { IQBWCTaskModel } from "@models";
import { QuickbooksService, GlobalsService, UtilsService } from "@services";
import * as beautify from "xml-beautifier";
import { SlickDialogComponent } from 'slick-components';

@Component({
	selector: "quickbooks-task-display",
	templateUrl: "quickbooks-task-display.component.html",
	styleUrls: ["quickbooks-task-display.component.scss"],
	providers: [QuickbooksService]
})
export class QuickbooksTaskDisplayComponent implements OnChanges {
	@Input() qbwcTaskModel: IQBWCTaskModel;
	@ViewChild("editRequestDialogRef", { static: true }) editRequestDialogRef: SlickDialogComponent;

	isScott: boolean = false;
	requestXML: string;
	responseXML: string;
	toCopyXML: string;

	editRequestXML: string;
	editIsPending: boolean;

	constructor(private quickbooksService: QuickbooksService) {
		if (GlobalsService.isDebug)
			this.isScott = true;
		else
			this.isScott = GlobalsService.userInfo.userId === 1;

		if (GlobalsService.companyInfo.companyId === 8)
			this.isScott = true;
	}

	ngOnChanges() {
		if (this.qbwcTaskModel) {
			this.requestXML = this.formatXML(this.qbwcTaskModel.requestXML);
			this.responseXML = this.formatXML(this.qbwcTaskModel.responseXML);
		}
	}
	copyResponse() {
		const xml = this.qbwcTaskModel.responseXML;
		UtilsService.copyToClipboard(xml);
	}

	async processResponseXML() {
		if (!this.qbwcTaskModel.responseXML) {
			alert("No response XML to process");
			return;
		}

		const success = await this.quickbooksService.processResponseXML(this.qbwcTaskModel.qbwcTaskId);

		if (success === true)
			alert("Success");
	}

	editRequest() {
		this.editRequestXML = this.formatXML(this.qbwcTaskModel.requestXML);
		this.editIsPending = true;
		this.editRequestDialogRef.showDialog();
	}

	async saveEdit() {
		this.qbwcTaskModel.requestXML = this.formatXML(this.editRequestXML);
		this.qbwcTaskModel.isPending = this.editIsPending;
		this.qbwcTaskModel = await this.quickbooksService.updateQBWCTask(this.qbwcTaskModel);

		this.requestXML = this.formatXML(this.qbwcTaskModel.requestXML);
		this.responseXML = this.formatXML(this.qbwcTaskModel.responseXML);

		this.editRequestDialogRef.hideDialog();
	}

	async cancelEdit() {
		this.editRequestDialogRef.hideDialog();
	}

	private formatXML(xml: string): string {
		if (!xml)
			return '';

		xml = xml.split("\n").map(x => x.trim()).join("");

		return beautify(xml);
	}
}