import { Injectable } from "@angular/core";
import { HttpService } from "../utils/http.service";
import { ICreditCardPaymentTypeModel, ICreditCardPreAuthModel, ICreditCardProcessPaymentModel, ICreditCardProcessPaymentResult } from "@models";
import { ICreditCardService } from "./credit-card.service";

@Injectable()
export class ZiftService implements ICreditCardService {

    constructor(private httpService: HttpService) { }

    getPaymentService(): string {
        return "Zift";
    }

    async getNewCreditCardPaymentType(customerId: number): Promise<ICreditCardPaymentTypeModel> {
        return this.httpService.get("/zift/getNewCreditCardPaymentType", { customerId: customerId });
    }

    async getCreditCardPaymentType(creditCardPaymentTypeId: number): Promise<ICreditCardPaymentTypeModel> {
        return this.httpService.get("/zift/getCreditCardPaymentType", { creditCardPaymentTypeId: creditCardPaymentTypeId });
    }

    async getCreditCardPaymentTypeForCustomer(customerId: number): Promise<ICreditCardPaymentTypeModel[]> {
        return this.httpService.get("/zift/getCreditCardPaymentTypeForCustomer", { customerId: customerId });
    }

    async doesCardAllowFees(cardNumber: string): Promise<boolean> {
        return this.httpService.get(`/zift/doesCardAllowFees?cardNumber=${cardNumber}`);
    }

    async addCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return this.httpService.post("/zift/addCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async updateCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return this.httpService.post("/zift/updateCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async deleteCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return this.httpService.post("/zift/deleteCreditCardPaymentType", creditCardPaymentTypeModel);
    }


    async verifyAccountForOnlinePayment(companyId: number, creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPreAuthModel> {
        return this.httpService.post(`/zift/verifyAccountForOnlinePayment?companyId=${companyId}`, creditCardPaymentTypeModel);
    }


    async preAuthPayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardPreAuthModel> {
        return this.httpService.post("/zift/preAuthPayment", processCreditCardPaymentModel);
    }

    async preAuthOnlinePayment(companyId: number, creditCardPaymentTypeModel: ICreditCardPaymentTypeModel, creditCardProcessPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardPreAuthModel> {
        const params = {
            creditCardPaymentTypeModel: creditCardPaymentTypeModel,
            creditCardProcessPaymentModel: creditCardProcessPaymentModel
        }
        return this.httpService.post(`/zift/preAuthOnlinePayment?companyId=${companyId}`, params);
    }

    async processPayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
        return this.httpService.post("/zift/processPayment", processCreditCardPaymentModel);
    }

    async processOnlinePayment(companyId: number, creditCardPaymentTypeModel: ICreditCardPaymentTypeModel, creditCardProcessPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> { 
        const params = {
            creditCardPaymentTypeModel: creditCardPaymentTypeModel,
            creditCardProcessPaymentModel: creditCardProcessPaymentModel
        }
        return this.httpService.post(`/zift/processOnlinePayment?companyId=${companyId}`, params);
    }
}