import { GlobalsService, UtilsService } from "@services";

export interface ITaskModel {
	taskId: number;
	uuid: string;
	version: string;
	title: string;
	description: string;
	ownerUserId: number;
	ownerFullName: string;
	assignedToUserIds: number[];
	assignedToUserFullNames: string;
	jobId?: number;
	jobName: string;
	dueDate?: Date;
	nextReminder?: Date;
	snoozeMinutes: number;
	reminderLength: string;
	completedDate?: Date;
	isCompleted: boolean;
	isCancelled: boolean;
	isDeleted: boolean;

	// Angular only
	nextReminderMilliseconds?: number;
	lockJobDropDown: boolean;
	isCompleting: boolean;
}

export class TaskModel implements ITaskModel {
	constructor() {
		this.taskId = 0;
		this.uuid = UtilsService.newGuid();
		this.reminderLength = "No Reminder";
		this.ownerUserId = GlobalsService.userInfo.userId;
		this.ownerFullName = GlobalsService.userInfo.fullName;
		this.assignedToUserIds = [GlobalsService.userInfo.userId];
		this.assignedToUserFullNames = GlobalsService.userInfo.fullName;
		this.isCompleted = false;
		this.isCancelled = false;
		this.isDeleted = false;

		this.nextReminderMilliseconds = null;
	}

	taskId: number;
	uuid: string;
	version: string;
	title: string;
	description: string;
	ownerUserId: number;
	ownerFullName: string;
	assignedToUserIds: number[];
	assignedToUserFullNames: string;
	jobId?: number;
	jobName: string;
	dueDate?: Date;
	nextReminder?: Date;
	snoozeMinutes: number;
	reminderLength: string;
	completedDate?: Date;
	isCompleted: boolean;
	isCancelled: boolean;
	isDeleted: boolean;

	// Angular only
	nextReminderMilliseconds?: number;
	lockJobDropDown: boolean;
	isCompleting: boolean;
}

