import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { AuthService, UtilsService, TasksService, SleepService } from "@services";
import { SlickDialogComponent, SlickToastService } from "slick-components";
import { DropdownModel, ITaskModel, TaskModel } from "@models";
import { Subscription } from "rxjs";
import { TasksStore } from "@stores";
import { TaskEditDialogComponent } from "@app/tasks/tasks-components";

@Component({
	selector: "tasks",
	templateUrl: "tasks.component.html",
	styleUrls: ['tasks.component.css'],
	providers: [TasksService, SlickToastService]
})

export class TasksComponent implements OnInit, OnDestroy {
	@ViewChild("taskEditDialogRef") taskEditDialogRef: TaskEditDialogComponent;
	@ViewChild("reminderDialogRef") reminderDialogRef: SlickDialogComponent;

	activeTasks: ITaskModel[] = [];
	reminderTasks: ITaskModel[] = [];
	showingTasks = false;
	reminderDialogOpen: boolean = false;

	snoozeOption: string;
	reminderOptions: DropdownModel[] = [
		new DropdownModel("No Reminder", "No Reminder"),
		new DropdownModel("1 minute before", "1 minute before"),
		new DropdownModel("5 minutes before", "5 minutes before"),
		new DropdownModel("10 minutes before", "10 minute before"),
		new DropdownModel("15 minutes before", "15 minutes before"),
		new DropdownModel("30 minutes before", "30 minutes before"),
		new DropdownModel("1 hour before", "1 hour before"),
		new DropdownModel("2 hours before", "2 hours before"),
		new DropdownModel("4 hours before", "4 hours before"),
		new DropdownModel("8 hours before", "8 hours before"),
		new DropdownModel("1 day before", "1 day before"),
	]

	showReminder$: Subscription;

	taskCount: number;
	taskCount$: Subscription;

	private fnDocumentClick = (e) => this.documentClick(e);

	constructor(private authService: AuthService,
		private tasksStore: TasksStore,
		private taskService: TasksService,
		private slickToastService: SlickToastService) {
	}

	async ngOnInit() {
		if (this.authService.isAuthenticated === true) {
			this.taskCount$ = this.tasksStore.taskCountSubject.subscribe(taskCount => this.taskCount = taskCount);

			this.showReminder$ = this.tasksStore.reminder$.subscribe((reminderTasks: ITaskModel[]) => {
				this.reminderTasks = [...reminderTasks];
				if (!this.reminderDialogOpen) {
					this.reminderDialogOpen = true;
					this.reminderDialogRef.showDialog();
				}
			})
		}
	}

	ngOnDestroy() {
		if (this.showReminder$)
			this.showReminder$.unsubscribe();
	}

	onReminderDialogClose() {
		this.hideDialog();
	}

	async addTask() {
		this.hideTaskWindow();

		const taskModel = new TaskModel();

		const updatedTaskModel = await this.taskEditDialogRef.showDialog(taskModel);
	}

	showTasks() {
		if (this.showingTasks === false) {
			this.showingTasks = true;
			this.activeTasks = this.tasksStore.getActiveTasks();
			document.addEventListener("click", this.fnDocumentClick, true);
		}
		else {
			this.hideTaskWindow();
		}
	}

	async snooze(taskModel: ITaskModel, snoozeMinutes: number) {
		taskModel.reminderLength = "No Reminder";

		taskModel.snoozeMinutes = snoozeMinutes;

		taskModel = await this.taskService.updateTask(taskModel);

		this.tasksStore.updateTask(taskModel);

		this.reminderTasks = this.reminderTasks.filter(x => x.uuid !== taskModel.uuid);
		if (this.reminderTasks.length === 0)
			this.hideDialog();

		if (snoozeMinutes === 0)
			this.slickToastService.showSuccess("Task dismissed");
		else
			this.slickToastService.showSuccess("Task snoozed");

	}

	async openTask(taskModel: ITaskModel) {
		this.hideTaskWindow();

		const updatedTaskModel = await this.taskEditDialogRef.showDialog({ ...taskModel });

		if (updatedTaskModel != null)
			this.tasksStore.updateTask(updatedTaskModel);
	}

	async completeTask(e: Event, taskModel: ITaskModel) {
		e.preventDefault();
		e.stopPropagation();

		taskModel.isCompleted = true;
		taskModel.isCompleting = true;

		await SleepService.sleep(300);

		taskModel = await this.taskService.updateTask(taskModel);
		this.tasksStore.removeTask(taskModel.taskId);

		this.reminderTasks = this.reminderTasks.filter(x => x.uuid !== taskModel.uuid);
		if (this.reminderTasks.length === 0)
			this.hideDialog();

		this.slickToastService.showSuccess("Task completed");
	}

	hideTaskWindow() {
		document.removeEventListener("click", this.fnDocumentClick, true);
		this.showingTasks = false;
	}

	private async hideDialog() {
		await this.tasksStore.refreshTasks()
		this.reminderDialogOpen = false;
		this.reminderDialogRef.hideDialog();
	}

	documentClick(e) {
		if (UtilsService.checkParentClassExists(e.target, "task-details"))
			return;

		this.hideTaskWindow();
	}

}
