import { Injectable } from "@angular/core";
import { IDocumentModel, ITextChatConversationSummaryModel, ITextChatMessageModel, ITextChatTemplateModel } from "@models";
import { BehaviorSubject, Subject } from "rxjs";
import { GlobalsService, HttpService, UtilsService, AppointmentsService, LookupService } from "@services";
import { ITextChatConversationModel, TextChatConversationModel } from "../models/text-chat/text-chat-conversation.model";
import { sortBy } from "sort-by-typescript";

enum DisplayTypes { Open, Closed }

class TextChatInit {
	unreadMessageCount: number;
}

@Injectable()
export class TextChatStore {
	
	private _initRunning = true;
	private _isMobile;
	private _unreadMessagesCount: number = 0;
	private _openConversations: ITextChatConversationSummaryModel[];
	private _visibleConversations: ITextChatConversationSummaryModel[];
	private _activeConversation: ITextChatConversationModel = null;
	private _activeConversationUuid: string;
	private _displayType: DisplayTypes = DisplayTypes.Open;
	private _searchText: string;
	private _lastSearchText: string;
	private _templates: ITextChatTemplateModel[];

	unreadMessagesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(this._unreadMessagesCount);
	visibleConversations$ = new Subject<ITextChatConversationSummaryModel[]>();
	activeConversation$ = new Subject<ITextChatConversationModel>();
	newMessage$ = new Subject<ITextChatMessageModel>();
	closeConversation$ = new Subject<ITextChatConversationSummaryModel>();
	reopenConversation$ = new Subject<ITextChatConversationSummaryModel>();

	constructor(private httpService: HttpService,
		private appointmentsService: AppointmentsService,		
		private lookupService: LookupService) {
	}

	async init(isMobile: boolean): Promise<void> {
		this._isMobile = isMobile;

		const openConversations = await this.refreshOpenConversations();
		this._visibleConversations = [...openConversations];

		const textChatInit: TextChatInit = await this.httpService.get("/textChat/textChatInit");
		this._unreadMessagesCount = textChatInit.unreadMessageCount;
		this.unreadMessagesCount$.next(this._unreadMessagesCount);

		this._initRunning = false;
	}

	async waitForInit(): Promise<void> {
		return new Promise<void>((resolve) => {
			let infinteLoop = 100;
			const waitInterval = setInterval(() => {
				if (this._initRunning === false || --infinteLoop <= 0) {
					if (infinteLoop <= 0)
						console.error("Infinite loop waiting for TextChatStore.init");
					clearInterval(waitInterval);

					resolve();
				}
			}, 100);
		})
	}

	get searchText() {
		return this._searchText;
	}

	set searchText(searchText) {
		this._searchText = searchText;
	}

	get lastSearchText() {
		return this._lastSearchText;
	}

	set lastSearchText(lastSearchText) {
		this._lastSearchText = lastSearchText;
	}

	get displayType(): DisplayTypes {
		return this._displayType;
	}

	set displayType(displayType: DisplayTypes) {
		this._displayType = displayType;
		this.refresh();
	}

	async refresh() {
		this.refreshVisibleConversations();

		if (this._activeConversation) {
			this._activeConversation = <ITextChatConversationModel>(await this.httpService.get('/textChat/getConversation', { textChatConversationId: this._activeConversation.textChatConversationId }));
			this.activeConversation$.next(this._activeConversation);
		}

		const textChatInit: TextChatInit = await this.httpService.get("/textChat/textChatInit");
		this._unreadMessagesCount = textChatInit.unreadMessageCount;
		this.unreadMessagesCount$.next(this._unreadMessagesCount);

	}

	async refreshOpenConversations(): Promise<ITextChatConversationSummaryModel[]> {
		this._openConversations = <ITextChatConversationSummaryModel[]>(await this.httpService.get('/textChat/getOpenConversations'));

		return this._openConversations;
	}

	async refreshVisibleConversations(): Promise<ITextChatConversationSummaryModel[]> {
		const params = {
			displayType: this._displayType.toString(),
			searchText: this._searchText || '',
			isServiceTech: this._isMobile
		}

		this._visibleConversations = <ITextChatConversationSummaryModel[]>(await this.httpService.get('/textChat/getConversations', params));
		this.visibleConversations$.next(this._visibleConversations);

		return this._visibleConversations;
	}

	async onAddConversation(conversation: ITextChatConversationSummaryModel) {
		conversation = UtilsService.dateSanitize(conversation);

		if (this._displayType === DisplayTypes.Open) {
			this._openConversations.unshift(conversation);
			this._visibleConversations.unshift(conversation);

			this.visibleConversations$.next(this._visibleConversations);
		}
	}

	async onUpdateConversation(conversation: ITextChatConversationSummaryModel) {
		conversation = UtilsService.dateSanitize(conversation);

		const openIdx = this._openConversations.findIndex(x => x.uuid === conversation.uuid);
		if (openIdx >= 0)
			this._openConversations[openIdx] = conversation;

		const idx = this._visibleConversations.findIndex(x => x.uuid === conversation.uuid);
		if (idx >= 0) {
			this._visibleConversations[idx] = conversation;
			this.visibleConversations$.next(this._visibleConversations);
		}

		if (this._activeConversation.uuid === conversation.uuid) {
			this._activeConversation = await this.httpService.get("/textChat/getConversation", { textChatConversationId: conversation.textChatConversationId });
			this.activeConversation$.next(this._activeConversation);
		}
	}

	onCloseConversation(conversation: ITextChatConversationSummaryModel) {
		conversation = UtilsService.dateSanitize(conversation);

		this._openConversations = this._openConversations.filter(x => x.uuid !== conversation.uuid);

		if (this._displayType === DisplayTypes.Open) {
			this._visibleConversations = this._visibleConversations.filter(x => x.uuid !== conversation.uuid);
			this.visibleConversations$.next(this._visibleConversations);
		}

		this.closeConversation$.next(conversation);
	}

	onReopenConversation(conversation: ITextChatConversationSummaryModel) {
		conversation = UtilsService.dateSanitize(conversation);

		const openIdx = this._openConversations.findIndex(x => x.uuid === conversation.uuid);
		if (openIdx < 0)
			this._openConversations.unshift(conversation);
		else
			this._openConversations[openIdx] = conversation;

		if (this._displayType === DisplayTypes.Open) 
			this._visibleConversations = [...this._visibleConversations, conversation];		
		else 
			this._visibleConversations = this._visibleConversations.filter(x => x.uuid !== conversation.uuid);		

		this.visibleConversations$.next(this._visibleConversations);
		this.reopenConversation$.next(conversation);
	}

	async onNewMessage(message: ITextChatMessageModel) {
		message = UtilsService.dateSanitize(message);

		let conversation = this._openConversations.find(x => x.textChatConversationId === message.textChatConversationId);

		// If this conversation was hidden and is now not-hidden, load it
		if (!conversation) {
			conversation = (<ITextChatConversationSummaryModel>(await this.httpService.get(`/textChat/getConversationSummary?textChatConversationId=${message.textChatConversationId}`)));
			this._openConversations.push(conversation);
			this._visibleConversations.push(conversation);
		}

		if (conversation) {
			const visibleConversation = this._visibleConversations.find(x => x.textChatConversationId === message.textChatConversationId);

			if (visibleConversation) {
				visibleConversation.lastTextBody = message.body;
				visibleConversation.lastTextSentDate = message.sentDate;
			}

			// If we're an attendant of this conversation, either add an unread badge 
			// or reset the last read time if it's the current conversation
			if (conversation.assignedToUserId === GlobalsService.userInfo.userId || conversation.attendantUserIds.indexOf(GlobalsService.userInfo.userId) >= 0) {
				// If the user that sent the message is the logged in user, that means they are up to date on the read
				if (message.senderId === GlobalsService.userInfo.userId) {
					this.httpService.get("/textChat/resetLastConversationReadTime", { textChatConversationId: message.textChatConversationId })
				}
				else if (this._activeConversation && this._activeConversation.textChatConversationId === message.textChatConversationId) {
					this.httpService.get("/textChat/resetLastConversationReadTime", { textChatConversationId: message.textChatConversationId })
				}
				else {
					conversation.unreadMessages++;
					if (visibleConversation)
						visibleConversation.unreadMessages++;
					this._unreadMessagesCount++;
					this.unreadMessagesCount$.next(this._unreadMessagesCount);
				}
			}

			// Only refresh the visible conversations if we can see this conversation
			if (visibleConversation)
				this.visibleConversations$.next(this._visibleConversations);
		}

		this._visibleConversations.sort(sortBy("-lastTextSentDate"));

		this.newMessage$.next(message);
	}

	getVisibleConversations(): ITextChatConversationSummaryModel[] {
		return this._visibleConversations;
	}

	async loadActiveConversation(textChatConversationId: number) {
		this._activeConversation = <ITextChatConversationModel>(await this.httpService.get('/textChat/getConversation', { textChatConversationId }));
		this._activeConversationUuid = this._activeConversation.uuid;
		this.activeConversation$.next(this._activeConversation);

		// Find the conversation in open conversations and reset the unread count
		const conversation = this._openConversations.find(x => x.textChatConversationId === textChatConversationId);
		if (conversation != null) {
			this._unreadMessagesCount -= conversation.unreadMessages;
			this.unreadMessagesCount$.next(this._unreadMessagesCount);
			conversation.unreadMessages = 0;

			const visibleConversation = this._visibleConversations.find(x => x.textChatConversationId === textChatConversationId);
			if (visibleConversation) {
				visibleConversation.unreadMessages = 0;
				this.visibleConversations$.next(this._visibleConversations);
			}
		}
	}

	getActiveConversationUuid(): string {
		return this._activeConversationUuid;
	}

	setActiveConversation(conversation: TextChatConversationModel) {
		this._activeConversation = conversation;
		this._activeConversationUuid = conversation.uuid;
		this.activeConversation$.next(this._activeConversation);
	}

	getActiveConversation() {
		return this._activeConversation;
	}

	clearActiveConversation(clearActiveConversationUuid: boolean = true) {
		this._activeConversation = null;
		if (clearActiveConversationUuid === true)
			this._activeConversationUuid = null;
		this.activeConversation$.next(this._activeConversation);
	}

	startConversation(conversation: ITextChatConversationModel): Promise<ITextChatConversationSummaryModel> {
		return this.httpService.post('/textChat/startConversation', conversation);
	}

	updateAttendants(conversation: ITextChatConversationModel): Promise<ITextChatConversationSummaryModel> {
		return this.httpService.post('/textChat/updateAttendants', conversation);
	}

	updateAssignedTo(conversation: ITextChatConversationModel): Promise<ITextChatConversationSummaryModel> {
		return this.httpService.patch(`/textChat/updateAssignedTo?textChatConversationId=${conversation.textChatConversationId}&assignedToUserId=${conversation.assignedToUserId || ''}`);
	}

	async sendMessage(message: ITextChatMessageModel) {
		if (this._activeConversation.isClosed === true) {
			await this.httpService.patch(`/textChat/reopenConversation?textChatConversationId=${this._activeConversation.textChatConversationId}`);
		}

		this.httpService.post('/textChat/sendMessage', message);
	}

	async sendAttachments(files: IDocumentModel[]): Promise<ITextChatMessageModel[]> {
		if (this._activeConversation.isClosed === true) {
			await this.httpService.patch(`/textChat/reopenConversation?textChatConversationId=${this._activeConversation.textChatConversationId}`);
		}

		try {
			const formData = new FormData();
			files.forEach(file => {
				if (file.base64Image && file.base64Image.length > 0) {
					const fileCopy: IDocumentModel = UtilsService.clone(file);
					const fileName = file.name;
					delete fileCopy.file;
					delete fileCopy.thumbnailBase64Image;
					const fileJSON = JSON.stringify(fileCopy);
					formData.append(fileName, fileJSON);
				}
			});

			return await this.httpService.postMultipart(`/textChat/sendAttachments?textChatConversationId=${this._activeConversation.textChatConversationId}`, formData);
		} catch (error) {
			console.error(error);
		}
	}

	addSystemMessage(message: ITextChatMessageModel) {
		this.httpService.post('/textChat/addSystemMessage', message);
	}

	closeConversation(textChatConversationId: number) {
		this.httpService.patch(`/textChat/closeConversation?textChatConversationId=${textChatConversationId}`);
	}

	async getConversationByAppointmentId(appointmentId: number): Promise<ITextChatConversationModel> {
		return this.httpService.get(`/textChat/getConversationByAppointmentId?appointmentId=${appointmentId}`);
	}

	async getConversationByPhoneNumber(textChatPhoneNumber: string, jobId?: number): Promise<ITextChatConversationModel> {
		return this.httpService.get(`/textChat/getConversationByPhoneNumber?phoneNumber=${textChatPhoneNumber}`);
	}

	async getConversationByJobId(jobId: number): Promise<ITextChatConversationModel> {
		return this.httpService.get(`/textChat/getConversationByJobId?jobId=${jobId}`);
	}

	async getTemplates(showInactive: boolean = false, forceReload: boolean = false): Promise<ITextChatTemplateModel[]> {
		if (!this._templates || forceReload)
			this._templates = await this.httpService.get("/textChatTemplates/getTemplates");

		if (showInactive === false)
			return this._templates.filter(x => x.active === true);
		else 
			return [...this._templates];
	}

	async updateTemplates(templates: ITextChatTemplateModel[]): Promise<ITextChatTemplateModel[]> {
		this._templates = await this.httpService.post('/textChatTemplates/updateTemplates', templates);

		return this._templates;
	}
}
