import { ErrorHandler, Injectable } from '@angular/core';
import { GlobalsService, SleepService } from "@services";

import * as atatus from 'atatus-js';
atatus.config('6bdcc819ae2f4dd1813e2c3d6b9a6fbf').install();

@Injectable()
class AtatusErrorHandler extends ErrorHandler {
	private errorCache: string[] = [];

	constructor() {
		super();
	}

	async handleError(error: Error) {
		await SleepService.sleep(100);

		const errorStr = error.toString();
		if (this.errorCache.indexOf(errorStr) >= 0)
			return;

		this.errorCache.push(errorStr);

		if (atatus && GlobalsService.isDebug === false) {
			// Send the error to Atatus
			const tags = ['desktop'];
			if (GlobalsService.userInfo)
				tags.push(GlobalsService.userInfo.userName);

			atatus.notify(error, null, tags);
		}

		super.handleError(error);
	}
}

export { AtatusErrorHandler };