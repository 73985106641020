import { ITimeclockEntryModel } from "./timeclock-entry.model";
import * as moment from "moment";

export interface ITimeclockEntryDay {
	date: Date;
	totalMinutes: number;
	totalDisplay: string;
	totalWorkedMinutes: number;
	totalWorkedDisplay: string;
	totalBreakMinutes: number;
	totalBreakDisplay: string;
	timeclockEntryModels: ITimeclockEntryModel[]

}

export class TimeclockEntryDay implements ITimeclockEntryDay {
	constructor(date: Date) {
		this.date = date;
		this.totalMinutes = 0;
		this.totalWorkedMinutes = 0;
		this.totalBreakMinutes = 0;
		this.timeclockEntryModels = [];
	}

	date: Date;
	totalMinutes: number;
	get totalDisplay(): string {
		return moment.utc(moment.duration(this.totalMinutes, "minutes").asMilliseconds()).format("H:mm");
	}
	totalWorkedMinutes: number;
	get totalWorkedDisplay(): string {
		return moment.utc(moment.duration(this.totalWorkedMinutes, "minutes").asMilliseconds()).format("H:mm");
	}
	totalBreakMinutes: number;
	get totalBreakDisplay(): string {
		return moment.utc(moment.duration(this.totalBreakMinutes, "minutes").asMilliseconds()).format("H:mm");
	}
	timeclockEntryModels: ITimeclockEntryModel[]
}
