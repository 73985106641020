import { IVendorModel } from '../vendors/vendor.model';

export interface IInventoryItemModel {
	inventoryItemId: number;
	sku: string;
	description: string;
	vendorId?: number;
	cogsItemId?: number;
	threshold?: number;
	orderQuantity?: number;
	orderAmount?: string;
	cost?: number; 
	price?: number;
	vendorSku?: string;
	currentQuantity: number;

	vendor: IVendorModel;
}

export class InventoryItemModel implements IInventoryItemModel {
	constructor() {
		this.currentQuantity = 0;
		this.cost = 0;
		this.price = 0;
	}

	inventoryItemId: number;
	sku: string;
	description: string;
	vendorId?: number;
	cogsItemId?: number;
	threshold?: number;
	orderQuantity?: number;
	orderAmount?: string;
	cost?: number;
	price?: number;
	vendorSku?: string;
	currentQuantity: number;

	vendor: IVendorModel;
}