import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SlickEmailerModule, SlickEmailViewerModule, SlickDatePickerModule, SlickDialogModule } from "slick-components";
import { FormErrorModule, FormErrorComponent } from "@shared-components/form-error";
import { EmailerDialogComponent } from "./emailer-dialog.component";
import { DocumentViewerComponent } from "./document-viewer.component";
import { DocumentViewerDialogComponent } from "./document-viewer-dialog.component";
import { ColorPickerModule } from "ngx-color-picker";
import { ColorPickerDialogComponent } from "./color-picker-dialog.component";
import { ConcurrencyErrorDialogComponent } from "./concurrency-error-dialog.component";
import { AddButtonDialogComponent } from "./add-button.component";
import { EditButtonDialogComponent } from "./edit-button.component";
import { DateTimeEntryComponent } from "./date-time-entry/date-time-entry.component";

@NgModule({
	imports: [CommonModule, FormsModule, ColorPickerModule, FormErrorModule, SlickDatePickerModule, SlickEmailerModule, SlickEmailViewerModule, SlickDialogModule],
	declarations: [ColorPickerDialogComponent, EmailerDialogComponent, DocumentViewerComponent, DocumentViewerDialogComponent, ConcurrencyErrorDialogComponent, AddButtonDialogComponent, EditButtonDialogComponent, DateTimeEntryComponent],
	exports: [ColorPickerDialogComponent, EmailerDialogComponent, DocumentViewerComponent, DocumentViewerDialogComponent, ConcurrencyErrorDialogComponent, AddButtonDialogComponent, EditButtonDialogComponent, DateTimeEntryComponent, FormErrorComponent]
})
export class SharedModule {
}

export { ColorPickerDialogComponent }
export { EmailerDialogComponent }
export { DocumentViewerComponent }
export { DocumentViewerDialogComponent } 
export { ConcurrencyErrorDialogComponent } 
export { DateTimeEntryComponent } 