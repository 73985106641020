export interface IEquipmentTypeModel {
	equipmentTypeId: number;
	description: string;
	active: boolean;
}

export class EquipmentTypeModel implements IEquipmentTypeModel {
	equipmentTypeId: number;
	description: string;
	active: boolean;
}