export interface ICalendarResourceModel {
	userId: number;
	fullName: string;
	visible: boolean;
	color: string;
}

export class CalendarResourceModel implements ICalendarResourceModel {
	userId: number;
	fullName: string;
	visible: boolean;
	color: string;
}