import { Injectable } from "@angular/core";
import { IPayPeriodDefinitionModel, IPayrollLaborCode } from "../models";
import { HttpService } from "./utils/http.service";

@Injectable()
export class PayrollService {
	constructor(private httpService: HttpService) { }

	async getPayrollLaborCodes(): Promise<IPayrollLaborCode[]> {
		return this.httpService.get("/payroll/getPayrollLaborCodes");
	}

	async updatePayrollLaborCodes(payrollLaborCodes: IPayrollLaborCode[]): Promise<IPayrollLaborCode[]> {
		return this.httpService.post("/payroll/updatePayrollLaborCodes", payrollLaborCodes);
	}

	async getPayPeriodDefinitions(showInactive: boolean): Promise<IPayPeriodDefinitionModel[]> {
		return this.httpService.get("/payroll/getPayPeriodDefinitions");
	}

	async updatePayPeriodDefinition(payPeriodDefinitionModel: IPayPeriodDefinitionModel): Promise<IPayPeriodDefinitionModel> {
		return this.httpService.post("/payroll/updatePayPeriodDefinitions", payPeriodDefinitionModel);
	}
}
