export interface ITimeSlotModel {
	index: number;
	left: number;
	top: number;
	width: number;
	height: number;
	maxAppointments: number;
}

export class TimeSlotModel implements ITimeSlotModel {
	constructor(index: number, left: number, top: number, width: number, height: number) {
		this.index = index;
		this.left = left;
		this.top = top;
		this.width = width;
		this.height = height;
		this.maxAppointments = 0;
	}

	index: number;
	left: number;
	top: number;
	width: number;
	height: number;
	maxAppointments: number;
}

