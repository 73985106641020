<div class="header bg-danger text-light p-0">

	<div class="menu-right">
		<tasks></tasks>
		<notifications></notifications>
		<div class="profile-container" [routerLink]="['/userSettings']">
			<div class="profile-name text-light">{{firstName}}</div>
			<img *ngIf="profilePictureUrl" [src]="profilePictureUrl" />
		</div>
	</div>
</div>

