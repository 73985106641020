import { ISlickTreeNodeModel, SlickTreeNodeModel } from "slick-components";
export enum DocumentTypes { JOB_DOCUMENTS, JOB_PHOTOS, USER_DOCUMENTS, SUBCONTRACTOR_DOCUMENTS, CERTIFICATIONS, WORKORDER_DOCUMENTS, WORKORDER_PHOTOS }

export interface IDocumentFolderModel extends ISlickTreeNodeModel {
	rootUuid: string;
	referenceId: number;
	documentType: DocumentTypes;
}

export class DocumentFolderModel extends SlickTreeNodeModel implements IDocumentFolderModel {
	rootUuid: string;
	referenceId: number;
	documentType: DocumentTypes;
}