import { IUserPermissionModel } from "../users/user-permission.model";

export interface IEmployeeRoleModel {
	employeeRoleId: number;
	description: string;
	isSystemRole: boolean;
	defaultPermissions: IUserPermissionModel[];
	active: boolean;
}

export class EmployeeRoleModel implements IEmployeeRoleModel {
	employeeRoleId: number;
	description: string;
	isSystemRole: boolean;
	defaultPermissions: IUserPermissionModel[];
	active: boolean;
}