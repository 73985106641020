import { UtilsService } from "@services";

export class Coordinates {
	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}

	x: number;
	y: number;
}

export enum EndPointTypes { P1, P2 }

export interface IRoomDesignWallModel {
	uuid: string;
	coordsP1X: number;
	coordsP1Y: number;
	coordsP2X: number;
	coordsP2Y: number;

	widthFeet: number;
	widthInches: number;

	isSelected: boolean;
	color: string;

	lineDrawing: any;
	lineCoordinates: Coordinates[];
}

export class RoomDesignWallModel implements IRoomDesignWallModel {
	constructor(x1: number, y1: number, x2: number, y2: number) {
		this.uuid = UtilsService.newGuid();

		if (x1 <= x2) {
			this.coordsP1X = x1;
			this.coordsP1Y = y1;
			this.coordsP2X = x2;
			this.coordsP2Y = y2;
		}
		else if (x1 > x2) {
			this.coordsP1X = x2;
			this.coordsP1Y = y2;
			this.coordsP2X = x1;
			this.coordsP2Y = y1;
		}

		this.isSelected = false;

		//this.lineCoordinates = this.getLineCoordinates();
	}

	uuid: string;
	coordsP1X: number;
	coordsP1Y: number;
	coordsP2X: number;
	coordsP2Y: number;
	widthFeet: number;
	widthInches: number;

	lineDrawing: any;
	lineCoordinates: Coordinates[];

	isSelected: boolean;
	color: string;
}