import { IAppointmentDisplayModel } from "@models/appointments/appointment-display.model";

export interface ICalendarMonthModel {
	weeks: ICalendarWeekModel[];
}

export class CalendarMonthModel implements ICalendarMonthModel {
	weeks: ICalendarWeekModel[];
}

export interface ICalendarWeekModel {
	days: ICalendarDayModel[];
}

export class CalendarWeekModel implements ICalendarWeekModel {
	days: ICalendarDayModel[];
}

export interface ICalendarDayModel {
	date: Date;
	notInMonth: boolean;
	isToday: boolean;
	isSelectedDate: boolean;
	appointments: IAppointmentDisplayModel[];
	appointmentCount: number;
}

export class CalendarDayModel implements ICalendarDayModel {
	date: Date;
	notInMonth: boolean;
	isToday: boolean;
	isSelectedDate: boolean;
	appointments: IAppointmentDisplayModel[];
	appointmentCount: number;
}


