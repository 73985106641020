import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TaskEditBase } from "@base-components/task-edit.base"
import { EmailerModel, IEmailerModel, ITaskModel } from "@models";
import { GlobalsService, HttpService, LookupService, SleepService, TasksService, UtilsService } from "@services";
import { TasksStore } from "@stores";
import { EmailerDialogComponent } from "@components";

@Component({
	selector: "task-edit",
	templateUrl: "task-edit.component.html"
})
export class TaskEditComponent extends TaskEditBase {
	@Input() showButtons: boolean;
	@Output() onTaskSaved: EventEmitter<ITaskModel> = new EventEmitter<ITaskModel>();
	@Output() onTaskCancelled: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("titleRef") titleRef: ElementRef;
	@ViewChild("emailerDialogRef", { static: true }) emailerDialogRef: EmailerDialogComponent;

	isSubmitted: boolean;
	spinnerStatus: string;
	saveAndEmailSpinnerStatus: string;
	emailerModel: IEmailerModel;


	constructor(tasksService: TasksService,
		tasksStore: TasksStore,
		lookupService: LookupService,
		httpService: HttpService) {

		super(tasksService, tasksStore, lookupService, httpService);
	}

	async ngOnChanges() {
		this.isSubmitted = false;

		this.showButtons = (this.showButtons || true).toString() === 'true';

		super.ngOnChanges();

		if (this.taskModel) {
			if (!this.taskModel.ownerUserId)
				this.taskModel.ownerUserId = GlobalsService.userInfo.userId;

			this.taskModel.lockJobDropDown = this.taskModel.lockJobDropDown || false;

			await SleepService.sleep();
			(<HTMLInputElement>this.titleRef.nativeElement).focus();
		}

	}

	validate(): boolean {
		return super.validate();
	}

	async onSave(): Promise<ITaskModel> {

		this.isSubmitted = true;
		this.spinnerStatus = "reset";

		try {
			if (this.validate() == false) {
				this.spinnerStatus = "error";
				return;
			}

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);
			await super.saveTask();		

			this.spinnerStatus = "ok";

			this.onTaskSaved.emit(this.taskModel);

			return this.taskModel;
		}
		catch {
			this.spinnerStatus = "error";
		}

	}


	async onSaveAndEmailTask() {
		try {
			if (this.validate() == false) {
				this.saveAndEmailSpinnerStatus = "error";
				return;
			}

			this.saveAndEmailSpinnerStatus = "spin";
			await SleepService.sleep(500);

			this.taskModel = await super.saveTask();			
			const emailerModel = await this.tasksService.getEmailerModel(this.taskModel);

			this.saveAndEmailSpinnerStatus = "ok";

			this.emailerDialogRef.showDialog(emailerModel);
		}
		catch (error) {
			this.saveAndEmailSpinnerStatus = "error";
			console.error(error);
		}
	}

	async onEmailSent(emailerModel: IEmailerModel) {

	}

	onCancel() {
		this.onTaskCancelled.emit(null);
	}
}
