import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, Pipe, PipeTransform } from "@angular/core";
import { SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickDialogComponent } from "slick-components";
import { NoDefaultPipe } from "../no-default.pipe";
import { IPhoneNumberModel, PhoneNumberModel } from "@models";
import { UtilsService, SleepService, ContactsService } from "@services";

@Component({
	selector: "phoneNumbersEdit",
	templateUrl: "phone-numbers-edit.component.html",
	styles: [`
		.phoneNumberBlock { padding: 5px; padding-left: 10px; line-height: 1.1em; }
		.phoneNumberBlock:nth-child(even) { background: #f8f8f8; }
		.phoneNumberBlock .far { margin-right: 3px; }`]
})
export class PhoneNumbersEditComponent implements OnChanges {
	@Input() phoneNumberModels: IPhoneNumberModel[];
	@Output() phoneNumberModelsChange: EventEmitter<IPhoneNumberModel[]> = new EventEmitter<IPhoneNumberModel[]>();
	@Input() showWork: boolean = false;

	@ViewChild("phoneNumberDialogRef", { static: true }) phoneNumberDialogRef: SlickDialogComponent;
	@ViewChild("deletePhoneNumberRef", { static: true }) deletePhoneNumberRef: SlickConfirmDialogComponent;

	defaultHome: IPhoneNumberModel = new PhoneNumberModel();
	defaultWork: IPhoneNumberModel = new PhoneNumberModel();
	defaultCell: IPhoneNumberModel = new PhoneNumberModel();
	defaultAlt1: IPhoneNumberModel = new PhoneNumberModel();
	defaultAlt2: IPhoneNumberModel = new PhoneNumberModel();
	dialogModel: IPhoneNumberModel = this.contactsService.getNewPhoneNumberModel();
	phoneNumberTypes: any[];
	showDialog: boolean = false;

	constructor(private contactsService: ContactsService) {


		this.phoneNumberTypes = [
			{ id: 1, text: 'Home',  },
			{ id: 2, text: 'Work' },
			{ id: 3, text: 'Cell' },
			{ id: 4, text: 'Fax' },
			{ id: 5, text: 'Other' }
		]
	}

	async ngOnChanges() {
		await SleepService.sleep();
		if (!this.phoneNumberModels || this.phoneNumberModels.length === 0) 
			this.phoneNumberModels = new Array<PhoneNumberModel>();			

		this.defaultHome = this.phoneNumberModels.find((p) => p.phoneNumberTypeId === 1 && p.isDefault === true);
		if (!this.defaultHome) {
			this.defaultHome = this.contactsService.getNewPhoneNumberModel(true, 1);
			this.phoneNumberModels.push(this.defaultHome);
		}

		this.defaultWork = this.phoneNumberModels.find((p) => p.phoneNumberTypeId === 2 && p.isDefault === true);
		if (!this.defaultWork) {
			this.defaultWork = this.contactsService.getNewPhoneNumberModel(true, 2);
			this.phoneNumberModels.push(this.defaultWork);
		}

		this.defaultCell = this.phoneNumberModels.find((p) => p.phoneNumberTypeId === 3 && p.isDefault === true);
		if (!this.defaultCell) {
			this.defaultCell = this.contactsService.getNewPhoneNumberModel(true, 3);
			this.phoneNumberModels.push(this.defaultCell);
		}

		this.defaultAlt1 = this.phoneNumberModels.find((p) => p.phoneNumberTypeId === 6 && p.isDefault === true);
		if (!this.defaultAlt1) {
			this.defaultAlt1 = this.contactsService.getNewPhoneNumberModel(true, 6);
			this.phoneNumberModels.push(this.defaultAlt1);
		}

		this.defaultAlt2 = this.phoneNumberModels.find((p) => p.phoneNumberTypeId === 7 && p.isDefault === true);
		if (!this.defaultAlt2) {
			this.defaultAlt2 = this.contactsService.getNewPhoneNumberModel(true, 7);
			this.phoneNumberModels.push(this.defaultAlt2);
		}

		this.phoneNumberModelsChange.emit(this.phoneNumberModels);
	}

	addNewPhoneNumber() {
		this.dialogModel = this.contactsService.getNewPhoneNumberModel(false, 5);
		this.phoneNumberDialogRef.showDialog();
	}

	editPhoneNumber(phoneNumberModel: IPhoneNumberModel) {
		this.dialogModel = UtilsService.clone(phoneNumberModel);
		this.phoneNumberDialogRef.showDialog();
		this.phoneNumberModelsChange.emit(this.phoneNumberModels);
	}

	async deletePhoneNumber(phoneNumberModel: IPhoneNumberModel) {
		let confirmResults: SlickConfirmDialogResults = await this.deletePhoneNumberRef.confirm();

		if (confirmResults === SlickConfirmDialogResults.Ok) {
			this.phoneNumberModels = this.phoneNumberModels.filter((a: IPhoneNumberModel) => a.uuid !== phoneNumberModel.uuid);
			this.phoneNumberModelsChange.emit(this.phoneNumberModels);
		}
	}

	onPhoneNumberTypeSelect(phoneNumberType) {
		if (phoneNumberType)
			this.dialogModel.phoneNumberTypeName = phoneNumberType.text;
		else
			this.dialogModel.phoneNumberTypeName = null;
	}

	onUpdate() {
		let phoneNumberModel = this.phoneNumberModels.find((a: IPhoneNumberModel) => a.uuid === this.dialogModel.uuid);
		if (!phoneNumberModel)
			this.phoneNumberModels.push(this.dialogModel);
		else 
			Object.assign(phoneNumberModel, this.dialogModel);		

		// If true, we're changing the default phoneNumber
		if (this.dialogModel.isDefault === true) {
			this.phoneNumberModels.forEach((a: IPhoneNumberModel) => a.isDefault = false);
			let newDefaultPhoneNumberModel = this.phoneNumberModels.find((a: IPhoneNumberModel) => a.uuid === this.dialogModel.uuid);
			newDefaultPhoneNumberModel.isDefault = true;
		}

		this.phoneNumberDialogRef.hideDialog();

		this.phoneNumberModelsChange.emit(this.phoneNumberModels);
	}

	onCancel() {
		this.phoneNumberDialogRef.hideDialog();
	}
}
