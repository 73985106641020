import { UtilsService } from "@services/utils/utils.service";

export interface IEmployeePayRateLaborCode {
	uuid: string;
	payrollLaborCodeId: number;
	displayName: string;
	overrideOvertime: boolean;
	payRate?: number;
}

export class EmployeePayRateLaborCode implements IEmployeePayRateLaborCode {
	constructor() {
		this.uuid = UtilsService.newGuid();
	}

	uuid: string;
	payrollLaborCodeId: number;
	displayName: string;
	overrideOvertime: boolean;
	payRate?: number;
}