export interface ICreditCardPaymentTypeModel {
    creditCardPaymentTypeId: number;
    customerId: number;
    processor: string;
    processorPaymentId: string;
    paymentDescription: string;
    paymentType: string;
    routingNumber: string;
    accountNumber: string;
    accountMasked: string;
    expDate: string;
    cvc: string;
    address: string;
    postalCode: string;
    isCreditCard: boolean;
    isACH: boolean;
    isDefault: boolean;
    active: boolean;
    displayText: string;
    accountNumberLastFour: string;   
}

export class CreditCardPaymentTypeModel implements ICreditCardPaymentTypeModel {
    creditCardPaymentTypeId: number;
    customerId: number;
    processor: string;
    processorPaymentId: string;
    paymentDescription: string;
    paymentType: string;
    routingNumber: string;
    accountNumber: string;
    accountMasked: string;
    expDate: string;
    cvc: string;
    address: string;
    postalCode: string;
    isCreditCard: boolean;
    isACH: boolean;
    isDefault: boolean;
    active: boolean;
    displayText: string;
    accountNumberLastFour: string;
}