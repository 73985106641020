import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { GlobalsService } from "@services";
import { Router } from "@angular/router";

@Component({
	selector: "landing",
	template: ``
})
export class LandingComponent implements OnInit {
	constructor(private location: Location, private router: Router) { }

	ngOnInit() {
		if (GlobalsService.isAdmin === true) {
			this.router.navigate(["/globalAdmin"]);
		}		
		else if (GlobalsService.companySettings.isCorporateAccount)
			this.router.navigate(["/corporate-view"]);
		else if (GlobalsService.isLoggedIn === true) {
			this.router.navigate(["/jobs"]);
		}			
	}
}