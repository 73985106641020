import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { UtilsService } from "./utils/utils.service";
import { LookupService } from "./utils/lookup.service";
import { ContactsService } from "./contacts.service";
import { IDropdownModel, IVendorSearchListModel, IVendorGridModel, IVendorModel, VendorModel, IVendorNoteModel } from "@models";

@Injectable()
export class VendorsService {
	constructor(private httpService: HttpService,
		private lookupService: LookupService,
		private contactsService: ContactsService) { }

	getNewVendorModel(): IVendorModel {
		var vendorModel: IVendorModel = new VendorModel();
		vendorModel.vendorId = 0;
		vendorModel.uuid = UtilsService.newGuid();
		vendorModel.version = 0;
		vendorModel.contact = this.contactsService.getNewContactModel();

		return vendorModel;
	}

	async getVendor(vendorId: number): Promise<IVendorModel> {
		try {
			return Promise.resolve(await this.httpService.get("/vendors/getVendor", { vendorId: vendorId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getVendorSearchListModel(vendorId: number): Promise<IVendorSearchListModel> {
		try {
			return Promise.resolve(await this.httpService.get("/vendors/getVendorSearchListModel", { vendorId: vendorId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getVendorsForInfiniteScroll(pageCount: number, searchString: string = ""): Promise<IVendorGridModel[]> {
		try {
			const params = {
				pageCount: pageCount,
				searchString: searchString
			}

			return Promise.resolve(await this.httpService.get("/vendors/getVendorsForInfiniteScroll", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getVendorsForJob(jobId: number): Promise<IVendorModel[]> {
		return Promise.resolve(await this.httpService.get("/vendors/getVendorsForJob", { jobId: jobId }));
	}

	async updateVendor(vendorModel: IVendorModel): Promise<IVendorModel> {
		try {
			if (vendorModel.vendorId === 0) {
				let returnModel = await this.httpService.post("/vendors/addVendor", vendorModel);
				this.lookupService.refreshEstimators();
				this.lookupService.refreshEmailerAddresses();
				return Promise.resolve(returnModel);
			}
			else {
				let returnModel = await this.httpService.post("/vendors/updateVendor", vendorModel)
				this.lookupService.refreshEstimators();
				this.lookupService.refreshEmailerAddresses();
				return Promise.resolve(returnModel);
			}
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async deleteVendor(vendorId: number): Promise<boolean> {
		try {
			return Promise.resolve(await this.httpService.delete("/vendors/deleteVendor", { vendorId: vendorId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getVendorUserNotes(vendorId: number, count: number): Promise<IVendorNoteModel[]> {
		try {
			let params = {
				vendorId: vendorId,
				count: count
			}
			return Promise.resolve(await this.httpService.get("/vendors/getVendorUserNotes", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getVendorSystemNotes(vendorId: number, count: number): Promise<IVendorNoteModel[]> {
		try {
			let params = {
				vendorId: vendorId,
				count: count
			}
			return Promise.resolve(await this.httpService.get("/vendors/getVendorSystemNotes", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getVendorSearchResults(searchText: string): Promise<IVendorSearchListModel[]> {
		try {
			const params = {
				searchText: searchText
			}

			return Promise.resolve(await this.httpService.get("/vendors/getVendorSearchResults", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getVendorForGrid(vendorId: number): Promise<any> {
		try {
			return Promise.resolve(await this.httpService.get("/vendors/getVendorForGrid", { vendorId: vendorId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async syncToQuickbooks(vendorId: number): Promise<IVendorGridModel> {
		try {
			return Promise.resolve(await this.httpService.post("/vendors/syncToQuickbooks", vendorId));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async unsyncFromQuickbooks(vendorId: number): Promise<IVendorGridModel> {
		try {
			return Promise.resolve(await this.httpService.post("/vendors/unsyncFromQuickbooks", vendorId));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}
}
