import { IMitigationRoomStructureReadingModel } from './mitigation-room-structure-reading.model';
import { IMitigationRoomModel } from './mitigation-room.model';

export interface IMitigationRoomStructureModel {
	mitigationRoomStructureId: number;
	mitigationRoomId: number;
	mitigationRoomName: string;
	structureName: string;

	mitigationRoomStructureReadings: IMitigationRoomStructureReadingModel[];
}

export class MitigationRoomStructureModel implements IMitigationRoomStructureModel {
	constructor() {
		this.mitigationRoomStructureId = 0;
	}
	mitigationRoomStructureId: number;
	mitigationRoomId: number;
	mitigationRoomName: string;
	structureName: string;

	mitigationRoomStructureReadings: IMitigationRoomStructureReadingModel[];
}

