import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { SharedModule } from "@components";
import { TasksService } from "@services";
import { SlickButtonSpinnerModule, SlickContainerModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickMultiSelectModule, SlickTabsModule, SlickValidationIndicatorModule } from "slick-components";
import { TaskEditComponent } from "./task-edit/task-edit.component";
import { TaskEditDialogComponent } from "./task-edit/task-edit-dialog.component";
import { TaskListComponent } from "./task-list/task-list.component";
import { TaskNotificationDialogComponent } from "./task-notification/task-notification-dialog.component";
import { TaskNotesComponent } from "./task-notes/task-notes.component";

@NgModule({
	imports: [CommonModule, FormsModule, SharedModule,
		SlickButtonSpinnerModule, SlickContainerModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickMultiSelectModule, SlickTabsModule, SlickValidationIndicatorModule],
	declarations: [TaskEditComponent, TaskEditDialogComponent, TaskListComponent, TaskNotificationDialogComponent, TaskNotesComponent],
	exports: [TaskEditComponent, TaskEditDialogComponent, TaskListComponent, TaskNotificationDialogComponent],
	providers: [TasksService]
})
export class TasksComponentsModule { }

export { TaskEditComponent, TaskEditDialogComponent, TaskListComponent, TaskNotificationDialogComponent }
