export interface IPayableCreditCardModel {
	payableCreditCardId: number;
	description: string;
	active: boolean;
}

export class PayableCreditCardModel implements IPayableCreditCardModel {
	payableCreditCardId: number;
	description: string;
	active: boolean;
}