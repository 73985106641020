import { ITimeclockBreakModel } from "./timeclock-break.model";
import { ITimeclockLaborCodeModel } from "./timeclock-labor-code.model";
import { IAppointmentReminderModel } from "../appointments/appointment-reminder.model";

export interface ITimeclockEntryModel {
	timeclockEntryId: number;
	userId: number;
	userFullName: string;
	jobId?: number;
	jobName: string;
	appointmentId?: number;
	clockIn: Date;
	clockInGeolocation: string;
	clockOut: Date;
	clockOutGeolocation: string;
	totalMinutes: number;
	totalDisplay: string;
	totalBreakMinutes: number;
	totalBreakDisplay: string;
	totalWorkedMinutes: number;
	totalWorkedDisplay: string;
	regularPayTotalMinutes: number;
	regularPayTotalDisplay: string;
	overtimePayTotalMinutes: number;
	overtimePayTotalDisplay: string;
	officePayTotalMinutes: number;
	officePayTotalDisplay: string;
	officeOvertimeTotalMinutes: number;
	officeOvertimeTotalDisplay: string;
	edpTotalMinutes: number;
	edpTotalDisplay: string;
	traumaTotalMinutes: number;
	traumaTotalDisplay: string;
	ptoTotalMinutes: number;
	ptoTotalDisplay: string;
	holidayTotalMinutes: number;
	holidayTotalDisplay: string;
	isOnBreak: boolean;
	currentBreak: ITimeclockBreakModel;
    notes: string;
	isTimeOffRequest: boolean;
	approved: boolean;
	syncedToQuickbooks: boolean;
	clockoutJobNote: string;

	canEdit: boolean;
	canDelete: boolean;

	breaks: ITimeclockBreakModel[];
	laborCodes: ITimeclockLaborCodeModel[];
	appointmentReminders: IAppointmentReminderModel[];
}

export class TimeclockEntryModel implements ITimeclockEntryModel {
	timeclockEntryId: number;
	userId: number;
	userFullName: string;
	jobId?: number;
	jobName: string;
	appointmentId?: number;
	clockIn: Date;
	clockInGeolocation: string;
	clockOut: Date;
	clockOutGeolocation: string;
	totalMinutes: number;
	totalDisplay: string;
	totalBreakMinutes: number;
	totalBreakDisplay: string;
	totalWorkedMinutes: number;
	totalWorkedDisplay: string;
	regularPayTotalMinutes: number;
	regularPayTotalDisplay: string;
	overtimePayTotalMinutes: number;
	overtimePayTotalDisplay: string;
	officePayTotalMinutes: number;
	officePayTotalDisplay: string;
	officeOvertimeTotalMinutes: number;
	officeOvertimeTotalDisplay: string;
	edpTotalMinutes: number;
	edpTotalDisplay: string;
	traumaTotalMinutes: number;
	traumaTotalDisplay: string;
	ptoTotalMinutes: number;
	ptoTotalDisplay: string;
	holidayTotalMinutes: number;
	holidayTotalDisplay: string;
	isOnBreak: boolean;
	currentBreak: ITimeclockBreakModel;
	notes: string;
	isTimeOffRequest: boolean;
	approved: boolean;
	syncedToQuickbooks: boolean;
	clockoutJobNote: string;

	canEdit: boolean;
	canDelete: boolean;

	breaks: ITimeclockBreakModel[];
	laborCodes: ITimeclockLaborCodeModel[];
	appointmentReminders: IAppointmentReminderModel[];

    constructor() {
        this.breaks = [];
		this.laborCodes = [];
		this.approved = false;
		this.syncedToQuickbooks = false;
    }
}