import { UtilsService } from "@services";

export interface IInvoiceLineItemModel {
	uuid: string;
	displaySeq: number;
	description: string;
	quantity: number;
	amount: number;
	total: number;
	isNewLineItem: boolean;
}

export class InvoiceLineItemModel implements IInvoiceLineItemModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
		this.quantity = 1;
		this.amount = 0;
	}

	uuid: string;
	displaySeq: number;
	description: string;
	quantity: number;
	amount: number;
	get total(): number {
		return UtilsService.round(this.amount * this.quantity);
	}
	isNewLineItem: boolean;
}
