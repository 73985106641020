import { ISlickEmailerModel, SlickEmailerModel } from "slick-components";
import { UtilsService } from "@services/utils/utils.service";

export interface IEmailerModel extends ISlickEmailerModel {
	sentByUser: string;
	referenceUuid: string
	isSent: boolean;
	referenceId: number;
	hasException: boolean;
	emailException: string;
}
export class EmailerModel extends SlickEmailerModel implements IEmailerModel {
	constructor() {
		super();
		this.uuid = UtilsService.newGuid();
	}

	sentByUser: string;
	referenceUuid: string
	isSent: boolean;
	referenceId: number;
	hasException: boolean;
	emailException: string;
}