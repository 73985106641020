<div *ngIf="activeNotifications.length === 0" class="notifications border border-dark border-top-0 border-left-0 border-right-0 ps-3 pe-3 ms-1 me-3 bg-white">
	<div class="notifications-flag text-black-50" style="opacity: .35;" >
		<i class="fal fa-flag-alt"></i>
	</div>
</div>
<div *ngIf="activeNotifications.length > 0"  (click)="showNotifications()" style="cursor: pointer;" class="notifications border border-dark border-top-0 border-left-0 border-right-0 ps-3 pe-3 ms-1 me-3 bg-white">
	<div class="notifications-flag text-info" style="cursor: pointer">
		<i class="badge bg-danger">{{activeNotifications.length}}</i>
		<i class="fas fa-flag-alt text-info"></i>
		<i class="fal fa-chevron-down"></i>
	</div>
</div>
<div class="notification-details card card-body bg-light" [ngClass]="{ 'fade-in': showingNotifications === true, 'fade-out': showingNotifications === false }">
	<div class="mb-2">
		<a href="javascript:void(0)" (click)="clearAllNotifications()"><i style="font-size: 12px; position: relative; top: -1px;" class="far fa-trash"></i> Clear All Notifications</a>
	</div>
	<div class="notification-detail card bg-primary text-light pt-2 pb-2 ps-2 pe-3 mb-2" *ngFor="let notification of activeNotifications">
		<div style="display: inline-block; cursor: pointer; width:20px; vertical-align: top; font-size: 14px; line-height: 12px;" (click)="clearNotification(notification)">
			<i class="far fa-times-circle text-danger"></i>
		</div>
		<div style="display: inline-block; width: calc(100% - 20px);">
			<div style="font-size: 10px; line-height: 12px;">{{notification.notificationDate | date:'M/d/yyyy h:mm:ss a'}}</div>
			<div style="font-size: 12px; line-height: 12px;">
				{{notification.message}}
			</div>
			<div *ngIf="notification.notificationType === 'JobAssigned'" class="edit-job" (click)="editJob(notification.referenceId)">
				<i class="far fa-pencil"></i> Edit Job
			</div>
			<div *ngIf="notification.notificationType === 'QuickbooksError'" class="view-quickbooks-task" (click)="viewQuickbooksTask(notification.referenceId)">
				<i class="fal fa-eye"></i> View Quickbooks Task
			</div>
		</div>
	</div>
</div>

<job-edit-dialog #jobEditDialogRef [jobModel]="jobModel" (onSave)="onJobSave()"></job-edit-dialog>
<quickbooks-task-display-dialog #quickbooksTaskDisplayDialog></quickbooks-task-display-dialog>
