import { IRoomDesignModel } from '../room-design/room-design.model';
import { IMitigationAtmosphericReadingModel } from './mitigation-atmospheric-reading.model';
import { IMitigationRoomStructureModel } from './mitigation-room-structure.model';

export interface IMitigationRoomModel {
	mitigationRoomId: number;
	mitigationPlanId: number;
	jobName: string;
	roomName: string;
	category?: number;
	class?: number
	roomSqft?: number;
	roomCuft?: number;
	waterExtraction?: boolean;
	waterExtractionPercent?: number;
	liftCarpet?: boolean;
	liftCarpetPercent?: number;
	seamCut?: boolean;
	seamCutLF?: number
	removePad?: boolean;
	removePadPercent?: number;
	germicide?: boolean;
	germicidePercent?: number;
	baseboardsDetached?: boolean;
	baseboardsDetachedLF?: number;
	baseboardsTrashed?: boolean;
	baseboardsTrashedLF?: number;
	casingDetached?: boolean;
	casingDetachedLF?: number
	casingTrashed?: boolean;
	casingTrashedLF?: number;
	drywallSqft?: number;
	drywallHoles?: number;
	drywallAmount?: number;
	cornerBead: string;
	insulation: string;
	insulationAmount?: number;
	doors: string;
	doorCount?: number;
	contents: string;
	contentHours?: number;
	paintWallSample?: boolean;
	paintCeilingSample?: boolean;
	baseSample?: boolean;
	caseSample?: boolean;
	padSample?: boolean;
	flooringSample?: boolean;
	otherSample?: boolean;
	otherNotes: string;
	documentsFolderUuid: string;
	photosFolderUuid: string;

	roomDesign: IRoomDesignModel;
	atmosphericReadings: IMitigationAtmosphericReadingModel[];
	roomStructures: IMitigationRoomStructureModel[];
}

export class MitigationRoomModel implements IMitigationRoomModel {
	mitigationRoomId: number;
	mitigationPlanId: number;
	jobName: string;
	roomName: string;
	category?: number;
	class?: number
	roomSqft?: number;
	roomCuft?: number;
	waterExtraction?: boolean;
	waterExtractionPercent?: number;
	liftCarpet?: boolean;
	liftCarpetPercent?: number;
	seamCut?: boolean;
	seamCutLF?: number
	removePad?: boolean;
	removePadPercent?: number;
	germicide?: boolean;
	germicidePercent?: number;
	baseboardsDetached?: boolean;
	baseboardsDetachedLF?: number;
	baseboardsTrashed?: boolean;
	baseboardsTrashedLF?: number;
	casingDetached?: boolean;
	casingDetachedLF?: number
	casingTrashed?: boolean;
	casingTrashedLF?: number;
	drywallSqft?: number;
	drywallHoles?: number;
	drywallAmount?: number;
	cornerBead: string;
	insulation: string;
	insulationAmount?: number;
	doors: string;
	doorCount?: number;
	contents: string;
	contentHours?: number;
	paintWallSample?: boolean;
	paintCeilingSample?: boolean;
	baseSample?: boolean;
	caseSample?: boolean;
	padSample?: boolean;
	flooringSample?: boolean;
	otherSample?: boolean;
	otherNotes: string;
	documentsFolderUuid: string;
	photosFolderUuid: string;

	roomDesign: IRoomDesignModel;
	atmosphericReadings: IMitigationAtmosphericReadingModel[];
	roomStructures: IMitigationRoomStructureModel[];
}

