import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { UtilsService, SleepService, GlobalsService, HttpService } from "@services";
import { SlickDialogComponent } from "slick-components";
import { ContactEditComponent } from "./contact-edit.component";
import { ContactsService } from "@services";
import { IContactModel, ContactModel } from "@models";

@Component({
	selector: "contact-edit-dialog",
	template: `
		<slick-dialog #dialogRef [header]="dialogHeader" [width]='800'>
			<contact-edit #contactEditRef [(contactModel)]='contactModel' [showButtons]="false" [showContactType]="showContactType"></contact-edit>
			<slick-dialog-footer>
				<div class="form-group form-save-buttons">
					<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary save-button" (click)="onSaveContact()"><i class="far fa-save"></i>&nbsp;Save</button>
					<button type="button" class="btn btn-outline-danger cancel-button" (click)="onCancelContact()"><i class="far fa-ban"></i>&nbsp;Cancel</button>
				</div>
			</slick-dialog-footer>
		</slick-dialog>
`
})
export class ContactEditDialogComponent {
	@Input() contactModel: IContactModel;
	@Input() showContactType: boolean = false;
	@Output() onSave: EventEmitter<IContactModel> = new EventEmitter<IContactModel>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild("contactEditRef", { static: true }) contactEditRef: ContactEditComponent;

	dialogHeader: string;
	spinnerStatus: string;

	constructor(private contactsService: ContactsService) {
	}

	showDialog() {
		this.contactEditRef.spinnerStatus = "reset";
		setTimeout(() => {

			this.dialogRef.showDialog();
		})
	}

	async onSaveContact() {
		try {
			var isValid = this.contactEditRef.validate();
			if (!isValid)
				return;

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			let updatedModel: IContactModel = await this.contactsService.updateContact(this.contactModel)
			this.spinnerStatus = "ok";
			this.dialogRef.hideDialog();
			this.onSave.emit(updatedModel);
		}
		catch (error) {
			console.error(error);
			this.spinnerStatus = "error";
		}
	}

	onCancelContact() {
		this.dialogRef.hideDialog();
		this.onCancel.emit();
	}
}


