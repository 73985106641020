import { Injectable } from "@angular/core";
import {
	IAppointmentModel, IAppointmentDisplayModel, AppointmentDisplayModel, IAppointmentRowModel, AppointmentRowModel,
	ITimeSlotModel, ICalendarMonthModel, CalendarMonthModel, ICalendarWeekModel, CalendarWeekModel, ICalendarDayModel, CalendarDayModel
} from "@models";
import { UtilsService } from "./utils/utils.service";
import { Observable, Subject } from "rxjs";
import * as moment from "moment";

export enum ScheduleDisplayTypes { Daily, Weekly, Monthly };
export enum ScheduleOrientations { Vertical, Horizontal };

@Injectable()
export class ScheduleService {
	getStartDate(selectedDisplay: ScheduleDisplayTypes, selectedDate: Date): Date {
		let startDate: Date;

		switch (selectedDisplay) {
			case ScheduleDisplayTypes.Daily:
				startDate = moment(selectedDate).toDate();
				break;

			case ScheduleDisplayTypes.Weekly:
				startDate = moment(selectedDate).startOf("week").toDate();
				break;

			case ScheduleDisplayTypes.Monthly:
				// Okay, so we aren't actually looking for the first of the month.  We want the first Sunday of the week 
				// at the start of the month.  For example, if the 1st is a Wed, we need to go back to like the 28th on Sunday.
				startDate = moment(selectedDate).startOf("month").startOf("week").toDate();
				break;
		}

		return startDate;
	}

	getEndDate(selectedDisplay: ScheduleDisplayTypes, selectedDate: Date): Date {
		let endDate: Date;

		switch (selectedDisplay) {
			case ScheduleDisplayTypes.Daily:
				endDate = moment(selectedDate).add(1, "day").toDate();
				break;

			case ScheduleDisplayTypes.Weekly:
				endDate = moment(selectedDate).startOf("week").add(1, "week").toDate();
				break;

			case ScheduleDisplayTypes.Monthly:
				// Okay, so we aren't actually looking for the end of the month.  We want the last saturday of the week 
				// at the end of the month.  For example, if the 31st is a Wed, we need to go until Sat the 3rd.
				endDate = moment(selectedDate).startOf("month").add(1, "month").add(1, "week").startOf("week").toDate();
				break;
		}

		endDate = moment(endDate).subtract(1, "millisecond").toDate();

		return endDate;
	}

	getAllHours(): any[] {
		let hours = [];

		let currentMin = moment().startOf("day");
		for (let i = 0; i < 96; i++) {
			if (i % 4 === 0) {
				let min = currentMin.format("ha");
				hours.push(min);
			}
			else {
				hours.push(" ");
			}

			currentMin = currentMin.add(15, "minutes");
		}

		return hours;
	}

	getCalendarMonth(selectedDate: Date): ICalendarMonthModel {
		let month = new CalendarMonthModel();
		month.weeks = Array.from(new Array<ICalendarWeekModel>(6)).map(() => new CalendarWeekModel());

		let today = moment().startOf("date");
		let momentSelectedDate = moment(selectedDate).startOf("date");
		let currentDate = moment(momentSelectedDate).startOf("month").startOf("week");

		month.weeks.forEach(week => {
			week.days = Array.from(new Array<ICalendarDayModel>(7)).map(() => new CalendarDayModel());
			week.days.forEach(day => {
				day.date = currentDate.toDate();
				day.notInMonth = (momentSelectedDate.month() !== currentDate.month());
				day.isToday = currentDate.isSame(today);

				currentDate = currentDate.add(1, "days");
			});
		});

		return month;
	}

	private onSelectDateSubject: Subject<Date> = new Subject<Date>();
	selectDate(day: Date) {
		this.onSelectDateSubject.next(day);
	}

	onSelectDate(): Observable<Date> {
		return this.onSelectDateSubject.asObservable();
	}

	redrawDate(appointments: IAppointmentModel[], timeSlots: ITimeSlotModel[], dayOfWeek: number, startDate: Date): IAppointmentDisplayModel[] {
		let currentDate = moment(startDate).add(dayOfWeek, "days").toDate();

		// Get the time slots for this date.  We have 96 time slots per date
		let timeSlotsForDate = timeSlots.filter(x => x.index >= dayOfWeek * 96 && x.index <= (dayOfWeek + 1) * 96);
		let appointmentsForDate = appointments.filter(x => moment(x.startDate).startOf("day").isSame(currentDate));

		let appointmentDisplayModels = this.getAppointmentDisplayModels(appointmentsForDate, timeSlotsForDate, startDate);

		let left = timeSlotsForDate[0].left + 3;
		let columnWidth = timeSlotsForDate[0].width - 18;

		appointmentDisplayModels = appointmentDisplayModels.map(appt => {
			let apptStartMinute = appt.startDate.getHours() * 60 + appt.startDate.getMinutes();
			let apptEndMinute = appt.endDate.getHours() * 60 + appt.endDate.getMinutes();
			let apptDuration = apptEndMinute - apptStartMinute;

			let apptStartTimeSlot = apptStartMinute / 15;
			let apptEndTimeSlot = apptEndMinute / 15;

			let apptWidth = (columnWidth / (appt.maxSiblings + 1)) + ((appt.maxSiblings * 10) / (appt.maxSiblings + 1));
			let apptHeight = (timeSlotsForDate[apptEndTimeSlot].top - timeSlotsForDate[apptStartTimeSlot].top);
			let apptTop = timeSlotsForDate[apptStartTimeSlot].top;
			let apptLeft = left + (appt.column * (apptWidth - 10));

			appt.top = apptTop + "px";
			appt.left = apptLeft + "px";
			appt.width = apptWidth + "px";
			appt.height = apptHeight + "px";
			appt.trackByKey = UtilsService.getHashCode(appt.appointmentId.toString() + appt.version.toString() + apptTop.toString() + apptLeft.toString() + apptWidth.toString() + apptHeight.toString());

			return appt;
		});

		return appointmentDisplayModels;
	}

	refreshAppointmentDisplayArray(appointmentDisplays: IAppointmentDisplayModel[], allAppointments: IAppointmentDisplayModel[]): IAppointmentDisplayModel[] {
		allAppointments.forEach(apptDisplay => {
			let apptIdx = appointmentDisplays.findIndex(a => a.appointmentId === apptDisplay.appointmentId);

			if (apptIdx < 0)
				appointmentDisplays.push(apptDisplay);
			else
				appointmentDisplays[apptIdx] = apptDisplay;
		});

		// Now delete any appointment that isn't in the allAppointments list, but is still in the appointmentDisplay list
		for (let i = 0; i < appointmentDisplays.length; i++) {
			let apptDisplay = appointmentDisplays[i];

			let apptIdx = allAppointments.findIndex(a => a.appointmentId === apptDisplay.appointmentId);

			if (apptIdx < 0) {
				appointmentDisplays.splice(i, 1);
			}
		}

		return appointmentDisplays;
	}

	//private sortAppointmentsByDuration(appointments: IAppointmentModel[]): IAppointmentModel[] {
	//	let sortedAppointments = appointments.sort((a, b) => {
	//		if (a.duration > b.duration)
	//			return 1;
	//		else if (a.duration < b.duration)
	//			return -1;
	//		else
	//			return 0;
	//	})

	//	return sortedAppointments;
	//}

	//private sortAppointmentsByDate(appointments: IAppointmentModel[]): IAppointmentModel[] {
	//	let sortedAppointments = appointments.sort((a, b) => {
	//		if (a.startDate.getTime() < b.startDate.getTime())
	//			return 1;
	//		else if (a.startDate.getTime() > b.startDate.getTime())
	//			return -1;
	//		else
	//			return 0;
	//	})

	//	return sortedAppointments;
	//}

	private getAppointmentDisplayModels(appointments: IAppointmentModel[], timeSlots: ITimeSlotModel[], startDate: Date): IAppointmentDisplayModel[] {
		let appointmentDisplayModels: IAppointmentDisplayModel[] = [];
		let appointmentRows: IAppointmentRowModel[] = this.getAppointmentRows(appointments, startDate);

		// This is a two step process.  First, go through each time slot and total up the number of appts per time slot
		timeSlots.forEach(ts => {
			ts.maxAppointments = 0;
			appointmentRows.forEach(row => {
				row.appointmentDisplayModels.forEach(appt => {
					if (ts.index >= appt.startTimeSlot && ts.index < appt.endTimeSlot)
						ts.maxAppointments++;
				})
			})
		})

		// Next, go through each appt and get the max # of appointments in all the time slots
		let rowIdx = 0;
		appointmentRows.forEach(row => {
			row.appointmentDisplayModels.forEach(appt => {
				try {
					appt.maxSiblings = 0;
					for (let timeSlotIdx = appt.dailyStartTimeSlot; timeSlotIdx < appt.dailyEndTimeSlot; timeSlotIdx++) {
						let timeSlot = timeSlots[timeSlotIdx];
						// Siblings will not include this appt, so it's -1
						let siblings = timeSlot.maxAppointments - 1;
						if (siblings > appt.maxSiblings)
							appt.maxSiblings = siblings;
					}

					appt.column = rowIdx;
					appointmentDisplayModels.push(appt);
				}
				catch (err) {
					console.error(`Error with appointment ${appt.appointmentId} | start: ${appt.startDate} | end: ${appt.endDate}`);
					console.error(err);
					console.error(appt);
				}
			})

			rowIdx++;
		})

		// REALLY max siblings is the number of rows.
		// It was possible for a 7 row day where each sibling only had 6 siblings.
		// This caused it to display incorrectly.
		appointmentDisplayModels.forEach(x => x.maxSiblings = rowIdx - 1);

		return appointmentDisplayModels;
	}

	private getAppointmentRows(appointments: IAppointmentModel[], startDate: Date): IAppointmentRowModel[] {
		if (!appointments)
			return null;

		//let sortedAppointments = this.sortAppointmentsByDuration(appointments);
		//let sortedAppointments = this.sortAppointmentsByDate(appointments);
		let appointmentDisplayModels = appointments.map(appt => new AppointmentDisplayModel(appt, startDate));
		let appointmentRows: IAppointmentRowModel[] = this.fillRows(appointmentDisplayModels);

		return appointmentRows;
	}

	private fillRows(appointments: IAppointmentDisplayModel[]): IAppointmentRowModel[] {
		let rows: IAppointmentRowModel[] = [];

		while (appointments.length > 0) {
			let row = this.fillRow(appointments, true);

			if (row.appointmentDisplayModels.length > 0)
				rows.push(row);
		}

		return rows;
	}

	private fillRow(appointments: IAppointmentDisplayModel[], safety = null, row: IAppointmentRowModel = null): IAppointmentRowModel {
		if (!appointments || appointments.length === 0)
			return null;

		if (!row) {
			row = new AppointmentRowModel();
			// Seed this row with an appointment
			row.appointmentDisplayModels = [];
			row.appointmentDisplayModels.push(appointments.splice(0, 1)[0]);
		}

		if (!safety)
			safety = 1;

		if (safety > 20)
			throw "Safety check failed";

		// If this is a new row, start at midnight
		let firstStartTimeMinute = row.appointmentDisplayModels[0].startDate.getHours() * 60 + row.appointmentDisplayModels[0].startDate.getMinutes();
		let lastEndTimeMinute = row.appointmentDisplayModels[row.appointmentDisplayModels.length - 1].endDate.getHours() * 60 + row.appointmentDisplayModels[row.appointmentDisplayModels.length - 1].endDate.getMinutes();

		// Get midnight next day - last ending time
		let beginOfDayDuration = firstStartTimeMinute;
		let endOfDayDuration = 1440 - lastEndTimeMinute;

		let found = false;
		for (let apptIdx = 0; apptIdx < appointments.length && found === false; apptIdx++) {
			// See if this appt will fit at the end of the current appointments

			let thisApptStartMinute = appointments[apptIdx].startDate.getHours() * 60 + appointments[apptIdx].startDate.getMinutes();
			if (thisApptStartMinute === lastEndTimeMinute && endOfDayDuration >= appointments[apptIdx].duration) {
				found = true;
				row.appointmentDisplayModels.push(appointments.splice(apptIdx, 1)[0]);
				this.fillRow(appointments, ++safety, row);
			}
			else {
				let thisApptEndMinute = appointments[apptIdx].endDate.getHours() * 60 + appointments[apptIdx].endDate.getMinutes();
				if (thisApptEndMinute === firstStartTimeMinute && beginOfDayDuration >= appointments[apptIdx].duration) {
					found = true;
					row.appointmentDisplayModels.unshift(appointments.splice(apptIdx, 1)[0]);
					this.fillRow(appointments, ++safety, row);
				}
			}
		}

		// Okay, we didn't find any exact matches.  See if we can find some inbetween matches
		if (found === false) {
			for (let apptIdx = 0; apptIdx < appointments.length && found === false; apptIdx++) {
				let thisApptStartMinute = appointments[apptIdx].startDate.getHours() * 60 + appointments[apptIdx].startDate.getMinutes();
				let thisApptEndMinute = appointments[apptIdx].endDate.getHours() * 60 + appointments[apptIdx].endDate.getMinutes();

				for (let rowApptIdx = 0; rowApptIdx < row.appointmentDisplayModels.length + 1 && found === false; rowApptIdx++) {
					// If this is the first record, there won't be a previous one, so set to midnight
					let prevApptEndMinute = (rowApptIdx === 0) ? 0 : row.appointmentDisplayModels[rowApptIdx - 1].endDate.getHours() * 60 + row.appointmentDisplayModels[rowApptIdx - 1].endDate.getMinutes();
					let currentApptStartMinute = (rowApptIdx === row.appointmentDisplayModels.length) ? 1440 : row.appointmentDisplayModels[rowApptIdx].startDate.getHours() * 60 + row.appointmentDisplayModels[rowApptIdx].startDate.getMinutes();
					if (thisApptStartMinute >= prevApptEndMinute && thisApptEndMinute <= currentApptStartMinute) {
						found = true;
						let previousAppts = (rowApptIdx === 0) ? [] : row.appointmentDisplayModels.splice(0, rowApptIdx);
						let nextAppts = row.appointmentDisplayModels.splice(0);
						row.appointmentDisplayModels = previousAppts;
						row.appointmentDisplayModels.push(appointments.splice(apptIdx, 1)[0]);
						row.appointmentDisplayModels = row.appointmentDisplayModels.concat(nextAppts);
						this.fillRow(appointments, ++safety, row);
					}
				}
			}
		}

		return row;
	}
}

