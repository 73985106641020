import { UtilsService } from "../../services";
import { ICustomerModel } from "../customers/customer.model";
import { ISubcontractorModel } from "../subcontractors/subcontractor.model";
import { IWorkOrderLineItemModel } from "./work-order-line-item.model";

export interface IWorkOrderModel {
	workOrderId: number;
	uuid: string;
	version: number;
	workOrderSeq: number;
	workOrderNumber: string;
	documentsFolderUuid: string;
	photosFolderUuid: string;
	workOrderStatus: string;
	jobId: number;
	jobName: string;
	subcontractorId: number;
	subcontractorName: string;
	startDate?: Date;
	endDate?: Date;
	workOrderTotal: number;
	estimatorNotes: string;
	subcontractorNotes: string;
	isDeleted: boolean;

	subcontractor: ISubcontractorModel;
	jobPrimaryCustomer: ICustomerModel;
	workOrderLineItems: IWorkOrderLineItemModel[];
}

export class WorkOrderModel implements IWorkOrderModel {
	constructor() {
		this.workOrderId = 0;
		this.uuid = UtilsService.newGuid();
		this.version = 1;
		this.workOrderStatus = "Awaiting Acceptance";
		this.workOrderTotal = 0;
		this.isDeleted = false;

		this.workOrderLineItems = [];
	}

	workOrderId: number;
	uuid: string;
	version: number;
	workOrderSeq: number;
	workOrderNumber: string;
	documentsFolderUuid: string;
	photosFolderUuid: string;
	workOrderStatus: string;
	jobId: number;
	jobName: string;
	subcontractorId: number;
	subcontractorName: string;
	startDate?: Date;
	endDate?: Date;
	workOrderTotal: number;
	estimatorNotes: string;
	subcontractorNotes: string;
	isDeleted: boolean;

	subcontractor: ISubcontractorModel;
	jobPrimaryCustomer: ICustomerModel;
	workOrderLineItems: IWorkOrderLineItemModel[];
}