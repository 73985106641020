export interface IAppointmentResourceModel {
	resourceId: number;
	resourceName: string;
	resourceColor: string;
}

export class AppointmentResourceModel implements IAppointmentResourceModel {
	resourceId: number;
	resourceName: string;
	resourceColor: string;
}

