export interface IAppointmentReminderModel {
	uuid: string;
	appointmentId: number;
	appointmentReminderDefaultId: number;
	isChecked: boolean;
	reminderText: string;
	checkedBy: string;
	checkedDate: Date;
}

export class AppointmentReminderModel implements IAppointmentReminderModel {
	uuid: string;
	appointmentId: number;
	appointmentReminderDefaultId: number;
	isChecked: boolean;
	reminderText: string;
	checkedBy: string;
	checkedDate: Date;
}