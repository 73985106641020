import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { UtilsService } from "./utils/utils.service";
import { LookupService } from "./utils/lookup.service";
import { ContactsService } from "./contacts.service";
import { IDropdownModel, ICustomerSearchListModel, ICustomerGridModel, ICustomerModel, CustomerModel, ICustomerNoteModel, IContactModel } from "@models";

@Injectable()
export class CustomersService {
	constructor(private httpService: HttpService, private lookupService: LookupService, private contactsService: ContactsService) { }

	getNewCustomerModel(): ICustomerModel {
		var customerModel: ICustomerModel = new CustomerModel();
		customerModel.customerId = 0;
		customerModel.uuid = UtilsService.newGuid();
		customerModel.version = 0;
		customerModel.contact = this.contactsService.getNewContactModel();
		customerModel.emailOptIn = true;
		customerModel.textOptIn = true;
		customerModel.active = true;

		return customerModel;
	}

	async getCustomer(customerId: number): Promise<ICustomerModel> {
		try {
			return Promise.resolve(await this.httpService.get("/customers/getCustomer", { customerId: customerId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getCustomerSearchListModel(customerId: number): Promise<ICustomerSearchListModel> {
		try {
			return Promise.resolve(await this.httpService.get("/customers/getCustomerSearchListModel", { customerId: customerId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getCustomersForInfiniteScroll(pageCount: number, searchString: string = ""): Promise<ICustomerGridModel[]> {
		try {
			const params = {
				pageCount: pageCount,
				searchString: searchString
			}

			return Promise.resolve(await this.httpService.get("/customers/getCustomersForInfiniteScroll", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getCustomersForJob(jobId: number): Promise<ICustomerModel[]> {
		return Promise.resolve(await this.httpService.get("/customers/getCustomersForJob", { jobId: jobId }));
	}

	async updateCustomer(customerModel: ICustomerModel): Promise<ICustomerModel> {
		try {
			if (customerModel.customerId === 0) {
				let returnModel = await this.httpService.post("/customers/addCustomer", customerModel);
				this.lookupService.refreshEstimators();
				this.lookupService.refreshEmailerAddresses();
				return Promise.resolve(returnModel);
			}
			else {
				let returnModel = await this.httpService.post("/customers/updateCustomer", customerModel)
				this.lookupService.refreshEstimators();
				this.lookupService.refreshEmailerAddresses();
				return Promise.resolve(returnModel);
			}
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async deleteCustomer(customerId: number): Promise<boolean> {
		try {
			return Promise.resolve(await this.httpService.delete("/customers/deleteCustomer", { customerId: customerId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getCustomerUserNotes(customerId: number, count: number): Promise<ICustomerNoteModel[]> {
		try {
			let params = {
				customerId: customerId,
				count: count
			}
			return Promise.resolve(await this.httpService.get("/customers/getCustomerUserNotes", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getCustomerSystemNotes(customerId: number, count: number): Promise<ICustomerNoteModel[]> {
		try {
			let params = {
				customerId: customerId,
				count: count
			}
			return Promise.resolve(await this.httpService.get("/customers/getCustomerSystemNotes", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getCustomerSearchResults(searchText: string): Promise<ICustomerSearchListModel[]> {
		try {
			const params = {
				searchText: searchText
			}

			return Promise.resolve(await this.httpService.get("/customers/getCustomerSearchResults", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getCustomerForGrid(customerId: number): Promise<any> {
		try {
			return Promise.resolve(await this.httpService.get("/customers/getCustomerForGrid", { customerId: customerId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async syncToQuickbooks(customerId: number): Promise<ICustomerGridModel> {
		try {
			return Promise.resolve(await this.httpService.post("/customers/syncToQuickbooks", { customerId: customerId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async unsyncFromQuickbooks(customerId: number): Promise<ICustomerGridModel> {
		try {
			return Promise.resolve(await this.httpService.post("/customers/unsyncFromQuickbooks", { customerId: customerId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async checkDuplicateCustomer(customer: ICustomerModel): Promise<ICustomerModel> {
		return await this.httpService.post("/customers/checkDuplicateCustomer", customer);
	}

	public setPrimaryCustomer(customerModel: ICustomerModel): ICustomerModel {
		// If this is a new customer, 
		if (customerModel.customerId === 0) {
			customerModel.contact.defaultAddress.isDefault = true;
			customerModel.contact.addresses = [];
			customerModel.contact.phoneNumbers = [];
			customerModel.contact.emailAddresses = [];
		}

		// find default address
		let defaultAddressIdx = customerModel.contact.addresses.findIndex(x => x.isDefault === true && x.active === true);
		if (defaultAddressIdx >= 0)
			customerModel.contact.addresses[defaultAddressIdx] = customerModel.contact.defaultAddress;
		else
			customerModel.contact.addresses.push(customerModel.contact.defaultAddress);

		// Find Home Phone
		let homePhoneIdx = customerModel.contact.phoneNumbers.findIndex(x => x.phoneNumberTypeId == 1 && x.isDefault === true && x.active === true);
		if (homePhoneIdx >= 0)
			customerModel.contact.phoneNumbers[homePhoneIdx] = customerModel.contact.defaultHome;
		else
			customerModel.contact.phoneNumbers.push(customerModel.contact.defaultHome);

		// Find Work Phone
		let workPhoneIdx = customerModel.contact.phoneNumbers.findIndex(x => x.phoneNumberTypeId == 2 && x.isDefault === true && x.active === true);
		if (workPhoneIdx >= 0)
			customerModel.contact.phoneNumbers[workPhoneIdx] = customerModel.contact.defaultWork;
		else
			customerModel.contact.phoneNumbers.push(customerModel.contact.defaultWork);

		// Find Cell Phone
		let cellPhoneIdx = customerModel.contact.phoneNumbers.findIndex(x => x.phoneNumberTypeId == 3 && x.isDefault === true && x.active === true);
		if (cellPhoneIdx >= 0)
			customerModel.contact.phoneNumbers[cellPhoneIdx] = customerModel.contact.defaultCell;
		else
			customerModel.contact.phoneNumbers.push(customerModel.contact.defaultCell);

		// Find Alt1 Phone
		let alt1PhoneIdx = customerModel.contact.phoneNumbers.findIndex(x => x.phoneNumberTypeId == 6 && x.isDefault === true && x.active === true);
		if (alt1PhoneIdx >= 0)
			customerModel.contact.phoneNumbers[alt1PhoneIdx] = customerModel.contact.defaultAlt1;
		else
			customerModel.contact.phoneNumbers.push(customerModel.contact.defaultAlt1);

		// Find Alt2 Phone
		let alt2PhoneIdx = customerModel.contact.phoneNumbers.findIndex(x => x.phoneNumberTypeId == 7 && x.isDefault === true && x.active === true);
		if (alt2PhoneIdx >= 0)
			customerModel.contact.phoneNumbers[alt2PhoneIdx] = customerModel.contact.defaultAlt2;
		else
			customerModel.contact.phoneNumbers.push(customerModel.contact.defaultAlt2);

		// Find Primary Email
		let primaryEmailAddressIdx = customerModel.contact.emailAddresses.findIndex(x => x.emailAddressTypeId == 1 && x.active === true);
		if (primaryEmailAddressIdx >= 0)
			customerModel.contact.emailAddresses[primaryEmailAddressIdx] = customerModel.contact.defaultEmailAddress;
		else
			customerModel.contact.emailAddresses.push(customerModel.contact.defaultEmailAddress);

		// Find Secondary Email
		let secondaryEmailAddressIdx = customerModel.contact.emailAddresses.findIndex(x => x.emailAddressTypeId == 2 && x.active === true);
		if (secondaryEmailAddressIdx >= 0)
			customerModel.contact.emailAddresses[secondaryEmailAddressIdx] = customerModel.contact.secondaryEmailAddress;
		else
			customerModel.contact.emailAddresses.push(customerModel.contact.secondaryEmailAddress);

		return customerModel;
	}

}
