<div style="border-bottom: 1px solid #ccc; margin-bottom: 5px;">
	<div style="display: inline-block; width: 49%;">
		<div style="font-size: 18px;">Phone Numbers</div>
	</div>
	<div style="display: inline-block; width: 50%; text-align: right;">
		<button type="button" class="btn btn-link" (click)="addNewPhoneNumber()"><i class="far fa-plus"></i> Add Additional Phone Number</button>
	</div>
</div>

<div style="clear: both;" class="form-horizontal">
	<div class="form-group row" style="padding-left: 15px; padding-right: 15px;">
		<div *ngIf="!showWork" class="col-4">
			<label>Cell</label>
			<input type="text" [slick-validation-indicator]="defaultCell.phoneNumber" slickValidationType="warning" class="form-control" [(ngModel)]="defaultCell.phoneNumber" />
		</div>

		<div *ngIf="showWork" class="col-4">
			<label>Work</label>
			<input type="text" [slick-validation-indicator]="defaultWork.phoneNumber" slickValidationType="warning" class="form-control" [(ngModel)]="defaultWork.phoneNumber" />
		</div>

		<div class="col-4">
			<label>Alt 1</label>
			<input type="text" class="form-control" [(ngModel)]="defaultAlt1.phoneNumber" />
		</div>

		<div class="col-4">
			<label>Alt 2</label>
			<input type="text" class="form-control" [(ngModel)]="defaultAlt2.phoneNumber" />
		</div>
	</div>
</div>

<div class="form-group">
	<div class="col-12">
		<div class="phoneNumberBlock" *ngFor="let phoneNumber of phoneNumberModels | noDefault">
			<div style="display: inline-block; vertical-align: top;"><i style="cursor: pointer;" class="far fa-pencil" (click)="editPhoneNumber(phoneNumber)"></i><i style="cursor: pointer;" class="far fa-trash" (click)="deletePhoneNumber(phoneNumber)"></i></div>
			<div style="display: inline-block; vertical-align: top; width: calc(100% - 40px);">
				{{phoneNumber.phoneNumberTypeName}}:&nbsp;
				{{phoneNumber.phoneNumber}}<br />
			</div>
		</div>
	</div>
</div>

<slick-dialog #phoneNumberDialogRef [header]="'Phone Number Edit'" [top]="120" width="400">

	<div class="form-horizontal">
		<div class="row form-group">
			<div class="col-12">
				<label>Type</label>
				<slick-drop-down [items]="phoneNumberTypes" 
							 [allowEmpty]="false"
							 [(ngModel)]="dialogModel.phoneNumberTypeId"
							 (onSelect)="onPhoneNumberTypeSelect($event)"></slick-drop-down>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-12">
				<label>Number</label>
				<input type="text" class="form-control" [(ngModel)]="dialogModel.phoneNumber" />
			</div>
		</div>

		<slick-dialog-footer>
			<div class="row">
				<div class="col-12 form-save-buttons">
					<button type="button" class="btn btn-outline-primary" (click)="onUpdate()">Update</button>
					<button type="button" class="btn btn-outline-danger" (click)="onCancel()">Cancel</button>
				</div>
			</div>
		</slick-dialog-footer>
	</div>
</slick-dialog>

<slick-confirm-dialog #deletePhoneNumberRef>
	Are you sure that you want to delete this phone number?
</slick-confirm-dialog>
