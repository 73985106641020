import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IPDFTemplateModel } from "@models";
import { TemplatesService } from "./templates.service";


@Injectable()
export class PDFTemplatesService extends TemplatesService {
	constructor(private httpService: HttpService) {
		super();
	}

	async getAllPDFTemplates(): Promise<IPDFTemplateModel[]> {
		return this.httpService.get(`/pdfTemplates/getAllPDFTemplates`);
	}

	async updatePDFTemplate(pdfTemplateModel: IPDFTemplateModel): Promise<IPDFTemplateModel> {
		return this.httpService.post(`/pdfTemplates/updatePDFTemplate`, pdfTemplateModel);
	}
}

