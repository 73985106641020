import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IDropdownModel, DropdownModel, ITradeModel } from "@models";

@Injectable()
export class TradesService {
	constructor(private httpService: HttpService) { }

	async getTrade(tradeId: number): Promise<ITradeModel> {
		const trades = await this.httpService.get("/trades/getTrade", { tradeId });

		return trades.map(x => new DropdownModel(x.tradeId, x.tradeName));
	}

	async getTrades(): Promise<ITradeModel[]> {
		const trades = await this.httpService.get("/trades/getTrades");

		return trades.map(x => new DropdownModel(x.tradeId, x.tradeName));
	}

	async getTradesForDropdown(): Promise<IDropdownModel[]> {
		const trades: ITradeModel[] = await this.httpService.get("/trades/getTrades");

		return trades.map(x => new DropdownModel(x.tradeId, x.tradeName));
	}

	async addTrade(tradeModel: ITradeModel): Promise<ITradeModel> {
		return this.httpService.post('/trades/addTrade', tradeModel);
	}

	async updateTrade(tradeModel: ITradeModel): Promise<ITradeModel> {
		return this.httpService.post('/trades/updateTrade', tradeModel);
	}

	async deleteTrade(tradeId: number): Promise<ITradeModel> {
		return this.httpService.delete('/trades/deleteTrade', { tradeId });
	}
}
