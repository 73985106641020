export interface IEquipmentModel {
	equipmentId: number;
	uuid: string;
	version: number;
	profilePictureUrl: string;
	name: string;
	equipmentTypeId: number;
	equipmentTypeDescription: string;
	equipmentStatusId: number;
	equipmentStatus: string;
	documentFolderUuid: string;
	description: string;
	scratchPad: string;
	purchaseAmount: number;
	outOfService: boolean;
	serialNumber: string;
	qrCode: string;
	active: boolean;
	isDirty: boolean;
}

export class EquipmentModel implements IEquipmentModel {
	equipmentId: number;
	uuid: string;
	version: number;
	profilePictureUrl: string;
	name: string;
	equipmentTypeId: number;
	equipmentTypeDescription: string;
	equipmentStatusId: number;
	equipmentStatus: string;
	documentFolderUuid: string;
	description: string;
	scratchPad: string;
	purchaseAmount: number;
	outOfService: boolean;
	serialNumber: string;
	qrCode: string;
	active: boolean;
	isDirty: boolean;
}
