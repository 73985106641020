export interface IMitigationRoomStructureReadingModel {
	mitigationRoomStructureReadingId: number;
	mitigationRoomStructureId: number;
	mitigationRoomStructureName: string;
	date: Date;
	wetPercent: number;
}

export class MitigationRoomStructureReadingModel implements IMitigationRoomStructureReadingModel {
	constructor() {
		this.mitigationRoomStructureReadingId = 0;
		this.wetPercent = null;
	}

	mitigationRoomStructureReadingId: number;
	mitigationRoomStructureId: number;
	mitigationRoomStructureName: string;
	date: Date;
	wetPercent: number;
}

