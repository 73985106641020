import { ContactModel, IContactModel } from "@models/contacts/contact.model";
import { UtilsService } from "@services/utils/utils.service";
import { IBusinessDevelopmentContactModel } from "./business-development-contact.model";

export interface IBusinessDevelopmentAccountModel {
	businessDevelopmentAccountId: number;
	uuid: string;
	contactId: number;
	marketingEmployeeId?: number;
	marketingEmployeeFullName: string;
	businessDevelopmentAccountTypeId?: number;
	businessDevelopmentAccountTypeDescription: string;
	carrierId?: number;
	carrierDisplayName: string;
	defaultEmail: string;
	scratchpad: string;
	isReferral: boolean;
	isCarrier: boolean;
	isAdjuster: boolean;
	isAgent: boolean;
	isCommissionEligible: boolean;
	defaultCommissionPercent: number;
	visitFrequency?: number;
	daysBeforeWarning?: number;
	lastVisitDate?: Date;
	totalJobs: number;
	totalJobsLastSixMonths: number;
	totalJobsLastTwelveMonths: number;
	totalJobsLastEighteenMonths: number;
	isDeleted: boolean;

	contact: IContactModel;
	businessDevelopmentContacts: IBusinessDevelopmentContactModel[];
}

export class BusinessDevelopmentAccountModel implements IBusinessDevelopmentAccountModel {
	businessDevelopmentAccountId: number;
	uuid: string;
	contactId: number;
	marketingEmployeeId?: number;
	marketingEmployeeFullName: string;
	businessDevelopmentAccountTypeId?: number;
	businessDevelopmentAccountTypeDescription: string;
	carrierId?: number;
	carrierDisplayName: string;
	defaultEmail: string;
	scratchpad: string;
	isReferral: boolean;
	isCarrier: boolean;
	isAdjuster: boolean;
	isAgent: boolean;
	isCommissionEligible: boolean;
	defaultCommissionPercent: number;
	visitFrequency?: number;
	daysBeforeWarning?: number;
	lastVisitDate?: Date;
	totalJobs: number;
	totalJobsLastSixMonths: number;
	totalJobsLastTwelveMonths: number;
	totalJobsLastEighteenMonths: number;
	isDeleted: boolean;

	contact: IContactModel;
	businessDevelopmentContacts: IBusinessDevelopmentContactModel[];

	constructor() {
		this.businessDevelopmentAccountId = 0;
		this.uuid = UtilsService.newGuid();
		this.contact = new ContactModel();
		this.isCommissionEligible = false;
		this.businessDevelopmentContacts = [];
	}
}