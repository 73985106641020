<div style="border-bottom: 1px solid #ccc; margin-bottom: 5px;">
    <div style="display: inline-block; width: 49%;">
        <div style="font-size: 18px;">Email Addresses</div>
    </div>
    <div style="display: inline-block; width: 50%; text-align: right;">
        <button type="button" class="btn btn-link" (click)="addNewEmailAddress()"><i class="far fa-plus"></i> Add Additional Email Address</button>
    </div>
</div>

<div style="clear: both;" class="form-horizontal">
    <div class="form-group">
        <div class="col-12">
            <input type="text" [slick-validation-indicator]="defaultEmailAddress.emailAddress" slickValidationType="warning" class="form-control" [(ngModel)]="defaultEmailAddress.emailAddress" />
        </div>
    </div>
</div>

<div class="form-group">
    <div class="col-12">
        <div class="emailAddressBlock" *ngFor="let emailAddress of emailAddressModels | noDefault">
            <div style="display: inline-block; vertical-align: top;"><i style="cursor: pointer;" class="far fa-pencil" (click)="editEmailAddress(emailAddress)"></i><i style="cursor: pointer;" class="far fa-trash" (click)="deleteEmailAddress(emailAddress)"></i></div>
            <div style="display: inline-block; vertical-align: top; width: calc(100% - 40px);">
                {{emailAddress.emailAddressTypeName}}:&nbsp;
                {{emailAddress.emailAddress}}<br />
            </div>
        </div>
    </div>
</div>

<slick-dialog #emailAddressDialogRef [header]="'Email Address Edit'" [top]="120" width="400">
    <div class="form-horizontal">
        <div class="row form-group">
            <div class="col-12">
                <label>Type</label>
                <slick-drop-down [items]="emailAddressTypes"
                                 [allowEmpty]="false"
                                 [(ngModel)]="dialogModel.emailAddressTypeId"
                                 (onSelect)="onEmailAddressTypeSelect($event)"></slick-drop-down>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-12">
                <label>Email Address</label>
                <input type="text" class="form-control" [(ngModel)]="dialogModel.emailAddress" />
            </div>
        </div>

        <slick-dialog-footer>
            <div class="row">
                <div class="col-12 form-save-buttons">
                    <button type="button" class="btn btn-outline-primary" (click)="onUpdate()">Update</button>
                    <button type="button" class="btn btn-outline-danger" (click)="onCancel()">Cancel</button>
                </div>
            </div>
        </slick-dialog-footer>
    </div>
</slick-dialog>

<slick-confirm-dialog #deleteEmailAddressRef>
    Are you sure that you want to delete this email address?
</slick-confirm-dialog>
